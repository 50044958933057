<template>
  <v-form
    v-model="valid"
    ref="panelForm"
    :action="route('admin.panels.save', panel.id)"
    @submit.prevent="saveConfig()"
  >
    <v-expansion-panels>
      <div
        class="panel-wrapper"
        v-for="(block, index) in blocks"
        :key="block.id"
      >
        <v-expansion-panel active-class="active" :key="block.id">
          <v-expansion-panel-header class="text-h6"
            >Block: {{ block.title }}</v-expansion-panel-header
          >
          <v-expansion-panel-content>
            <v-divider class="mb-4"></v-divider>
            <v-row>
              <v-col cols="12" lg="3">
                <v-row align="center">
                  <v-col cols="12">
                    <v-text-field
                      filled
                      dense
                      hide-details="auto"
                      :rules="inputRules"
                      v-model="block.title"
                      label="Titel"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-file-input
                      v-model="block.featured_image"
                      accept="image/png, image/jpeg, image/webp"
                      show-size
                      dense
                      filled
                      label="Bild"
                      hide-details="auto"
                      prepend-inner-icon="mdi-camera"
                      prepend-icon=""
                    ></v-file-input>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      filled
                      dense
                      hide-details="auto"
                      v-model="block.subtitle"
                      label="Untertitel"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <div class="px-4">
                    <v-switch
                      dense
                      class="my-2 inputSwitch"
                      v-model="block.open_on_load"
                      inset
                      :label="`Geöffnet beim Laden? ${
                        block.open_on_load ? 'Ja' : 'Nein'
                      }`"
                      hide-details="auto"
                    ></v-switch>
                  </div>
                </v-row>
                <v-row>
                  <v-col>
                    <h4 class="primary--text">Block-Typ</h4>
                    <v-radio-group
                      v-model="block.type"
                      row
                      active-class="type-selected"
                      hide-details="auto"
                    >
                      <v-radio class="elevation-4 rounded pa-2" value="table">
                        <template v-slot:label>
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon v-bind="attrs" v-on="on" size="48">
                                mdi-table
                              </v-icon>
                            </template>
                            <span>Tabelle</span>
                          </v-tooltip>
                        </template>
                      </v-radio>
                      <!-- <v-radio class="elevation-4 rounded pa-2" value="text">
                <template v-slot:label>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon v-bind="attrs" v-on="on" size="48">
                        mdi-text
                      </v-icon>
                    </template>
                    <span>Text</span>
                  </v-tooltip>
                </template>
              </v-radio> -->
                      <v-radio class="elevation-4 rounded pa-2" value="map">
                        <template v-slot:label>
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon v-bind="attrs" v-on="on" size="48">
                                mdi-map
                              </v-icon>
                            </template>
                            <span>Karte</span>
                          </v-tooltip>
                        </template>
                      </v-radio>
                    </v-radio-group>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-autocomplete
                      v-if="block.type === 'table'"
                      dense
                      filled
                      hide-details="auto"
                      :items="categories"
                      item-value="field_name"
                      item-text="field_name"
                      v-model="block.module"
                    ></v-autocomplete>
                    <v-autocomplete
                      v-if="block.type === 'map'"
                      dense
                      filled
                      hide-details="auto"
                      :items="fields"
                      item-value="id"
                      item-text="field_name"
                      v-model="block.location_field_id"
                    >
                    </v-autocomplete>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" lg="9">
                <template v-if="block.type === 'table'">
                  <panel-config-draggable
                    :columns="block.panel_columns"
                    :module="block.module"
                    @change="saveColumns"
                    :index="index"
                  ></panel-config-draggable>
                </template>
              </v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <div class="panel-buttons flex-grow-1 flex-md-grow-0">
          <v-btn
            :disabled="index <= 0"
            color="secondary"
            min-width="20px"
            max-width="20px"
            class="mr-2"
            @click="moveUp(index)"
          >
            <v-icon>mdi-menu-up</v-icon>
          </v-btn>
          <v-btn
            :disabled="index >= blocks.length - 1"
            color="secondary"
            min-width="20px"
            max-width="20px"
            class="mr-2"
            @click="moveDown(index)"
          >
            <v-icon>mdi-menu-down</v-icon>
          </v-btn>
          <v-btn
            color="primary"
            min-width="20px"
            max-width="20px"
            class="mr-2"
            @click="addBlock(index)"
          >
            <v-icon>mdi-plus</v-icon>
          </v-btn>
          <v-btn
            color="error"
            min-width="20px"
            max-width="20px"
            @click="removeBlock(index)"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
      </div>
    </v-expansion-panels>

    <v-row class="mx-0">
      <v-spacer></v-spacer>
      <v-btn type="submit" class="mt-8" color="primary"
        >Konfiguration speichern</v-btn
      >
    </v-row>
  </v-form>
</template>
<script>
import PanelConfigDraggable from "./PanelConfigDraggable.vue";

export default {
  components: {
    PanelConfigDraggable,
  },
  data: () => ({
    categories: [],
    valid: true,
    inputRules: [(v) => !!v || "Feld wird benötigt"],
    blocks: [],
  }),
  props: {
    panel: Object,
  },
  created() {
    this.blocks = this.panel.panel_blocks;

    this.blocks.forEach((block) => {
      if (!block.featured_image) return;
      const url = new URL(block.featured_image);
      const filename = url.pathname.split("/").pop();

      fetch(url)
        .then((result) => {
          return result.blob();
        })
        .then((blob) => {
          block.featured_image = new File([blob], filename);
        })

        .catch(() => {
          this.$snackbar.showMessage({
            content: "Fehler beim Laden",
            color: "error",
          });
        });
    });

    axios
      .get(this.$root.route("getCategories"))
      .then((res) => {
        if (this.panel.module === "Organisationen" || this.panel.module === 'Ansprechpartner') {
          this.categories = res.data.filter(
            (elem) =>
              elem.name === "Organisationen" || elem.name === "Ansprechpartner"
          );
        } else {
          this.categories = res.data.filter(
            (elem) =>
              elem.name !== "Organisationen" && elem.name !== "Ansprechpartner"
          );
        }

        if (this.panel.module === "Eigentümer") {
          this.categories = this.categories.filter(
            (elem) => !elem.name.includes("Betreiber")
          );
        }

        if (this.panel.module === "Betreiber") {
          this.categories = this.categories.filter(
            (elem) => !elem.name.includes("Eigentümer")
          );
        }

        this.categories = _.sortBy(this.categories, 'name')
      })
      .catch(() => {
        this.$snackbar.showMessage({
          content: "Fehler beim Laden",
          color: "error",
        });
      });
  },

  methods: {
    saveColumns([columns, index]) {
      this.blocks[index].panel_columns = columns;
    },
    addBlock(index) {
      this.blocks.splice(index + 1, 0, {
        title: "",
        type: "",
        open_on_load: false,
        module: "Windparks",
        location_field_id: null,
      });
    },

    moveUp(index) {
      this.blocks.splice(Math.max(index - 1, 0), 0, this.blocks[index]);
      this.blocks.splice(index + 1, 1);
    },

    moveDown(index) {
      this.blocks.splice(index + 2, 0, this.blocks[index]);
      this.blocks.splice(index, 1);
    },

    removeBlock(index) {
      this.blocks.splice(index, 1);
    },

    saveConfig() {
      if (this.valid) {
        const formData = new FormData();
        formData.append("blocks", JSON.stringify(this.blocks));
        this.blocks.forEach((element, index) => {
          if (element.featured_image) {
            formData.append(`image_${index}`, element.featured_image);
          }
        });

        axios
          .post(this.$refs.panelForm.$attrs.action, formData)
          .then((res) => {
            this.$snackbar.showMessage({
              content: "Konfiguration gespeichert",
              color: "primary",
            });
          })
          .catch(() => {
            this.$snackbar.showMessage({
              content: "Fehler beim Speichern",
              color: "error",
            });
          });
      }
    },
  },
  computed: {
    fields: {
      get() {
        return _.sortBy(this.$store.getters["fieldStore/getAllFieldsByCategoryName"](
          this.panel.module
        ), field => field.field_name.toLowerCase());
      },
    },
    dragOptions() {
      return {
        animation: 200,
        group: "panels",
        disabled: false,
        ghostClass: "ghost",
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.fields-grid {
  display: grid;
  grid-template-columns: 1fr;
  column-gap: 30px;

  .fields-item {
    display: flex;
    column-gap: 15px;
    align-items: center;

    .field-title {
      flex: 0 0 33%;
      text-align: end;
      color: #a6adaf;
    }

    .field-value {
      color: #919699;
      font-weight: 700;
    }

    .field-divider {
      border: 1px solid #f7f9fa;
      height: 100%;
    }

    &:nth-child(4n - 1) {
      background-color: #f7f9fa;
      .field-divider {
        border: 1px solid #fff;
      }
    }
    &:nth-child(4n) {
      background-color: #f7f9fa;
      .field-divider {
        border: 1px solid #fff;
      }
    }
  }
}

.panel-wrapper {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  column-gap: 15px;
  row-gap: 15px;
  margin: 15px 0;

  .panel-buttons {
    min-width: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 0 0 150px;
  }
}

.v-expansion-panel {
  flex: 1 0 700px;
  .v-expansion-panel-header {
    transition: all 250ms ease-in-out;
  }

  &.active {
    .v-expansion-panel-header {
      color: #84a315 !important;
    }
  }
}

.v-radio {
  &.type-selected,
  &.columns-selected {
    background-color: #84a315;
    .v-icon {
      color: #fff;
    }
  }

  &::v-deep {
    .v-input--selection-controls__input {
      display: none;
    }
    &.columns-selected .v-label {
      color: #fff;
    }
  }
}

.field-selected {
  background-color: #84a315;
  color: #fff;
}

@media (min-width: 960px) {
  .fields-grid {
    grid-template-columns: 1fr 1fr;
  }
}
</style>