import axios from 'axios'

const state = () => ({
  id: '',
  defaults: [],
  conditions: [],
  availableFilters: []
})

const mutations = {
  changeFilter (state, payload) {
    state.id = payload
  },

  changeFilterByModule (state, payload) {
    const filter = state.defaults.find(filter => filter.module === payload)

    if(filter) state.id = filter.id
  },

  changeAvailableFilters (state, payload) {
    state.availableFilters = payload
  },

  setDefaults (state, payload) {
    state.defaults = payload
  },

  updateConditions (state, payload) {
    state.conditions = payload
  }
}

const actions = {
  loadDefault ({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get('/filter/default')
        .then(res => {
          commit('setDefaults', res.data.defaults)
          resolve()
        })
        .catch(() => {
          this.$snackbar.showMessage({
            content: 'Fehler beim Laden!',
            color: 'error'
          })
          reject()
        })
    })
  },

  setDefaultByModule ({ commit }, module) {
    commit('changeFilterByModule', module)
  },

  getAllFilters ({ commit }) {
    axios
      .get('/filter/all')
      .then(res => {
        commit('changeAvailableFilters', res.data)
      })
      .catch(() => {
        this.$snackbar.showMessage({
          content: 'Fehler beim Laden!',
          color: 'error'
        })
      })
  },

  setCurrent ({ commit }, id) {
    commit('changeFilter', id)
  }
}

const getters = {
  getDefaultFilterByModule: state => module => {
    return state.defaults.find(filter => {
      return filter.module === module
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
