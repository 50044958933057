<template>
  <v-stepper v-model="step">
    <v-stepper-header>
      <v-stepper-step :complete="step > 1" :rules="dataRules" step="1">
        Allgemeine Daten
      </v-stepper-step>

      <v-divider></v-divider>

      <v-stepper-step :complete="step > 2" step="2">
        Gruppierungen und Berechnungen
      </v-stepper-step>

      <v-divider></v-divider>

      <v-stepper-step :complete="step > 3" step="3">
        Bedingungen
      </v-stepper-step>
    </v-stepper-header>

    <v-stepper-items>
      <v-stepper-content step="1">
        <v-form ref="dataForm" v-model="dataFormValid" lazy-validation>
          <v-row>
            <v-col cols="12" lg="6"
              ><v-text-field
                filled
                dense
                hide-details="auto"
                label="Name"
                v-model="name"
                :rules="inputRules"
              ></v-text-field
            ></v-col>
            <v-col cols="12" lg="6"
              ><v-text-field
                filled
                dense
                hide-details="auto"
                label="Beschreibung"
                v-model="description"
                :rules="inputRules"
              ></v-text-field
            ></v-col>
          </v-row>
          <v-row>
            <v-col cols="12" lg="6"
              ><v-autocomplete
                filled
                dense
                hide-details="auto"
                label="Primäres Modul"
                :items="categories"
                item-text="name"
                clearable
                return-object
                v-model="primaryModule"
                :rules="inputRules"
              ></v-autocomplete
            ></v-col>
            <v-col cols="12" lg="6"
              ><v-autocomplete
                filled
                dense
                hide-details="auto"
                label="Weitere Module (Max 2)"
                :items="filteredCategories"
                item-text="name"
                multiple
                chips
                deletable-chips
                small-chips
                clearable
                return-object
                v-model="additionalModules"
                :rules="additionalModulesRules"
              >
                <template v-slot:selection="data">
                  <v-chip
                    small
                    close
                    color="primary"
                    @click:close="removeModule(data.item)"
                    >{{ data.item.name }}</v-chip
                  >
                </template>
              </v-autocomplete></v-col
            >
          </v-row>
          <template v-if="primaryModule">
            <v-row>
              <v-col cols="12"
                ><v-autocomplete
                  filled
                  dense
                  hide-details="auto"
                  label="Spalten auswählen"
                  counter="25"
                  :counter-value="() => columns.length"
                  :items="availableFields"
                  :item-text="
                    (item) =>
                      `${item.portal_field_category.name}: ${item.field_name}`
                  "
                  multiple
                  item-value="id"
                  clearable
                  return-object
                  v-model="columns"
                  :rules="columnRules"
                >
                  <template v-slot:selection="data">
                    <v-chip
                      class="my-2"
                      small
                      close
                      :color="data.item.portal_field_category.color"
                      @click:close="removeColumn(data.item)"
                      >{{ data.item.portal_field_category.name }}:
                      {{ data.item.field_name }}</v-chip
                    >
                  </template></v-autocomplete
                ></v-col
              >
            </v-row>
            <v-row v-if="sortedFields && sortedFields.length > 0">
              <v-col cols="12">
                <h3 class="primary--text">Spalten sortieren</h3>
              </v-col>
              <v-col>
                <draggable
                  v-model="sortedFields"
                  v-bind="dragOptions"
                  class="d-flex flex-wrap"
                  style="column-gap: 12px"
                >
                  <v-chip
                    small
                    class="draggable-chip my-2"
                    v-for="element in sortedFields"
                    :key="element.id"
                    :color="element.portal_field_category.color"
                  >
                    {{ element.field_name }}
                  </v-chip>
                </draggable>
              </v-col>
            </v-row>
          </template>
          <v-row class="ma-0 mt-4">
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              @click="checkStep1"
              :disabled="!dataFormValid"
            >
              Weiter
            </v-btn>
          </v-row>
        </v-form>
      </v-stepper-content>

      <v-stepper-content step="2">
        <v-row>
          <v-col cols="12">
            <h3 class="primary--text">Gruppierung</h3>
          </v-col>
          <v-col>
            <v-row>
              <v-col cols="8" lg="6"
                ><v-autocomplete
                  filled
                  dense
                  hide-details="auto"
                  label="Feld auswählen"
                  :items="availableFields"
                  :item-text="
                    (item) =>
                      `${item.portal_field_category.name}: ${item.field_name}`
                  "
                  item-value="id"
                  clearable
                  return-object
                  v-model="groupBy.field"
                ></v-autocomplete
              ></v-col>
              <v-col cols="4" lg="4" class="d-flex align-center">
                <v-radio-group
                  v-model="groupBy.sort"
                  row
                  hide-details="auto"
                  class="pt-0 mt-0"
                >
                  <v-radio label="Aufsteigend" value="ASC"></v-radio>
                  <v-radio label="Absteigend" value="DESC"></v-radio>
                </v-radio-group>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12"><h3 class="primary--text">Berechnungen</h3></v-col>
          <v-col>
            <v-row v-if="calculations.length === 0">
              <v-col cols="8" lg="6" class="d-flex justify-center">
                <v-btn
                  x-small
                  color="primary"
                  min-width="20px"
                  max-width="20px"
                  @click="addCalculation(0)"
                >
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
              </v-col>
            </v-row>
            <template v-for="(item, index) in calculations">
              <div :key="index" class="mb-4">
                <v-row>
                  <v-col cols="7" lg="6"
                    ><v-autocomplete
                      filled
                      dense
                      hide-details="auto"
                      label="Feld auswählen"
                      :items="numberColumns"
                      :item-text="
                        (item) =>
                          `${item.portal_field_category.name}: ${item.field_name}`
                      "
                      item-value="id"
                      clearable
                      return-object
                      v-model="item.field"
                    ></v-autocomplete
                  ></v-col>
                  <v-col cols="3" lg="5" class="d-flex align-center">
                    <v-radio-group
                      v-model="item.method"
                      row
                      hide-details="auto"
                      class="pt-0 mt-0"
                    >
                      <v-radio label="Summe" value="SUM"></v-radio>
                      <v-radio label="Durchschnitt" value="AVG"></v-radio>
                      <v-radio label="kleinster Wert" value="MIN"></v-radio>
                      <v-radio label="größter Wert" value="MAX"></v-radio>
                    </v-radio-group>
                  </v-col>
                  <v-col
                    cols="2"
                    lg="1"
                    class="d-flex justify-center align-center"
                    style="column-gap: 8px"
                  >
                    <v-btn
                      x-small
                      color="primary"
                      min-width="20px"
                      max-width="20px"
                      @click="addCalculation(index)"
                    >
                      <v-icon>mdi-plus</v-icon>
                    </v-btn>
                    <v-btn
                      x-small
                      color="error"
                      min-width="20px"
                      max-width="20px"
                      @click="removeCalculation(index)"
                    >
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </div>
            </template>
          </v-col>
        </v-row>
        <v-row class="ma-0 mt-4">
          <v-btn text @click="step = 1"> Zurück </v-btn>

          <v-spacer></v-spacer>
          <v-btn color="primary" @click="step = 3"> Weiter </v-btn>
        </v-row>
      </v-stepper-content>

      <v-stepper-content step="3">
        <v-form
          ref="conditionsForm"
          lazy-validation
          v-model="conditionFormValid"
        >
          <v-row>
            <v-col cols="12" md="6">
              <h3 class="primary--text mb-4">alle Bedingungen treffen zu:</h3>
              <v-row
                justify="center"
                align="center"
                class="py-4"
                v-if="conditionAll.length == 0"
              >
                <v-btn
                  x-small
                  color="primary"
                  min-width="20px"
                  max-width="20px"
                  class="mr-2"
                  @click="addCondition(0, 'all')"
                >
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
                <v-btn
                  x-small
                  color="error"
                  min-width="20px"
                  max-width="20px"
                  @click="removeCondition(0, 'all')"
                >
                  <v-icon>mdi-close</v-icon>
                </v-btn></v-row
              >
              <v-row v-for="(condition, index) in conditionAll" :key="index">
                <v-col>
                  <v-autocomplete
                    dense
                    filled
                    clearable
                    hide-details="auto"
                    :rules="inputRules"
                    :items="availableFields"
                    item-value="id"
                    :item-text="
                      (item) =>
                        `${item.portal_field_category.name}: ${item.field_name}`
                    "
                    v-model="condition.field"
                    required
                    @change="setDataType(condition)"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="2" v-if="condition.data_type !== 'text'">
                  <v-select
                    class="condition-select mt-0"
                    hide-details="auto"
                    dense
                    height="100%"
                    full-width
                    value="e"
                    v-model="condition.operator"
                    :menu-props="{
                      'content-class': 'condition-select-menu',
                      bottom: true,
                      'offset-y': true,
                    }"
                    hide-selected
                    :items="operators.number"
                  >
                    <template v-slot:selection="{ item }">
                      <v-icon
                        color="white"
                        v-if="item.hasOwnProperty('icon')"
                        >{{ item.icon }}</v-icon
                      >
                      <span v-else class="white--text body-2 text-center">{{
                        item.text
                      }}</span>
                    </template>
                    <template v-slot:item="{ item }">
                      <v-icon v-if="item.hasOwnProperty('icon')">{{
                        item.icon
                      }}</v-icon>
                      <span class="body-2" v-else>{{ item.text }}</span>
                    </template>
                  </v-select>
                </v-col>
                <v-col cols="2" v-else>
                  <v-select
                    class="condition-select mt-0"
                    hide-details="auto"
                    dense
                    height="100%"
                    full-width
                    value="e"
                    v-model="condition.operator"
                    :menu-props="{
                      'content-class': 'condition-select-menu',
                      bottom: true,
                      'offset-y': true,
                    }"
                    hide-selected
                    :items="operators.text"
                  >
                    <template v-slot:selection="{ item }">
                      <span class="white--text body-2 text-center">{{
                        item.text
                      }}</span>
                    </template>
                    <template v-slot:item="{ item }">
                      <span class="body-2">{{ item.text }}</span>
                    </template>
                    <template v-slot:append-outer>
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            class="mt-n1"
                            small
                            v-bind="attrs"
                            v-on="on"
                            color="info"
                            icon
                          >
                            <v-icon> mdi-help-circle-outline </v-icon>
                          </v-btn>
                        </template>
                        <span>% als Platzhalter verwenden</span>
                      </v-tooltip>
                    </template>
                  </v-select>
                </v-col>
                <template>
                  <v-col v-if="condition.data_type == 'date'">
                    <v-menu
                      ref="menu"
                      v-model="condition.menu"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          prepend-inner-icon="mdi-calendar"
                          readonly
                          filled
                          dense
                          hide-details="auto"
                          :disabled="
                            condition.operator === 'null' ||
                            condition.operator === 'not_null'
                          "
                          :value="$filters.formatDate(condition.value)"
                          v-bind="attrs"
                          v-on="on"
                        >
                          <template v-slot:append-outer>
                            <v-btn
                              x-small
                              color="primary"
                              min-width="20px"
                              max-width="20px"
                              class="mr-2"
                              @click="addCondition(index, 'all')"
                            >
                              <v-icon>mdi-plus</v-icon>
                            </v-btn>
                            <v-btn
                              x-small
                              color="error"
                              min-width="20px"
                              max-width="20px"
                              @click="removeCondition(index, 'all')"
                            >
                              <v-icon>mdi-close</v-icon>
                            </v-btn>
                          </template>
                        </v-text-field>
                      </template>
                      <v-card flat>
                        <v-card-text>
                          <v-date-picker
                            v-model="condition.value"
                            color="secondary"
                            first-day-of-week="1"
                            landscape
                            locale="de-DE"
                          >
                          </v-date-picker>
                        </v-card-text>
                      </v-card>
                    </v-menu>
                  </v-col>
                  <v-col v-else>
                    <v-text-field
                      dense
                      filled
                      hide-details="auto"
                      :disabled="
                        condition.operator === 'null' ||
                        condition.operator === 'not_null'
                      "
                      v-model="condition.value"
                      required
                    >
                      <template v-slot:append-outer>
                        <v-btn
                          x-small
                          color="primary"
                          min-width="20px"
                          max-width="20px"
                          class="mr-2"
                          @click="addCondition(index, 'all')"
                        >
                          <v-icon>mdi-plus</v-icon>
                        </v-btn>
                        <v-btn
                          x-small
                          color="error"
                          min-width="20px"
                          max-width="20px"
                          @click="removeCondition(index, 'all')"
                        >
                          <v-icon>mdi-close</v-icon>
                        </v-btn>
                      </template>
                    </v-text-field>
                  </v-col>
                </template>
              </v-row>
            </v-col>
            <v-col cols="12" md="6">
              <h3 class="primary--text mb-4">
                mindestens eine Bedingung trifft zu:
              </h3>
              <v-row
                justify="center"
                align="center"
                class="py-4"
                v-if="conditionOne.length == 0"
              >
                <v-btn
                  x-small
                  color="primary"
                  min-width="20px"
                  max-width="20px"
                  class="mr-2"
                  @click="addCondition(0, 'one')"
                >
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
                <v-btn
                  x-small
                  color="error"
                  min-width="20px"
                  max-width="20px"
                  @click="removeCondition(0, 'one')"
                >
                  <v-icon>mdi-close</v-icon>
                </v-btn></v-row
              >
              <v-row v-for="(condition, index) in conditionOne" :key="index">
                <v-col>
                  <v-autocomplete
                    dense
                    filled
                    clearable
                    hide-details="auto"
                    :rules="inputRules"
                    :items="availableFields"
                    item-value="id"
                    :item-text="
                      (item) =>
                        `${item.portal_field_category.name}: ${item.field_name}`
                    "
                    v-model="condition.field"
                    required
                    @change="setDataType(condition)"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="2" v-if="condition.data_type !== 'text'">
                  <v-select
                    class="condition-select mt-0"
                    hide-details="auto"
                    dense
                    height="100%"
                    full-width
                    value="e"
                    v-model="condition.operator"
                    :menu-props="{
                      'content-class': 'condition-select-menu',
                      bottom: true,
                      'offset-y': true,
                    }"
                    hide-selected
                    :items="operators.number"
                  >
                    <template v-slot:selection="{ item }">
                      <v-icon
                        color="white"
                        v-if="item.hasOwnProperty('icon')"
                        >{{ item.icon }}</v-icon
                      >
                      <span v-else class="white--text body-2 text-center">{{
                        item.text
                      }}</span>
                    </template>
                    <template v-slot:item="{ item }">
                      <v-icon v-if="item.hasOwnProperty('icon')">{{
                        item.icon
                      }}</v-icon>
                      <span class="body-2" v-else>{{ item.text }}</span>
                    </template>
                  </v-select>
                </v-col>
                <v-col cols="2" v-else>
                  <v-select
                    class="condition-select mt-0"
                    hide-details="auto"
                    dense
                    height="100%"
                    full-width
                    value="e"
                    v-model="condition.operator"
                    :menu-props="{
                      'content-class': 'condition-select-menu',
                      bottom: true,
                      'offset-y': true,
                    }"
                    hide-selected
                    :items="operators.text"
                  >
                    <template v-slot:selection="{ item }">
                      <span class="white--text body-2 text-center">{{
                        item.text
                      }}</span>
                    </template>
                    <template v-slot:item="{ item }">
                      <span class="body-2">{{ item.text }}</span>
                    </template>
                    <template v-slot:append-outer>
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            class="mt-n1"
                            small
                            v-bind="attrs"
                            v-on="on"
                            color="info"
                            icon
                          >
                            <v-icon> mdi-help-circle-outline </v-icon>
                          </v-btn>
                        </template>
                        <span>% als Platzhalter verwenden</span>
                      </v-tooltip>
                    </template>
                  </v-select>
                </v-col>
                <template>
                  <v-col v-if="condition.data_type == 'date'">
                    <v-menu
                      ref="menu"
                      v-model="condition.menu"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          prepend-inner-icon="mdi-calendar"
                          readonly
                          filled
                          dense
                          :disabled="
                            condition.operator === 'null' ||
                            condition.operator === 'not_null'
                          "
                          hide-details="auto"
                          :value="$filters.formatDate(condition.value)"
                          v-bind="attrs"
                          v-on="on"
                        >
                          <template v-slot:append-outer>
                            <v-btn
                              x-small
                              color="primary"
                              min-width="20px"
                              max-width="20px"
                              class="mr-2"
                              @click="addCondition(index, 'one')"
                            >
                              <v-icon>mdi-plus</v-icon>
                            </v-btn>
                            <v-btn
                              x-small
                              color="error"
                              min-width="20px"
                              max-width="20px"
                              @click="removeCondition(index, 'one')"
                            >
                              <v-icon>mdi-close</v-icon>
                            </v-btn>
                          </template>
                        </v-text-field>
                      </template>
                      <v-card flat>
                        <v-card-text>
                          <v-date-picker
                            v-model="condition.value"
                            color="secondary"
                            first-day-of-week="1"
                            landscape
                            locale="de-DE"
                          >
                          </v-date-picker>
                        </v-card-text>
                      </v-card>
                    </v-menu>
                  </v-col>
                  <v-col v-else
                    ><v-text-field
                      dense
                      filled
                      hide-details="auto"
                      :disabled="
                        condition.operator === 'null' ||
                        condition.operator === 'not_null'
                      "
                      v-model="condition.value"
                      required
                    >
                      <template v-slot:append-outer>
                        <v-btn
                          x-small
                          color="primary"
                          min-width="20px"
                          max-width="20px"
                          class="mr-2"
                          @click="addCondition(index, 'one')"
                        >
                          <v-icon>mdi-plus</v-icon>
                        </v-btn>
                        <v-btn
                          x-small
                          color="error"
                          min-width="20px"
                          max-width="20px"
                          @click="removeCondition(index, 'one')"
                        >
                          <v-icon>mdi-close</v-icon>
                        </v-btn>
                      </template>
                    </v-text-field></v-col
                  >
                </template>
              </v-row>
            </v-col>
          </v-row>
        </v-form>
        <v-row class="ma-0 mt-4">
          <v-btn text @click="step = 2"> Zurück </v-btn>

          <v-spacer></v-spacer>
          <v-btn color="primary" @click="checkBedingungen">
            Bericht erstellen
          </v-btn>
        </v-row>
      </v-stepper-content>
      <v-stepper-content step="4">
        <bericht-table v-if="step === 4"
          :headers="sortedFields"
          :items="items"
          :primary-module="primaryModule"
          :group-by="groupBy"
          :calculations="calculations"
        ></bericht-table>
        <v-row class="ma-0 mt-4">
          <v-btn text @click="step = 3"> Zurück </v-btn>

          <v-spacer></v-spacer>
        </v-row>
      </v-stepper-content>
    </v-stepper-items>
    <v-overlay absolute v-model="loading">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </v-stepper>
</template>

<script>
import draggable from "vuedraggable";
import BerichtTable from "./DetailsTable.vue";

export default {
  components: {
    draggable,
    "bericht-table": BerichtTable,
  },
  data: () => ({
    step: 1,
    id: null,
    items: [],
    categories: [],
    name: "",
    description: "",
    dataFormValid: true,
    conditionFormValid: true,
    columns: [],
    loading: false,
    calculations: [],
    conditionAll: [],
    conditionOne: [],
    groupBy: {
      field: null,
      sort: null,
    },

    operators: {
      number: [
        { value: "e", icon: "mdi-equal" },
        { value: "gt", icon: "mdi-greater-than" },
        { value: "gte", icon: "mdi-greater-than-or-equal" },
        { value: "lt", icon: "mdi-less-than" },
        { value: "lte", icon: "mdi-less-than-or-equal" },
        { value: "null", text: "ist leer" },
        { value: "not_null", text: "ist nicht leer" },
      ],
      text: [
        { value: "contains", text: "Enthält" },
        { value: "not_contains", text: "Enthält nicht" },
        { value: "null", text: "ist leer" },
        { value: "not_null", text: "ist nicht leer" },
      ],
    },
    primaryModule: null,
    additionalModules: [],
    inputRules: [(v) => !!v || "Feld wird benötigt"],
    additionalModulesRules: [
      (v) => v.length <= 2 || "Maximal 2 weitere Module",
    ],
    columnRules: [
      (v) => !!v || "Mindestens 1 Spalte nötig",
      (v) => v.length <= 25 || "Maximal 25 Spalten",
      (v) => v.length > 0 || "Mindestens 1 Spalte nötig",
    ],
  }),
  created() {
    axios
      .get(this.$root.route("getCategories"))
      .then((res) => {
        this.categories = _.sortBy(res.data, 'name');
      })
      .catch(() => {
        this.$snackbar.showMessage({
          content: "Fehler beim Laden",
          color: "error",
        });
      });
  },
  computed: {
    dragOptions() {
      return {
        animation: 200,
        disabled: false,
        ghostClass: "ghost",
      };
    },
    numberColumns: {
      get() {
        return _.sortBy(_.sortBy(this.availableFields.filter((field) => field.data_type === "number"), field => field.field_name.toLowerCase()), 'portal_field_category.name');
      },
    },
    dataRules: {
      get() {
        return [() => this.dataFormValid];
      },
    },
    sortedFields: {
      get() {
        return this.columns;
      },
      set(value) {
        this.columns = value;
      },
    },
    availableFields: {
      get() {
        if (!this.primaryModule) return;
        let fields = [
          ...this.$store.getters["fieldStore/getAllFieldsByCategoryName"](
            this.primaryModule.name
          ),
        ];

        if (
          this.additionalModules.length > 0 &&
          this.additionalModules.length <= 2
        ) {
          this.additionalModules.forEach((module) => {
            fields = [
              ...fields,
              ...this.$store.getters["fieldStore/getAllFieldsByCategoryName"](
                module.name
              ),
            ];
          });
        }

        return _.sortBy(_.sortBy(fields, field => field.field_name.toLowerCase()), 'portal_field_category.name');
      },
    },
    filteredCategories: {
      get() {
        if (!this.primaryModule) return this.categories;

        let categories = this.categories;

        categories = categories.filter(
          (elem) => elem.name !== this.primaryModule.name
        );

        if (this.primaryModule.name === "Organisationen") {
          categories = categories.filter(
            (elem) =>
              elem.name === "Organisationen" || elem.name === "Ansprechpartner"
          );
        } else {
          categories = categories.filter(
            (elem) =>
              elem.name !== "Organisationen" && elem.name !== "Ansprechpartner"
          );
        }

        if (
          this.primaryModule.name === "Photovoltaikparks" ||
          this.primaryModule.name === "Photovoltaikanlagen"
        ) {
          categories = categories.filter(
            (elem) =>
              elem.name !== "Windparks" &&
              elem.name !== "Windenergieanlagen" &&
              elem.name !== "Brennstoffe" &&
              elem.name !== "Brennstoffanlagen"
          );
        }

        if (
          this.primaryModule.name === "Brennstoffe" ||
          this.primaryModule.name === "Brennstoffanlagen"
        ) {
          categories = categories.filter(
            (elem) =>
              elem.name !== "Windparks" &&
              elem.name !== "Windenergieanlagen" &&
              elem.name !== "Photovoltaikparks" &&
              elem.name !== "Photovoltaikanlagen"
          );
        }

        if (
          this.primaryModule.name === "Windparks" ||
          this.primaryModule.name === "Windenergieanlagen"
        ) {
          categories = categories.filter(
            (elem) =>
              elem.name !== "Brennstoffe" &&
              elem.name !== "Brennstoffanlagen" &&
              elem.name !== "Photovoltaikparks" &&
              elem.name !== "Photovoltaikanlagen"
          );
        }

        if (this.primaryModule.name.includes("Eigentümer")) {
          categories = categories.filter(
            (elem) => !elem.name.includes("Betreiber")
          );
        }

        if (this.primaryModule.name.includes("Betreiber")) {
          categories = categories.filter(
            (elem) => !elem.name.includes("Eigentümer")
          );
        }

        return categories;
      },
    },
  },
  methods: {
    removeModule(module) {
      const index = this.additionalModules.findIndex(
        (elem) => elem.id === module.id
      );
      if (index >= 0) this.additionalModules.splice(index, 1);
    },

    removeColumn(column) {
      const index = this.columns.findIndex((elem) => elem.id === column.id);
      if (index >= 0) this.columns.splice(index, 1);
    },

    addCalculation(index) {
      this.calculations.splice(index + 1, 0, {
        field: "",
        method: "",
      });
    },

    removeCalculation(index) {
      this.calculations.splice(index, 1);
    },

    checkStep1() {
      if (this.$refs.dataForm.validate() && this.dataFormValid) this.step = 2;
    },

    checkBedingungen() {
      this.loading = true;
      if (this.$refs.conditionsForm.validate() && this.conditionFormValid) {
        this.saveBericht()
          .then((res) => {
            this.id = res.data;
            this.getBerichtData()
              .then((res) => {
                this.items = res.data.data;
                this.step = 4;
                this.loading = false;
              })
              .catch(() => {
                this.loading = false;

                this.$snackbar.showMessage({
                  content: "Fehler beim Laden!",
                  color: "error",
                });
              });
          })
          .catch(() => {
            this.loading = false;

            this.$snackbar.showMessage({
              content: "Fehler beim Erstellen!",
              color: "error",
            });
          });
      }
    },

    addCondition(index, type) {
      if (type === "all") {
        this.conditionAll.splice(index + 1, 0, {
          field: "",
          operator: "e",
          value: "",
        });
      }

      if (type === "one") {
        this.conditionOne.splice(index + 1, 0, {
          field: "",
          operator: "e",
          value: "",
        });
      }
    },

    removeCondition(index, type) {
      if (type === "all") {
        this.conditionAll.splice(index, 1);
      }
      if (type === "one") {
        this.conditionOne.splice(index, 1);
      }
    },
    setDataType(condition) {
      condition["data_type"] = this.$store.getters[
        "fieldStore/getDataTypeById"
      ](condition.field);
      switch (condition.data_type) {
        case "text":
          condition.operator = "contains";
          break;
        case "number":
          condition.operator = "e";
          break;
        case "date":
          condition.value = null;
          condition.operator = "e";
          break;
        default:
          break;
      }
    },

    saveBericht() {
      return axios.post(this.$root.route("berichte.save"), {
        id: this.id,
        name: this.name,
        description: this.description,
        primaryModule: this.primaryModule.id,
        additionalModules: _.map(this.additionalModules, "id"),
        fields: _.map(this.sortedFields, "id"),
        conditionsAnd: this.conditionAll,
        conditionsOr: this.conditionOne,
        groupBy: this.groupBy,
        calculations: this.calculations.length > 0 ? this.calculations : false,
      });
    },

    getBerichtData() {
      return axios.post(this.$root.route("berichte.data"), {
        id: this.id,
        name: this.name,
        description: this.description,
        primaryModule: this.primaryModule.id,
        additionalModules: _.map(this.additionalModules, "id"),
        fields: _.map(this.sortedFields, "id"),
        conditionsAnd: this.conditionAll,
        conditionsOr: this.conditionOne,
        groupBy: this.groupBy,
        calculations: this.calculations.length > 0 ? this.calculations : false,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.condition-select::v-deep {
  border-radius: 4px;
  .v-input__append-outer {
    margin-left: 4px;
  }
  .v-input__control {
    .v-input__slot::before,
    .v-input__slot::after {
      content: unset;
    }
    .v-input__slot {
      background: #ffc107;

      .v-select__slot {
        .v-select__selections {
          justify-content: center;
          align-items: center;
          input {
            display: none;
          }
        }
        .v-input__append-inner {
          display: none;
        }
      }
    }
  }
}
.condition-select-menu::v-deep {
  box-shadow: unset;

  .v-list {
    padding: 0;
    .v-list-item {
      margin: 4px 0;
      padding: 0;
      border-radius: 4px;
      justify-content: center;

      &::before {
        border-radius: 4px;
      }
    }
  }
}
</style>