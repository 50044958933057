import axios from 'axios'

const state = () => ({
  notifications: []
})

const mutations = {
  updateNotifications (state, payload) {
    state.notifications = payload
  },

  removeNotification (state, payload) {
    const index = _.findIndex(state.notifications, ['id', payload])
    if (index >= 0) state.notifications.splice(index, 1)
  }
}

const actions = {
  loadNotifications ({ commit }, { vm }) {
    axios.get('/getNotifications').then(res => {
      commit('updateNotifications', res.data.unreadNotifications)

      let content = 'Es gibt neue News-Artikel:<br>'

      if (res.data.newNotifications.length > 0) {
        res.data.newNotifications.forEach(elem => {
          content += elem.data.title + '<br>'
        })

        vm.$snackbar.showMessage({ content: content, color: 'info' })
      }
    })
  },

  removeNotification ({ commit }, id) {
    commit('removeNotification', id)
  }
}

const getters = {}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
