<template>
  <div>
    <v-btn color="primary" @click="show = true">E-Mail-Adresse ändern</v-btn>
    <v-dialog v-model="show" max-width="400">
      <v-card>
        <v-toolbar color="primary" dark flat>
          <v-toolbar-title>E-Mail-Adresse ändern</v-toolbar-title>
        </v-toolbar>
        <v-form @submit.prevent="sendEmailVerificationLink">
          <v-card-text class="pt-4 px-4">
            <v-text-field
              filled
              dense
              hide-details="auto"
              label="Neue E-Mail-Adresse"
              v-model="newEmail"
              :error-messages="errors"
            ></v-text-field>
          </v-card-text>
          <v-card-actions>
            <v-btn color="error" @click.stop="show = false" x-small
              >abbrechen</v-btn
            >
            <v-spacer></v-spacer>
            <v-btn color="primary" type="submit" small
              >E-Mail-Adresse ändern</v-btn
            >
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data: () => ({
    newEmail: "",
    show: false,
    errors: [],
  }),
  props: {
    user: Number
  },
  methods: {
    sendEmailVerificationLink() {
      axios
        .post(this.$root.route("user.changeEmail"), {
          email: this.newEmail,
          user: this.user
        })
        .then(() => {
          (this.show = false),
            this.$snackbar.showMessage({
              content: `E-Mail-Adresse geändert`,
              color: "primary",
            });
        })
        .catch((err) => {
          this.errors = err.response.data.errors.email;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
</style>