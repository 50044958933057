<template>
  <v-row class="mx-0">
    <v-col style="position: relative">
      <v-form
        v-model="valid"
        ref="profileForm"
        :action="route('user.save')"
        @submit.prevent="saveProfile()"
      >
        <h2 class="primary--text">Benutzerdaten</h2>
        <div class="profile-grid">
          <div class="profile-item my-1">
            <div class="profile-title pa-2">Name</div>
            <div class="profile-divider"></div>
            <div class="profile-value py-2">
              <v-text-field
                filled
                dense
                hide-details="auto"
                :rules="inputRules"
                v-model="user.name"
              ></v-text-field>
            </div>
          </div>
          <div class="profile-item my-1">
            <div class="profile-title pa-2">E-Mail-Adresse</div>
            <div class="profile-divider"></div>
            <div class="profile-value py-2 d-flex align-center">
              <v-text-field
                filled
                dense
                hide-details="auto"
                disabled
                v-model="user.email"
              ></v-text-field>
              <v-btn class="ml-4" :href="route('user.email')"
                >E-Mail-Adresse ändern</v-btn
              >
            </div>
          </div>
          <div class="profile-item my-1">
            <div class="profile-title pa-2">Telefon</div>
            <div class="profile-divider"></div>
            <div class="profile-value py-2">
              <v-text-field
                filled
                dense
                hide-details="auto"
                :rules="inputRules"
                v-model="user.phone"
              ></v-text-field>
            </div>
          </div>
          <div class="profile-item my-1">
            <div class="profile-title pa-2">Avatar</div>
            <div class="profile-divider"></div>
            <div class="profile-value py-2" style="min-width: 50%">
              <v-file-input
                v-model="newAvatar"
                accept="image/png, image/jpeg, image/webp"
                :rules="fileRules"
                show-size
                dense
                filled
                label="Neuer Avatar"
                hide-details="auto"
                prepend-inner-icon="mdi-camera"
                prepend-icon=""
                style="width: 100%"
              ></v-file-input>
            </div>
          </div>
        </div>
        <v-row class="mx-0 mt-4">
          <v-btn color="primary" type="submit">Profil speichern</v-btn>
        </v-row>
      </v-form>
    </v-col>
    <v-col class="d-flex align-center">
      <v-avatar size="200" class="mx-0">
        <v-img :src="previewImage"></v-img>
      </v-avatar>
    </v-col>
  </v-row>
</template>

<script>
export default {
  data: () => ({
    user: [],
    newAvatar: null,
    avatar: null,
    valid: true,
    inputRules: [(v) => !!v || "Feld wird benötigt"],
    fileRules: [
      (v) => !v || v.size < 10000000 || "Bild sollte kleiner als 10MB sein",
    ],
  }),

  computed: {
    previewImage() {
      if (this.newAvatar) {
        return URL.createObjectURL(this.newAvatar);
      }

      if (this.avatar) {
        return this.avatar;
      }

      return "/images/user-default.webp";
    },
  },

  methods: {
    saveProfile() {
      this.$refs.profileForm.validate();

      if (this.valid) {
        const formData = new FormData();
        formData.append("name", this.user.name);
        formData.append("phone", this.user.phone);
        if (this.newAvatar != null) formData.append("avatar", this.newAvatar);

        axios
          .post(this.$refs.profileForm.$attrs.action, formData)
          .then((res) => {
            this.$snackbar.showMessage({
              content: "Profile gespeichert",
              color: "primary",
            });
          })
          .catch(() => {
            this.$snackbar.showMessage({
              content: "Fehler beim Speichern",
              color: "error",
            });
          });
      }
    },
  },

  created() {
    axios
      .get("/user")
      .then((res) => {
        this.user = res.data;
        this.avatar = res.data.avatar ? res.data.avatar.image_url : '';
      })
      .catch(() => {
        this.$snackbar.showMessage({
          content: "Fehler beim Laden!",
          color: "error",
        });
      });
  },
};
</script>

<style lang="scss" scoped>
.profile-grid {
  display: grid;
  grid-template-columns: 1fr;
  column-gap: 30px;

  .profile-item {
    display: flex;
    column-gap: 15px;
    align-items: center;

    .profile-title {
      flex-basis: 150px;
      text-align: start;
      color: #a6adaf;
    }

    .profile-value {
      color: #919699;
      font-weight: 700;
    }

    .profile-divider {
      border: 1px solid #f7f9fa;
      height: 100%;
    }
  }
}
</style>