import axios from 'axios'

const state = () => ({
  favorite: null
})

const mutations = {
  setFavorite (state, payload) {
    state.favorite = payload
  },

  removeFavorite (state, payload) {
    state.favorite = null
  }
}

const getters = {
  checkFavorite: (state) => {
    return state.favorite !== null
  }
}

const actions = {
  loadFavorite ({ commit }) {
    axios
      .get('/favorite')
      .then((res) => {
        commit('setFavorite', res.data.favorite)
      })
      .catch(() => {
        this.$snackbar.showMessage({ content: 'Fehler beim Laden!', color: 'error' })
      })
  },

  removeFavorite ({ commit }) {
    commit('removeFavorite')
  },

  setFavorite ({ commit }, favorite) {
    commit('setFavorite', favorite)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
