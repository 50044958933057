<template>
  <v-row justify="center">
    <v-col class="card-wrapper" cols="auto">
      <v-card
        elevation="24"
        class="mx-auto rounded-lg login-card"
        v-bind:class="{ back: showRegisterForm }"
      >
        <v-snackbar
          v-model="showSnackbar"
          v-if="status"
          top
          color="primary"
          absolute
        >
          {{ status }}

          <template v-slot:action="{ attrs }">
            <v-btn
              color="white"
              icon
              v-bind="attrs"
              @click="showSnackbar = false"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </template>
        </v-snackbar>

        <v-card-title>
          <slot name="logo"></slot>
        </v-card-title>
        <v-card-text class="py-0">
          <slot name="content"></slot>
        </v-card-text>
        <v-card-actions v-bind:class="{ 'py-7': !showActions }">
          <v-row class="mx-0" v-if="showActions">
            <slot name="actions"></slot>
            <v-spacer></v-spacer>
            <v-btn
              link
              text
              color="primary"
              plain
              class="text-transform-none px-0"
              v-if="allowRegister"
              @click="toggleRegisterForm"
            >
              (+) registrieren
            </v-btn>
          </v-row>
        </v-card-actions>

        <v-card
          color="primary"
          dark
          class="register-card"
          v-bind:class="{ active: showRegisterForm }"
        >
          <v-card-title>
            <h1 class="white--text">Konto registrieren</h1>
          </v-card-title>
          <v-card-text class="py-0">
            <slot name="register"></slot>
          </v-card-text>
        </v-card>
      </v-card>
      <v-btn
        class="register-fab"
        :color="!showRegisterForm ? 'primary' : 'error'"
        x-large
        absolute
        fab
        @click="toggleRegisterForm"
        v-bind:class="{ active: showRegisterForm }"
        v-if="allowRegister"
      >
        <v-icon size="30">mdi-plus</v-icon>
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
export default {
  data: () => ({
    showRegisterForm: false,
    showSnackbar: true,
  }),
  props: {
    showActions: Boolean,
    allowRegister: Boolean,
    status: String,
    showRegister: Boolean,
  },
  methods: {
    toggleRegisterForm() {
      this.showRegisterForm = !this.showRegisterForm;
    },
  },

  created() {
    this.showRegisterForm = this.showRegister;

    localStorage.clear();
    sessionStorage.clear();
  },
};
</script>

<style lang="scss" scoped>
.v-card__subtitle,
.v-card__text,
.v-card__title,
.v-card__actions {
  padding: 60px;
  max-width: 520px;
}

.card-wrapper {
  position: relative;
}

.register-fab {
  right: 0;
  top: 60px;
  transform: translateX(50%) translateY(15%);

  &.active {
    transform: translateX(50%) translateY(15%) rotate(45deg);
  }
}

.login-card {
  transform: scale(1.05);
  transition: all 300ms ease-in-out;
  top: 0;
  &.back {
    transform: scale(1);
    top: -30px;
  }
}

.register-card {
  position: absolute;
  left: 50%;
  right: 50%;
  top: 50%;
  bottom: 50%;
  overflow: hidden;
  transform: scale(1.05);
  transition: all 500ms ease-in-out;

  .v-card__text,
  .v-card__title {
    opacity: 0;
    transition: opacity 150ms ease-in-out;
    transition-delay: 0;
  }

  &.active {
    top: 30px;
    left: 0;
    right: 0;
    bottom: -130px;

    .v-card__text,
    .v-card__title {
      transition: opacity 500ms ease-in-out;
      transition-delay: 400ms;
      opacity: 1;
    }
  }
}
</style>