<template>
  <div>
    <v-data-table
      hide-default-footer
      :headers="headers"
      :items="cloneItems"
      item-key="id"
      :search="search"
      :options="{ itemsPerPage: itemsPerPage }"
      :page.sync="page"
      @page-count="pageCount = $event"
      dense
      :footer-props="{
        itemsPerPageText: 'Zeilen pro Seite: ',
        itemsPerPageOptions: [5, 10, 15, 30, -1],
      }"
    >
      <template v-slot:item.public="{ item }">
        <v-icon
          color="primary"
          v-if="item.public == true"
          >mdi-check-circle-outline</v-icon
        >
        <v-icon v-else color="error">mdi-close-circle-outline</v-icon>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              icon
              color="error"
              @click.stop="openDialog(item.id)"
            >
              <v-icon> mdi-delete </v-icon>
            </v-btn>
          </template>
          <span>Löschen</span>
        </v-tooltip>
      </template>
      <template
        v-slot:footer="{
          props: { pagination, itemsPerPageText, itemsPerPageOptions, options },
        }"
      >
        <table-footer
          :pagination="pagination"
          :itemsPerPageText="itemsPerPageText"
          :itemsPerPageOptions="itemsPerPageOptions"
          :tableOptions="options"
          :pageCount="pageCount"
        ></table-footer>
      </template>
    </v-data-table>
    <v-dialog v-model="dialog" max-width="400">
      <v-card>
        <v-toolbar color="primary" dark flat>
          <v-toolbar-title>Filter löschen?</v-toolbar-title>
        </v-toolbar>
        <v-card-actions>
          <v-btn color="secondary" @click.stop="dialog = false" x-small
            >Abbrechen</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn color="error" type="btn" @click="deleteFilter()" small
            >Löschen</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import AuthCard from "../components/AuthCard.vue";
import TableFooter from "../components/TableFooter.vue";

export default {
  components: {
    "table-footer": TableFooter,
    AuthCard,
  },
  data: () => ({
    page: 1,
    pageCount: 0,
    itemsPerPage: 10,
    selected: [],
    dialog: false,
    currentId: 0,
    cloneItems: [],
    headers: [
      { text: "Id", value: "id" },
      { text: "Name", value: "name" },
      { text: "Benutzer", value: "user.name" },
      { text: "Öffentlich", value: "public" },
      { text: "Aktionen", value: "actions" },
    ],
  }),
  created() {
    this.cloneItems = _.cloneDeep(this.items);
  },
  props: {
    items: Array,
    search: String,
  },
  methods: {
    openDialog(id) {
      this.dialog = true;
      this.currentId = id;
    },
    deleteFilter() {
      axios
        .delete(this.$root.route("admin.filters.delete", this.currentId))
        .then((res) => {
          this.dialog = false;
          this.$snackbar.showMessage({
            content: "Filter gelöscht",
            color: "primary",
          });

          this.cloneItems.splice(
            this.cloneItems.findIndex((elem) => elem.id === this.currentId),
            1
          );
        })
        .catch(() => {
          this.dialog = false;
          this.$snackbar.showMessage({
            content: "Fehler beim Löschen",
            color: "error",
          });
        });
    },
  },
};
</script>

<style lang="scss" scoped>
</style>