<template>
  <div class="px-0">
    <v-form ref="filterForm" v-model="valid">
      <v-row>
        <v-col cols="12" md="6">
          <v-row class="ma-0">
            <v-autocomplete
              dense
              filled
              clearable
              hide-details="auto"
              label="Modul"
              :items="modules"
              item-value="name"
              item-text="name"
              v-model="module"
            ></v-autocomplete>
          </v-row>
        </v-col>
        <v-spacer></v-spacer>
        <v-col cols="12" md="6">
          <v-row class="ma-0">
            <v-autocomplete
              dense
              filled
              clearable
              hide-details="auto"
              label="Einstellungen laden von:"
              :items="filters"
              item-value="id"
              v-model="currentFilter"
            ></v-autocomplete>
          </v-row>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="6">
          <h3 class="primary--text mb-4">alle Bedingungen treffen zu:</h3>
          <v-row
            justify="center"
            align="center"
            class="py-4"
            v-if="conditionAll.length == 0"
          >
            <v-btn
              x-small
              color="primary"
              min-width="20px"
              max-width="20px"
              class="mr-2"
              @click="addCondition(0, 'all')"
            >
              <v-icon>mdi-plus</v-icon>
            </v-btn>
            <v-btn
              x-small
              color="error"
              min-width="20px"
              max-width="20px"
              @click="removeCondition(0, 'all')"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn></v-row
          >
          <v-row v-for="(condition, index) in conditionAll" :key="index">
            <v-col>
              <v-autocomplete
                dense
                filled
                clearable
                hide-details="auto"
                :rules="validationRules"
                :items="fields"
                item-value="id"
                :item-text="
                  (item) =>
                    `${item.portal_field_category.name}: ${item.field_name}`
                "
                v-model="condition.field"
                required
                @change="setDataType(condition)"
              ></v-autocomplete>
            </v-col>
            <v-col cols="2" v-if="condition.data_type !== 'text'">
              <v-select
                class="condition-select mt-0"
                hide-details="auto"
                dense
                height="100%"
                full-width
                value="e"
                v-model="condition.operator"
                :menu-props="{
                  'content-class': 'condition-select-menu',
                  bottom: true,
                  'offset-y': true,
                }"
                hide-selected
                :items="operators.number"
              >
                <template v-slot:selection="{ item }">
                  <v-icon color="white" v-if="item.hasOwnProperty('icon')">{{
                    item.icon
                  }}</v-icon>
                  <span v-else class="white--text body-2 text-center">{{
                    item.text
                  }}</span>
                </template>
                <template v-slot:item="{ item }">
                  <v-icon v-if="item.hasOwnProperty('icon')">{{
                    item.icon
                  }}</v-icon>
                  <span class="body-2" v-else>{{ item.text }}</span>
                </template>
              </v-select>
            </v-col>
            <v-col cols="2" v-else>
              <v-select
                class="condition-select mt-0"
                hide-details="auto"
                dense
                height="100%"
                full-width
                value="e"
                v-model="condition.operator"
                :menu-props="{
                  'content-class': 'condition-select-menu',
                  bottom: true,
                  'offset-y': true,
                }"
                hide-selected
                :items="operators.text"
              >
                <template v-slot:selection="{ item }">
                  <span class="white--text body-2 text-center">{{
                    item.text
                  }}</span>
                </template>
                <template v-slot:item="{ item }">
                  <span class="body-2">{{ item.text }}</span>
                </template>
                <template v-slot:append-outer>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        class="mt-n1"
                        small
                        v-bind="attrs"
                        v-on="on"
                        color="info"
                        icon
                      >
                        <v-icon> mdi-help-circle-outline </v-icon>
                      </v-btn>
                    </template>
                    <span>% als Platzhalter verwenden</span>
                  </v-tooltip>
                </template>
              </v-select>
            </v-col>
            <template>
              <v-col v-if="condition.data_type == 'date'">
                <v-menu
                  ref="menu"
                  v-model="condition.menu"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      prepend-inner-icon="mdi-calendar"
                      readonly
                      filled
                      dense
                      hide-details="auto"
                      :disabled="
                        condition.operator === 'null' ||
                        condition.operator === 'not_null'
                      "
                      :value="$filters.formatDate(condition.value)"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <template v-slot:append-outer>
                        <v-btn
                          x-small
                          color="primary"
                          min-width="20px"
                          max-width="20px"
                          class="mr-2"
                          @click="addCondition(index, 'all')"
                        >
                          <v-icon>mdi-plus</v-icon>
                        </v-btn>
                        <v-btn
                          x-small
                          color="error"
                          min-width="20px"
                          max-width="20px"
                          @click="removeCondition(index, 'all')"
                        >
                          <v-icon>mdi-close</v-icon>
                        </v-btn>
                      </template>
                    </v-text-field>
                  </template>
                  <v-card flat>
                    <v-card-text>
                      <v-date-picker
                        v-model="condition.value"
                        color="secondary"
                        first-day-of-week="1"
                        landscape
                        locale="de-DE"
                      >
                      </v-date-picker>
                    </v-card-text>
                  </v-card>
                </v-menu>
              </v-col>
              <v-col v-else>
                <v-text-field
                  dense
                  filled
                  hide-details="auto"
                  :disabled="
                    condition.operator === 'null' ||
                    condition.operator === 'not_null'
                  "
                  v-model="condition.value"
                  required
                >
                  <template v-slot:append-outer>
                    <v-btn
                      x-small
                      color="primary"
                      min-width="20px"
                      max-width="20px"
                      class="mr-2"
                      @click="addCondition(index, 'all')"
                    >
                      <v-icon>mdi-plus</v-icon>
                    </v-btn>
                    <v-btn
                      x-small
                      color="error"
                      min-width="20px"
                      max-width="20px"
                      @click="removeCondition(index, 'all')"
                    >
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                  </template>
                </v-text-field>
              </v-col>
            </template>
          </v-row>
        </v-col>
        <v-col cols="12" md="6">
          <h3 class="primary--text mb-4">
            mindestens eine Bedingung trifft zu:
          </h3>
          <v-row
            justify="center"
            align="center"
            class="py-4"
            v-if="conditionOne.length == 0"
          >
            <v-btn
              x-small
              color="primary"
              min-width="20px"
              max-width="20px"
              class="mr-2"
              @click="addCondition(0, 'one')"
            >
              <v-icon>mdi-plus</v-icon>
            </v-btn>
            <v-btn
              x-small
              color="error"
              min-width="20px"
              max-width="20px"
              @click="removeCondition(0, 'one')"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn></v-row
          >
          <v-row v-for="(condition, index) in conditionOne" :key="index">
            <v-col>
              <v-autocomplete
                dense
                filled
                clearable
                hide-details="auto"
                :rules="validationRules"
                :items="fields"
                item-value="id"
                :item-text="
                  (item) =>
                    `${item.portal_field_category.name}: ${item.field_name}`
                "
                v-model="condition.field"
                required
                @change="setDataType(condition)"
              ></v-autocomplete>
            </v-col>
            <v-col cols="2" v-if="condition.data_type !== 'text'">
              <v-select
                class="condition-select mt-0"
                hide-details="auto"
                dense
                height="100%"
                full-width
                value="e"
                v-model="condition.operator"
                :menu-props="{
                  'content-class': 'condition-select-menu',
                  bottom: true,
                  'offset-y': true,
                }"
                hide-selected
                :items="operators.number"
              >
                <template v-slot:selection="{ item }">
                  <v-icon color="white" v-if="item.hasOwnProperty('icon')">{{
                    item.icon
                  }}</v-icon>
                  <span v-else class="white--text body-2 text-center">{{
                    item.text
                  }}</span>
                </template>
                <template v-slot:item="{ item }">
                  <v-icon v-if="item.hasOwnProperty('icon')">{{
                    item.icon
                  }}</v-icon>
                  <span class="body-2" v-else>{{ item.text }}</span>
                </template>
              </v-select>
            </v-col>
            <v-col cols="2" v-else>
              <v-select
                class="condition-select mt-0"
                hide-details="auto"
                dense
                height="100%"
                full-width
                value="e"
                v-model="condition.operator"
                :menu-props="{
                  'content-class': 'condition-select-menu',
                  bottom: true,
                  'offset-y': true,
                }"
                hide-selected
                :items="operators.text"
              >
                <template v-slot:selection="{ item }">
                  <span class="white--text body-2 text-center">{{
                    item.text
                  }}</span>
                </template>
                <template v-slot:item="{ item }">
                  <span class="body-2">{{ item.text }}</span>
                </template>
                <template v-slot:append-outer>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        class="mt-n1"
                        small
                        v-bind="attrs"
                        v-on="on"
                        color="info"
                        icon
                      >
                        <v-icon> mdi-help-circle-outline </v-icon>
                      </v-btn>
                    </template>
                    <span>% als Platzhalter verwenden</span>
                  </v-tooltip>
                </template>
              </v-select>
            </v-col>
            <template>
              <v-col v-if="condition.data_type == 'date'">
                <v-menu
                  ref="menu"
                  v-model="condition.menu"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      prepend-inner-icon="mdi-calendar"
                      readonly
                      filled
                      dense
                      :disabled="
                        condition.operator === 'null' ||
                        condition.operator === 'not_null'
                      "
                      hide-details="auto"
                      :value="$filters.formatDate(condition.value)"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <template v-slot:append-outer>
                        <v-btn
                          x-small
                          color="primary"
                          min-width="20px"
                          max-width="20px"
                          class="mr-2"
                          @click="addCondition(index, 'one')"
                        >
                          <v-icon>mdi-plus</v-icon>
                        </v-btn>
                        <v-btn
                          x-small
                          color="error"
                          min-width="20px"
                          max-width="20px"
                          @click="removeCondition(index, 'one')"
                        >
                          <v-icon>mdi-close</v-icon>
                        </v-btn>
                      </template>
                    </v-text-field>
                  </template>
                  <v-card flat>
                    <v-card-text>
                      <v-date-picker
                        v-model="condition.value"
                        color="secondary"
                        first-day-of-week="1"
                        landscape
                        locale="de-DE"
                      >
                      </v-date-picker>
                    </v-card-text>
                  </v-card>
                </v-menu>
              </v-col>
              <v-col v-else
                ><v-text-field
                  dense
                  filled
                  hide-details="auto"
                  :disabled="
                    condition.operator === 'null' ||
                    condition.operator === 'not_null'
                  "
                  v-model="condition.value"
                  required
                >
                  <template v-slot:append-outer>
                    <v-btn
                      x-small
                      color="primary"
                      min-width="20px"
                      max-width="20px"
                      class="mr-2"
                      @click="addCondition(index, 'one')"
                    >
                      <v-icon>mdi-plus</v-icon>
                    </v-btn>
                    <v-btn
                      x-small
                      color="error"
                      min-width="20px"
                      max-width="20px"
                      @click="removeCondition(index, 'one')"
                    >
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                  </template>
                </v-text-field></v-col
              >
            </template>
          </v-row>
        </v-col>
      </v-row>
    </v-form>
    <table-field-draggable :module="module"></table-field-draggable>
    <v-row>
      <v-spacer></v-spacer>
      <v-btn color="primary" @click="saveFilter">Filter speichern</v-btn>
    </v-row>
  </div>
</template>

<script>
import tableFieldDraggable from "../components/TableFieldDraggable.vue";

export default {
  components: {
    tableFieldDraggable,
  },
  data: () => ({
    modules: [],
    loading: false,
    loadingName: false,
    valid: true,
    isChanged: false,
    currentFilter: null,
    conditionAll: [],
    conditionOne: [],
    loadedFilter: null,
    module: "",
    menus: {
      all: false,
      one: false,
    },
    operators: {
      number: [
        { value: "e", icon: "mdi-equal" },
        { value: "gt", icon: "mdi-greater-than" },
        { value: "gte", icon: "mdi-greater-than-or-equal" },
        { value: "lt", icon: "mdi-less-than" },
        { value: "lte", icon: "mdi-less-than-or-equal" },
        { value: "null", text: "ist leer" },
        { value: "not_null", text: "ist nicht leer" },
      ],
      text: [
        { value: "contains", text: "Enthält" },
        { value: "not_contains", text: "Enthält nicht" },
        { value: "null", text: "ist leer" },
        { value: "not_null", text: "ist nicht leer" },
      ],
    },

    validationRules: [(v) => !!v || "Bitte Feld ausfüllen"],
  }),
  props: ['filter', 'oldModule'],
  created() {
    axios
      .get(this.$root.route("getCategories"))
      .then((res) => {
        this.modules = res.data;
      })
      .catch(() => {
        this.$snackbar.showMessage({
          content: "Fehler beim Laden",
          color: "error",
        });
      });

      if(this.filter) {
        this.currentFilter = this.filter
      }

      if(this.oldModule) {
        this.module = this.oldModule
      }
  },
  computed: {
    fields() {
      return _.sortBy(_.sortBy(this.$store.getters["fieldStore/getAllFields"].filter(field => _.map(this.categories, 'name').includes(field.portal_field_category.name)), field => field.field_name.toLowerCase()), 'portal_field_category.name');
    },

    categories() {
      let categories = _.flatten(
        _.partition(
          _.sortBy(
            _.uniqBy(
              _.map(
                this.$store.getters["fieldStore/getAllFields"],
                "portal_field_category"
              ),
              "id"
            ),
            "id"
          ),
          (o) =>
            o.name === "Windparks" ||
            o.name === "Windenergieanlagen" ||
            o.name === "Organisationen" ||
            o.name === "Photovoltaikparks" ||
            o.name === "Photovoltaikanlagen" ||
            o.name === "Brennstoffe" ||
            o.name === "Brennstoffanlagen"
        )
      );

      if (this.module === "Organisationen" || this.module === 'Ansprechpartner') {
        categories = categories.filter(
          (elem) =>
            elem.name === "Organisationen" || elem.name === "Ansprechpartner"
        );
      } else {
        categories = categories.filter(
          (elem) =>
            elem.name !== "Organisationen" && elem.name !== "Ansprechpartner"
        );
      }

      if (
        this.module === "Photovoltaikparks" ||
        this.module === "Photovoltaikanlagen"
      ) {
        categories = categories.filter(
          (elem) =>
            elem.name !== "Windparks" &&
            elem.name !== "Windenergieanlagen" &&
            elem.name !== "Brennstoffe" &&
            elem.name !== "Brennstoffanlagen"
        );
      }

      if (
        this.module === "Brennstoffe" ||
        this.module === "Brennstoffanlagen"
      ) {
        categories = categories.filter(
          (elem) =>
            elem.name !== "Windparks" &&
            elem.name !== "Windenergieanlagen" &&
            elem.name !== "Photovoltaikparks" &&
            elem.name !== "Photovoltaikanlagen"
        );
      }

      if (this.module === "Windparks" || this.module === "Windenergieanlagen") {
        categories = categories.filter(
          (elem) =>
            elem.name !== "Brennstoffe" &&
            elem.name !== "Brennstoffanlagen" &&
            elem.name !== "Photovoltaikparks" &&
            elem.name !== "Photovoltaikanlagen"
        );
      }

      if (this.module === "Eigentümer") {
        categories = categories.filter(
          (elem) => !elem.name.includes("Betreiber")
        );
      }

      if (this.module === "Betreiber") {
        categories = categories.filter(
          (elem) => !elem.name.includes("Eigentümer")
        );
      }

      return categories;
    },

    filters() {
      if(this.module)
        return this.$store.state.filter.availableFilters.filter(
          (filter) =>
            filter.hasOwnProperty("header") || filter.module === this.module
        );

        return this.$store.state.filter.availableFilters;
    },
  },
  watch: {
    currentFilter: {
      handler() {
        this.loadFilter(this.currentFilter);
      },
    },
  },
  methods: {
    setDataType(condition) {
      condition["data_type"] = this.$store.getters[
        "fieldStore/getDataTypeById"
      ](condition.field);
      switch (condition.data_type) {
        case "text":
          condition.operator = "contains";
          break;
        case "number":
          condition.operator = "e";
          break;
        case "date":
          condition.value = null;
          condition.operator = "e";
          break;
        default:
          break;
      }
    },
    addCondition(index, type) {
      if (type === "all") {
        this.conditionAll.splice(index + 1, 0, {
          field: "",
          operator: "e",
          value: "",
        });
      }

      if (type === "one") {
        this.conditionOne.splice(index + 1, 0, {
          field: "",
          operator: "e",
          value: "",
        });
      }
    },

    removeCondition(index, type) {
      if (type === "all") {
        this.conditionAll.splice(index, 1);
      }
      if (type === "one") {
        this.conditionOne.splice(index, 1);
      }
    },

    loadFilter(filterId) {
      if (filterId === "" || filterId === null) return;

      this.conditionAll = [];
      this.conditionOne = [];

      this.loading = true;
      axios
        .post(`/filter/${filterId}`, {
          fieldIds: _.map(this.$store.getters['fieldStore/getAllFields'], 'id')
        })
        .then((res) => {
          if (res.data.module !== this.module) {
            if (this.conditionAll.length == 0 && this.conditionOne.length == 0)
              this.tablesDisabled = true;
            this.loading = false;
            return;
          }

          this.$store.dispatch(
            "fieldStore/setSelectedFields",
            res.data.filterFields
          );

          this.loadedFilter = filterId;

          for (let condition of res.data.filterConditions) {
            let newCondition = {
              field: condition.portal_field_id,
              operator: condition.operator,
              value: condition.value,
              data_type: condition.portal_field.data_type,
            };

            if (condition.conjunction == "or") {
              this.conditionOne.push(newCondition);
              continue;
            }

            if (condition.conjunction == "and") {
              this.conditionAll.push(newCondition);
              continue;
            }
          }

          if (this.conditionAll.length == 0 && this.conditionOne.length == 0)
            this.tablesDisabled = true;
          this.loading = false;
        })
        .catch(() => {
          this.$snackbar.showMessage({
            content: "Fehler beim Laden!",
            color: "error",
          });
          this.loading = false;
        });
    },

    saveFilter() {
      this.$refs.filterForm.validate();

      if (this.valid) {
        this.saveRequest();
      }
    },

    saveRequest() {
      axios
        .post(this.$root.route("admin.filters.save"), {
          name: `Globaler ${this.module} Filter`,
          global: true,
          conditionsAnd: this.conditionAll,
          conditionsOr: this.conditionOne,
          module: this.module,
          selectedFields: this.$store.state.fieldStore.selectedFields,
        })
        .then((res) => {
          this.$store.dispatch("filter/getAllFilters", res.data.id);
          this.$snackbar.showMessage({
            content: "Filter gespeichert!",
            color: "primary",
          });
          window.location.href = this.$root.route('admin.filters.index')
        })
        .catch(() => {
          this.$snackbar.showMessage({
            content: "Fehler beim Speichern!",
            color: "error",
          });
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.condition-select::v-deep {
  border-radius: 4px;
  .v-input__append-outer {
    margin-left: 4px;
  }
  .v-input__control {
    .v-input__slot::before,
    .v-input__slot::after {
      content: unset;
    }
    .v-input__slot {
      background: #ffc107;

      .v-select__slot {
        .v-select__selections {
          justify-content: center;
          align-items: center;
          input {
            display: none;
          }
        }
        .v-input__append-inner {
          display: none;
        }
      }
    }
  }
}

.v-input::v-deep {
  .v-input__append-outer {
    pointer-events: all;
  }
}

.filter-card {
  position: absolute;
  left: 0;
  right: 0;
  top: 5px;
  z-index: 15;

  &-fab {
    right: 0;
    top: 16px;
    transform: translateX(50%);
  }
}

.inputSwitch {
  min-width: 250px;

  &::v-deep .v-label {
    font-size: 14px;
  }
}

.slide-enter-active,
.slide-leave-active {
  transition: top 0.5s, opacity 0.5s;
}
.slide-enter,
.slide-leave-to {
  opacity: 0;
  top: -50%;
}
.condition-select-menu::v-deep {
  box-shadow: unset;

  .v-list {
    padding: 0;
    .v-list-item {
      margin: 4px 0;
      padding: 0;
      border-radius: 4px;
      justify-content: center;

      &::before {
        border-radius: 4px;
      }
    }
  }
}
</style>