<template>
  <v-btn v-if="isBookmark" icon color="warning" @click.stop="removeBookmark()"
    ><v-icon>mdi-bookmark-remove</v-icon></v-btn
  >
  <v-btn v-else icon @click.stop="addBookmark()"
    ><v-icon>mdi-bookmark-plus-outline</v-icon></v-btn
  >
</template>

<script>
export default {
  props: ["currentRoute", "id", "title"],
  data: () => ({
    isBookmark: false,
  }),

  mounted() {
    this.checkBookmark();
  },

  methods: {
    checkBookmark() {
      axios
        .post("/checkBookmark", {
          id: this.id,
          route: this.currentRoute,
        })
        .then(() => {
          this.isBookmark = true;
        })
        .catch(() => {
          this.isBookmark = false;
        });
    },

    addBookmark() {
      axios
        .post("/bookmark", {
          id: this.id,
          route: this.currentRoute,
          title: this.title,
        })
        .then(() => {
          this.checkBookmark();
        })
        .catch(() => {
          this.$snackbar.showMessage({
            content: "Unerwarter Fehler!",
            color: "error",
          });
        });
    },

    removeBookmark() {
      axios
        .delete("/bookmark", {
          data: {
            id: this.id,
            route: this.currentRoute,
          },
        })
        .then(() => {
          this.checkBookmark();
        })
        .catch(() => {
          this.$snackbar.showMessage({
            content: "Unerwarter Fehler!",
            color: "error",
          });
        });
    },
  },
};
</script>

<style lang="scss" scoped>
</style>