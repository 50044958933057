// src/plugins/exportSnackbar.js
const exportSnackbarPlugin = {
  install: (Vue, { store }) => {
    if (!store) {
      throw new Error('Please provide vuex store.')
    }

    Vue.prototype.$exportSnackbar = {
      showMessage: function () {
        store.commit(
          'exportSnackbar/showMessage',
          {},
          { root: true }
        )
      },
      closeMessage: function () {
        store.commit(
          'exportSnackbar/closeMessage',
          {},
          { root: true }
        )
      }
    }
  }
}
export default exportSnackbarPlugin
