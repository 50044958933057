<template>
  <v-card flat>
    <v-card-title class="primary--text"> aktuelle Nachrichten </v-card-title>
    <v-container class="py-0">
      <v-row
        dense
        :class="{ articleRow: $vuetify.breakpoint.lgAndUp }"
      >
        <v-col cols="12" lg="6" v-for="article in news" :key="article.id">
          <v-card>
            <v-card-title class="pt-2 pb-4"
              >{{ article.title }}<v-spacer></v-spacer
              ><span class="body-2">{{
                article.publishDate | formatDateTime
              }}</span></v-card-title
            >
            <v-card-text class="pb-0 articleText">
              <v-img
                width="125px"
                max-width="125px"
                aspect-ratio="1"
                :src="`${article.featuredImage}_thumbnail.webp`"
              ></v-img>

              <div>{{ article.excerpt }}</div></v-card-text
            >
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" text link :href="'/news/' + article.slug"
                >weiterlesen</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="secondary" class="mx-auto" text link href="/news"
        >Alle Artikel anzeigen</v-btn
      >
    </v-card-actions>
    <v-overlay absolute v-model="loading">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </v-card>
</template>

<script>
export default {
  data: () => ({
    loading: true,
    news: [],
  }),
  created() {
    axios
      .get("/news/latest")
      .then((res) => {
        this.news = res.data;
        this.loading = false;
      })
      .catch(() => {
        this.loading = false;
        this.$snackbar.showMessage({
          content: "Fehler beim Laden!",
          color: "error",
        });
      });
  },
};
</script>

<style lang="scss" scoped>
.v-card__title {
  word-break: break-word;
}

.articleText {
  display: flex;
  column-gap: 16px;
}
</style>