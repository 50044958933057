<template>
  <v-row>
    <v-col cols="12">
      <v-row>
        <v-col cols="6">
          <v-text-field
            filled
            dense
            hide-details="auto"
            label="Berechtigung suchen"
            v-model="search"
          ></v-text-field>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12" lg="6">
      <h4 class="mb-4">Verfügbare Berechtigungen</h4>
      <v-list dense elevation="2">
        <v-list-item-group>
          <draggable
            :list="filteredItems"
            group="fields"
            class="draggable-wrapper"
          >
            <v-list-item
              v-for="(element, index) in filteredItems"
              :key="element.id"
            >
              <v-list-item-content>
                <v-list-item-title
                  v-text="
                    `${element.portal_field_category.name}: ${element.name}`
                  "
                ></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </draggable>
        </v-list-item-group>
      </v-list>
    </v-col>
    <v-col cols="4" lg="6">
      <h4 class="mb-4">Ausgewählte Berechtigungen</h4>
      <v-list dense elevation="2">
        <v-list-item-group>
          <draggable
            :list="sortedSelected"
            group="fields"
            class="draggable-wrapper"
          >
            <v-list-item v-for="element in sortedSelected" :key="element.id">
              <v-list-item-content>
                <v-list-item-title
                  v-text="
                    `${element.portal_field_category.name}: ${element.name}`
                  "
                ></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </draggable>
        </v-list-item-group>
      </v-list>
    </v-col>
  </v-row>
</template>

<script>
import draggable from "vuedraggable";

export default {
  components: {
    draggable,
  },
  created() {
    this.cloneItems = _.cloneDeep(this.items);
    this.filteredItems = this.cloneItems;
    this.itemsSelected.forEach((item) => {
      this.cloneItems.splice(
        this.cloneItems.findIndex((element) => element.id === item.id),
        1
      );
    });

    this.selectedItems = this.itemsSelected;
  },
  data: () => ({
    selectedItems: [],
    cloneItems: [],
    search: "",
    filteredItems: [],
  }),
  props: {
    items: Array,
    itemsSelected: Array,
  },
  computed: {
    sortedSelected: {
      get() {
        return _.sortBy(
          _.sortBy(
            this.selectedItems,
            (field) => field.name.toLowerCase()
          ),
          "portal_field_category.name"
        );
      }
    }
  },
  watch: {
    search: {
      handler(value) {
        this.filteredItems = this.cloneItems.filter(
          (item) =>
            item.portal_field_category.name
              .toLowerCase()
              .includes(value.toLowerCase()) ||
            item.name.toLowerCase().includes(value.toLowerCase())
        );
      },
    },
    sortedSelected: {
      handler() {
        this.$emit("change", this.sortedSelected);
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.v-list {
  height: 400px;
  overflow: scroll;

  .v-list-item-group {
    height: 100%;
    .draggable-wrapper {
      height: 100%;
    }
  }
}
</style>