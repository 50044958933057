export default class UploadAdapter {
  constructor (loader) {
    // The file loader instance to use during the upload.
    this.loader = loader
  }

  // Starts the upload process.
  upload () {
    return this.loader.file
      .then(file => new Promise((resolve, reject) => {
        const formData = new FormData()
        formData.append('file', file)

        axios.post('/admin/news/image/upload', formData).then(res => {
          return resolve(
            res.data.urls
          )
        }).catch((err) => {
          return reject(err && err.error ? err.error.message : `Couldn't upload file: ${file.name}.`)
        })
      }))
  }
}
