<template>
  <v-chip-group column>
    <draggable
      v-model="categoryFields"
      v-bind="dragOptions"
      @remove="removeElement"
      :move="setMoved"
    >
      <v-chip
        small
        class="draggable-chip"
        @dblclick="moveElement(element)"
        @click="moveElementClick(element)"
        v-for="element in categoryFields"
        :key="element.id"
        :color="element.color"
      >
        {{ element.field_name }}
      </v-chip>
    </draggable>
  </v-chip-group>
</template>

<script>
import draggable from "vuedraggable";

export default {
  data: () => ({
    movedId: "",
  }),
  components: {
    draggable,
  },
  methods: {
    removeElement() {
      this.$store.dispatch("fieldStore/removeAvailable", this.movedId);
    },
    moveElement(element) {
      if (
        this.$store.state.fieldStore.selectedFields.length ==
          process.env.MIX_MAX_FIELDS &&
        process.env.MIX_MAX_FIELDS != -1
      ) {
        this.$snackbar.showMessage({
          content: "maximale Anzahl Felder erreicht",
          color: "error",
        });

        return;
      }

      this.$store.dispatch("fieldStore/removeAvailable", element.id);
      this.$store.dispatch("fieldStore/addSelected", element);
      if (
        this.$store.state.fieldStore.selectedFields.length >
        process.env.MIX_WARNING_FIELDS
      ) {
        this.$snackbar.showMessage({
          content:
            "Hinweis: Beachten Sie bitte, dass eine größere Auswahl von Feldern zu längeren Ladezeiten führen kann.",
          color: "warning",
        });
      }
    },

    moveElementClick(element) {
      if (this.$vuetify.breakpoint.smAndDown) {
        this.moveElement(element);
      }
    },
    setMoved(evt) {
      this.movedId = evt.draggedContext.element.id;
    },
  },
  computed: {
    categoryFields: {
      get() {
        return _.sortBy(this.fields, "field_name");
      },

      set(value) {
        return;
      },
    },
    dragOptions() {
      return {
        animation: 200,
        group: {
          name: "availableFields",
          pull: ["selectedFields"],
          put: ["selectedFields"],
        },
        disabled: false,
        ghostClass: "ghost",
      };
    },
  },
  props: {
    fields: Array,
  },
};
</script>

<style lang="scss" scoped>
</style>