<template>
  <div>
    <v-data-iterator
      class="d-none"
      :search="search"
      :items="items"
      :items-per-page="-1"
      :sort-by="tableOptions.sortBy"
      :sort-desc="tableOptions.sortDesc"
      locale="de-DE"
      hide-default-footer
      :footer-props="{
        itemsPerPageText: 'Zeilen pro Seite: ',
        itemsPerPageOptions: [-1],
      }"
      @current-items="cloneItems = $event"
    >
    </v-data-iterator>
    <v-data-table
      v-model="selected"
      show-select
      item-key="uuid"
      :search="search"
      :headers="fields"
      :items="items"
      :item-class="setCursor"
      :items-per-page.sync="tableOptions.itemsPerPage"
      :sort-by.sync="tableOptions.sortBy"
      :sort-desc.sync="tableOptions.sortDesc"
      :page.sync="tableOptions.page"
      @page-count="pageCount = $event"
      dense
      locale="de-DE"
      hide-default-footer
      :footer-props="{
        itemsPerPageText: 'Zeilen pro Seite: ',
        itemsPerPageOptions: [10, 25, 50, -1],
      }"
      @click:row="openDetails"
      @current-items="$emit('current-items', $event)"
    >
      <template
        v-for="header in fields"
        v-slot:[`item.${header.value}`]="{ value }"
      >
        <template v-if="header.data_type == 'date'">
          {{ value | formatDate }}
        </template>
        <template v-else-if="header.data_type == 'number'">
          {{ value | formatNumber }}
        </template>
        <template v-else-if="header.data_type == 'coordinate'">
          {{ value | formatCoordinate }}
        </template>
        <template v-else>
          {{ value }}
        </template>
      </template>
      <template
        v-slot:footer="{
          props: { pagination, itemsPerPageText, itemsPerPageOptions },
        }"
      >
        <table-footer
          :pagination="pagination"
          :itemsPerPageText="itemsPerPageText"
          :itemsPerPageOptions="itemsPerPageOptions"
          :tableOptions="tableOptions"
          :pageCount="pageCount"
        ></table-footer>
      </template>
    </v-data-table>
    <v-dialog v-model="show" max-width="400">
      <v-card>
        <v-toolbar color="info" dark flat>
          <v-toolbar-title>Tabelle exportieren</v-toolbar-title>
        </v-toolbar>
        <v-card-text class="pt-4 px-4">
          <v-form v-model="exportValid" ref="exportSettings">
            <v-text-field
              v-model="fileName"
              :rules="validationRules"
              filled
              dense
              hide-details="auto"
              label="Dateiname"
              class="mb-4"
            ></v-text-field>
            <v-select
              v-model="exportFormat"
              :rules="validationRules"
              filled
              dense
              hide-details="auto"
              :items="dropdownItems"
              label="Als Excel oder CSV exportieren?"
            ></v-select>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn color="error" @click.stop="$emit('close')" x-small
            >Abbrechen</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn color="primary" type="btn" small @click="exportData"
            >Exportieren</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import TableFooter from "./TableFooter.vue";

export default {
  components: {
    "table-footer": TableFooter,
  },
  data: () => ({
    pageCount: 0,
    key: 0,
    cloneItems: [],
    selected: [],
    tableOptions: {
      page: 1,
      itemsPerPage: 10,
      sortBy: [],
      sortDesc: [],
    },
    exportFormat: "",
    fileName: "",
    exportValid: true,
    dropdownItems: [
      {
        text: "Excel (.xlsx)",
        value: "xlsx",
      },
      {
        text: "CSV (.csv)",
        value: "csv",
      },
    ],
    validationRules: [(v) => !!v || "Bitte Feld ausfüllen"],
  }),
  props: {
    search: String,
    show: Boolean,
    items: Array,
    fields: Array,
    module: String,
  },

  created() {
    this.loadLocalStorageTableOptions();
  },
  methods: {
    loadLocalStorageTableOptions() {
      if (localStorage.hasOwnProperty("tableOptions")) {
        const lsTableOptions = JSON.parse(localStorage.tableOptions);

        if (lsTableOptions.module === this.module)
          this.tableOptions = lsTableOptions;
      }
    },
    openDetails(row) {
      const index = _.findIndex(this.cloneItems, (item) => {
        return _.isEqual(item, row);
      });

      this.$store.dispatch("detailsPagination/setIndizes", index);
      window.location.href = this.$root.route(
        `${this.module.toLowerCase()}.details`,
        row.Id
      );
    },
    setCursor() {
      return "cursor-pointer table-item";
    },

    exportData() {
      this.$refs.exportSettings.validate();

      if (this.exportValid) {
        axios
          .post(
            this.$root.route("excel.export"),
            {
              items: this.selected.length > 0 ? this.selected : this.cloneItems,
              headings: this.fields,
              format: this.exportFormat,
            },
            {
              responseType: "blob",
            }
          )
          .then((res) => {
            const url = window.URL.createObjectURL(new Blob([res.data]));
            const link = document.createElement("a");
            link.href = url;
            const fileName = `${this.fileName}.${this.exportFormat}`;
            link.setAttribute("download", fileName);
            document.body.appendChild(link);
            link.click();
            link.remove();
            this.$emit("close");
          });
      }
    },
  },
  watch: {
    cloneItems: {
      handler() {
        this.key++;
        this.$store.dispatch(
          "detailsPagination/setItems",
          _.map(this.cloneItems, "Id")
        );
      },
    },
    selected: {
      handler() {
        this.$emit("current-items", this.selected);
        this.key++;
      },
    },
    tableOptions: {
      handler() {
        this.tableOptions.module = this.module;

        localStorage.tableOptions = JSON.stringify(this.tableOptions);
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.v-data-table::v-deep {
  .v-data-table-header th {
    white-space: nowrap;
  }
  .cursor-pointer {
    cursor: pointer;
  }

  .table-item {
    white-space: nowrap !important;
  }
}
</style>