import Vue from 'vue'
import Vuex from 'vuex'
import snackbar from './modules/snackbar'
import exportSnackbar from './modules/exportSnackbar'
import fieldStore from './modules/fieldStore'
import filter from './modules/filter'
import favorite from './modules/favorite'
import notifications from './modules/notifications'
import detailsPagination from './modules/detailsPagination'
import createPersistedState from 'vuex-persistedstate'
import SecureLS from 'secure-ls'

const ls = new SecureLS({ isCompression: true })

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    snackbar,
    fieldStore,
    filter,
    favorite,
    notifications,
    detailsPagination,
    exportSnackbar
  },
  plugins: [createPersistedState({
    paths: [
      'fieldStore',
      'filter',
      'favorite',
      'detailsPagination'
    ],
    storage: {
      getItem: (key) => ls.get(key),
      setItem: (key, value) => ls.set(key, value),
      removeItem: (key) => ls.remove(key)
    }
  })]
})
