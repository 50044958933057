<template>
  <v-form v-model="valid" ref="fieldForm" @submit.prevent="savePermission()">
    <h2 class="primary--text :hover">Berechtigungs-Daten</h2>
    <div class="profile-grid">
      <div class="profile-item my-1">
        <div class="profile-title pa-2">Berechtigungs-Option</div>
        <div class="profile-divider"></div>
        <div class="profile-value py-2">
          <v-select
            filled
            dense
            hide-details="auto"
            :rules="inputRules"
            :items="permissionOptions"
            v-model="newPermission.option"
          ></v-select>
        </div>
      </div>
      <div class="profile-item my-1">
        <div class="profile-title pa-2">Berechtigungs-Typ</div>
        <div class="profile-divider"></div>
        <div class="profile-value py-2">
          <v-select
            filled
            dense
            hide-details="auto"
            :rules="inputRules"
            :items="permissionTypes"
            v-model="newPermission.type"
          ></v-select>
        </div>
      </div>
      <div class="profile-item my-1" v-if="newPermission.type === 'module'">
        <div class="profile-title pa-2">Modul</div>
        <div class="profile-divider"></div>
        <div class="profile-value py-2">
          <v-autocomplete
            filled
            dense
            hide-details="auto"
            :rules="inputRules"
            :items="modules"
            item-value="field_name"
            item-text="field_name"
            v-model="newPermission.field"
          ></v-autocomplete>
        </div>
      </div>
      <template v-if="newPermission.type === 'field'">
        <div class="profile-item my-1">
          <div class="profile-title pa-2">Modul</div>
          <div class="profile-divider"></div>
          <div class="profile-value py-2">
            <v-autocomplete
              filled
              dense
              hide-details="auto"
              :items="modules"
              item-value="id"
              item-text="field_name"
              v-model="newPermission.module"
            ></v-autocomplete>
          </div>
        </div>
        <div class="profile-item my-1">
          <div class="profile-title pa-2">Feld</div>
          <div class="profile-divider"></div>
          <div class="profile-value py-2">
            <v-autocomplete
              filled
              dense
              hide-details="auto"
              :rules="inputRules"
              :items="computedFields"
              item-value="field_name"
              item-text="field_name"
              v-model="newPermission.field"
            ></v-autocomplete>
          </div>
        </div>
      </template>
      <div class="profile-item my-1">
        <div class="profile-title pa-2">Berechtigung zu Rollen zuweisen</div>
        <div class="profile-divider"></div>
        <div class="profile-value py-2">
          <v-autocomplete
            filled
            dense
            multiple
            :items="roles"
            hide-details="auto"
            v-model="selectedRoles"
            item-value="id"
            item-text="name"
          ></v-autocomplete>
        </div>
      </div>
    </div>
    <v-row class="mx-0 mt-4">
      <v-btn color="primary" type="submit">Feld speichern</v-btn>
    </v-row>
  </v-form>
</template>

<script>
export default {
  data: () => ({
    newPermission: {
      option: "view",
    },
    valid: true,
    inputRules: [(v) => !!v || "Feld wird benötigt"],
    roles: [],
    fields: [],
    selectedRoles: [],
    modules: [],
    permissionOptions: [
      {
        text: "Sichtbar",
        value: "view",
      },
    ],
    permissionTypes: [
      {
        text: "Feld",
        value: "field",
      },
      {
        text: "Modul",
        value: "module",
      },
    ],
  }),
  props: ["permission"],
  computed: {
    computedFields: {
      get() {
        return _.sortBy(
          this.fields.filter(
            (field) =>
              field.portal_field_category_id === this.newPermission.module
          ),
          (field) => field.field_name.toLowerCase()
        );
      },
    },
  },
  created() {
    axios
      .get(this.$root.route("getRoles"))
      .then((res) => {
        this.roles = _.sortBy(res.data, (role) => role.name.toLowerCase());
      })
      .catch(() => {
        this.$snackbar.showMessage({
          content: "Fehler beim Laden",
          color: "error",
        });
      });

    axios
      .get(this.$root.route("getCategories"))
      .then((res) => {
        this.modules = _.sortBy(res.data, "name");
      })
      .catch(() => {
        this.$snackbar.showMessage({
          content: "Fehler beim Laden",
          color: "error",
        });
      });

    this.fields = [...this.$store.getters["fieldStore/getAllFields"]];

    if (this.permission) {
      const strArray = this.permission.name.split(/\s(.+)/);
      this.newPermission.option = strArray[0];
      this.newPermission.field = strArray[1].match(/(?::)[\s\S]*/)
        ? strArray[1].match(/(?::)[\s\S]*/)[0].replace(":", "")
        : strArray[1];
      this.newPermission.type = this.permission.type;
      this.newPermission.module = this.permission.portal_field_category_id;

      this.selectedRoles = this.permission.roles.map((role) => role.id);
    }
  },

  methods: {
    savePermission() {
      this.$refs.fieldForm.validate();

      if (this.valid) {
        axios
          .post(
            this.permission
              ? this.$root.route("admin.permissions.update", this.permission.id)
              : this.$root.route("admin.permissions.insert"),
            {
              roles: this.selectedRoles,
              permission: this.newPermission,
            }
          )
          .then((res) => {
            this.$snackbar.showMessage({
              content: "Berechtigung gespeichert",
              color: "primary",
            });
            window.location.href = this.$root.route("admin.permissions.index");
          })
          .catch((error) => {
            if (error.response.data) {
              this.$snackbar.showMessage({
                content: error.response.data,
                color: "error",
              });
            } else {
              this.$snackbar.showMessage({
                content: "Fehler beim Speichern!",
                color: "error",
              });
            }
          });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.profile-grid {
  display: grid;
  grid-template-columns: 1fr;
  column-gap: 30px;

  .profile-item {
    display: flex;
    column-gap: 15px;
    align-items: center;

    .profile-title {
      flex-basis: 150px;
      text-align: start;
      color: #a6adaf;
    }

    .profile-value {
      color: #919699;
      font-weight: 700;
      flex-basis: 500px;
    }

    .profile-divider {
      border: 1px solid #f7f9fa;
      height: 100%;
    }
  }
}
</style>