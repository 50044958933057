const state = () => ({
  prevIndex: '',
  nextIndex: '',
  currentIndex: '',
  items: []
})

const mutations = {
  setItems (state, payload) {
    state.items = payload
  },

  setIndizes (state, payload) {

    state.currentIndex = payload

    state.prevIndex = payload - 1 >= 0 ? payload - 1 : false
    state.nextIndex = payload + 1 < state.items.length ? payload + 1 : false
  },

  movePrev (state) {
    const index = state.currentIndex - 1

    state.currentIndex = index

    state.prevIndex = index - 1 >= 0 ? index - 1 : false
    state.nextIndex = index + 1 < state.items.length ? index + 1 : false
  },

  moveNext (state) {
    const index = state.currentIndex + 1

    state.currentIndex = index

    state.prevIndex = index - 1 >= 0 ? index - 1 : false
    state.nextIndex = index + 1 < state.items.length ? index + 1 : false
  }
}

const actions = {
  setItems ({ commit }, items) {
    commit('setItems', items)
  },

  setIndizes ({ commit }, row) {
    commit('setIndizes', row)
  },

  moveToPrev ({ commit }) {
    commit('movePrev')
  },

  moveToNext ({ commit }) {
    commit('moveNext')
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
