<template>
  <v-autocomplete
    v-model="selectedRoles"
    dense
    filled
    clearable
    multiple
    hide-details="auto"
    label="Rollen auswählen:"
    name="roles"
    :items="sortedRoles"
    item-value="name"
    item-text="name"
  ></v-autocomplete>
</template>

<script>
import _ from 'lodash';

export default {
  data: () => ({
    selectedRoles: [],
    sortedRoles: []
  }),
  created() {
    this.selectedRoles = this.userRoles;
    this.sortedRoles = _.sortBy(this.roles, role => role.name.toLowerCase())
  },
  props: {
    roles: Array,
    userRoles: Array,
  },
};
</script>

<style lang="scss" scoped>
</style>