<template>
  <v-snackbar
    class="pt-0"
    top
    v-model="show"
    :color="color"
    :timeout="color === 'error' ? -1 : 10000"
  >
    <v-row class="ma-0" align="center">
      <div v-html="message"></div>
      <v-btn
        @click="show = false"
        color="secondary"
        small
        class="mt-1"
        v-if="this.message.length > 400"
      >
        Fenster schließen
      </v-btn>
    </v-row>
    <template v-slot:action>
      <v-btn @click="show = false" icon class="mt-1">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
export default {
  data() {
    return {
      show: false,
      message: "",
      color: "",
    };
  },

  created() {
    this.$store.subscribe((mutation, state) => {
      if (mutation.type === "snackbar/showMessage") {
        this.message = state.snackbar.content;
        this.color = state.snackbar.color;
        this.show = true;
      }
    });
  },
};
</script>

<style lang="scss">
.v-snack__action {
  align-items: flex-start;
  align-self: flex-start;
}
</style>