<template>
  <div id="map"></div>
</template>

<script>
import { Loader } from "@googlemaps/js-api-loader";
export default {
  data: () => ({
    loader: new Loader({
      apiKey: "AIzaSyAIucv2axoHxJPcrqCkIzpLUh68hPQNZ2g",
      version: "weekly",
    }),
  }),
  props: {
    items: Array,
    module: String,
  },

  created() {
    this.loader.load().then(() => {
      const map = new google.maps.Map(document.getElementById("map"), {
        center: { lat: 51.163375, lng: 10.447683 },
        zoom: 6,
      });

      this.items.forEach((item) => {
        if (item.Lat && item.Long) {
          const window = new google.maps.InfoWindow(
            this.getContent(item, this.module)
          );
          const marker = new google.maps.Marker({
            position: {
              lat: parseFloat(item.Lat),
              lng: parseFloat(item.Long),
            },
            map: map,
          });

          marker.addListener("click", () => {
            window.open(map, marker);
          });
        }

        if ("wea" in item) {
          const window = new google.maps.InfoWindow(
            this.getContent(item.wea, "Windenergieanlagen")
          );
          const marker = new google.maps.Marker({
            position: {
              lat: parseFloat(item.wea.Lat),
              lng: parseFloat(item.wea.Long),
            },
            map: map,
          });

          marker.addListener("click", () => {
            window.open(map, marker);
          });
        }

        if ("pva" in item) {
          const window = new google.maps.InfoWindow(
            this.getContent(item.pva, "Photovoltaikanlagen")
          );
          const marker = new google.maps.Marker({
            position: {
              lat: parseFloat(item.pva.Lat),
              lng: parseFloat(item.pva.Long),
            },
            map: map,
          });

          marker.addListener("click", () => {
            window.open(map, marker);
          });
        }

        if ("bsa" in item) {
          const window = new google.maps.InfoWindow(
            this.getContent(item.bsa, "Brennstoffanlagen")
          );
          const marker = new google.maps.Marker({
            position: {
              lat: parseFloat(item.bsa.Lat),
              lng: parseFloat(item.bsa.Long),
            },
            map: map,
          });

          marker.addListener("click", () => {
            window.open(map, marker);
          });
        }
      });
    });
  },
  methods: {
    getContent(item, module) {
      switch (module) {
        case "Windparks":
          return {
            content: `<h4 class="mb-2">Name Windpark: ${
              item.Name_Windpark
            }</h4><h4 class="mb-2">Betreiber: ${
              item.Betreiber_Windpark
            }</h4><h4 class="mb-2">Leistung Windpark: ${
              item.Nennleistung_Windpark
            } MW</h4><h4 class="mb-2">Anzahl Anlagen: ${
              item.Anzahl_Anlagen_im_Windpark
            }</h4><a style="float: right; color: #82B1FF" href="${this.$root.route(
              "windparks.details",
              item.Id
            )}">Zum Windpark</a>`,
          };
        case "Photovoltaikparks":
          return {
            content: `<h4 class="mb-2">Name PV-Park: ${
              item.Name_PV_Park
            }</h4><h4 class="mb-2">Betreiber: ${
              item.Anlagenbetreiber
            }</h4><h4 class="mb-2">Anzahl Solarmodule: ${
              item.Anzahl_SolarModule
            }</h4><h4 class="mb-2">Nennleistung: ${
              item.Nennleistung_PVP_Park
            } kW</h4><a style="float: right; color: #82B1FF" href="${this.$root.route(
              "photovoltaikparks.details",
              item.Id
            )}">Zum Photovoltaikpark</a>`,
          };
        case "Brennstoffe":
          return {
            content: `<h4 class="mb-2">Name EZ: ${
              item.Name_Anlage
            }</h4><h4 class="mb-2">Betreiber: ${
              item.Anlagenbetreiber
            }</h4><h4 class="mb-2">Anzahl der Anlagen: ${
              item.AnzahlderAnlagen
            }</h4><h4 class="mb-2">Energieträger: ${
              item.Energieträger
            }</h4><h4 class="mb-2">Bruttoleistung: ${
              item.Bruttoleistung_in_kW
            } kW</h4><h4 class="mb-2">Elektrische KWK-Leistung: ${
              item.ElektrischeKWKLeistung
            } kW</h4><a style="float: right; color: #82B1FF" href="${this.$root.route(
              "brennstoffe.details",
              item.Id
            )}">Zur Energiezentrale</a>`,
          };

        case "Windenergieanlagen":
          return {
            content: `<h4 class="mb-2">Name: ${
              item.Wind_Name
            }</h4><h4 class="mb-2">Betreiber: ${
              item.BetreibergesellschaftWindpark
            }</h4><h4 class="mb-2">Leistung: ${
              item.Nettonennleistung_in_kW
            } kW</h4><h4 class="mb-2">Inbetriebnahmedatum: ${this.$filters.formatDate(
              item.Inbetriebnahmedatum
            )}</h4><h4 class="mb-2">Hersteller: ${
              item.Hersteller
            }</h4><a style="float: right; color: #82B1FF" href="${this.$root.route(
              "windenergieanlagen.details",
              item.Id
            )}">Zur Windenergieanlage</a>`,
          };
        case "Photovoltaikanlagen":
          return {
            content: `<h4 class="mb-2">Name: ${
              item.Photovoltaikname
            }</h4><h4 class="mb-2">Betreiber: ${
              item.Anlagenbetreiber
            }</h4><h4 class="mb-2">Anzahl Solarmodule: ${
              item.Anzahl_SolarModule
            }</h4><h4 class="mb-2">Bruttoleistung: ${
              item.Bruttoleistung_in_kW
            } kW</h4><a style="float: right; color: #82B1FF" href="${this.$root.route(
              "photovoltaikanlagen.details",
              item.Id
            )}">Zur Photovoltaikanlage</a>`,
          };
        case "Brennstoffanlagen":
          return {
            content: `<h4 class="mb-2">Name: ${
              item.ThermischeAnlage_Name
            }</h4><h4 class="mb-2">Betreiber: ${
              item.Anlagenbetreiber
            }</h4><h4 class="mb-2">Hauptbrennstoff:: ${
              item.Hauptbrennstoff_der_Einheit
            }</h4><h4 class="mb-2">Bruttoleistung: ${
              item.Bruttoleistung_in_kW
            } kW</h4><a style="float: right; color: #82B1FF" href="${this.$root.route(
              "brennstoffanlagen.details",
              item.Id
            )}">Zur Thermischen Anlage</a>`,
          };
        case "Organisationen":
          return {
            content: `<h4 class="mb-2">Firmenname: ${
              item["Firmenname Orga"]
            }</h4><h4 class="mb-2">Straße: ${
              item.Street
            }</h4><h4 class="mb-2">PLZ Ort: ${item.ZipCode} ${
              item.City
            }</h4><h4 class="mb-2">Telefon: ${
              item.CommPhoneCentral
            }</h4><a style="float: right; color: #82B1FF" href="${this.$root.route(
              "organisationen.details",
              item.Id
            )}">Zur Organisation</a>`,
          };
      }
    },
  },
};
</script>

<style lang="scss" scoped>
#map {
  height: 700px;
}
</style>
