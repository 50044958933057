<template>
  <v-form ref="newsForm" @submit.prevent="saveNewsArticle" v-model="valid">
    <v-row>
      <v-col>
        <v-text-field
          v-model="title"
          filled
          dense
          label="Titel"
          :rules="inputRules"
          hide-details="auto"
        ></v-text-field
      ></v-col>
      <v-col>
        <v-text-field
          v-model="seoSlug"
          filled
          dense
          label="News-URL"
          hide-details="auto"
          :loading="slugLoading"
          :error-messages="slugError"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-text-field
          v-model="excerpt"
          filled
          dense
          label="Einleitungs-Text"
          :rules="inputRules"
          hide-details="auto"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-file-input
          v-model="featuredImage"
          accept="image/png, image/jpeg, image/webp"
          :rules="fileRules"
          show-size
          dense
          filled
          label="Bild"
          hide-details="auto"
          prepend-inner-icon="mdi-camera"
          prepend-icon=""
        ></v-file-input>
      </v-col>
      <v-col>
        <v-menu
          ref="menu"
          v-model="menu"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              :rules="inputRules"
              v-model="publishDateTime"
              label="Veröffentlichungsdatum"
              prepend-inner-icon="mdi-calendar"
              readonly
              filled
              dense
              hide-details="auto"
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-tabs
            v-model="tab"
            background-color="primary"
            grow
            centered
            dark
            icons-and-text
          >
            <v-tabs-slider></v-tabs-slider>

            <v-tab href="#date-tab">
              Datum
              <v-icon>mdi-calendar</v-icon>
            </v-tab>

            <v-tab href="#clock-tab" target="">
              Uhrzeit
              <v-icon>mdi-clock</v-icon>
            </v-tab>
          </v-tabs>

          <v-tabs-items v-model="tab">
            <v-tab-item value="date-tab">
              <v-card flat>
                <v-card-text>
                  <v-date-picker
                    v-model="publishDate"
                    color="secondary"
                    first-day-of-week="1"
                    landscape
                    locale="de-DE"
                    @input="tab = 'clock-tab'"
                  >
                  </v-date-picker>
                </v-card-text>
              </v-card>
            </v-tab-item>
            <v-tab-item value="clock-tab">
              <v-card flat>
                <v-card-text>
                  <v-time-picker
                    color="secondary"
                    v-model="publishTime"
                    format="24hr"
                    landscape
                    @click:minute="menu = false"
                  >
                  </v-time-picker>
                </v-card-text>
              </v-card>
            </v-tab-item>
          </v-tabs-items>
        </v-menu>
      </v-col>
    </v-row>
    <v-row>
      <v-col><h2 class="primary--text">Nachricht</h2></v-col>
    </v-row>
    <v-row>
      <v-col>
        <ckeditor
          :editor="editor"
          v-model="editorData"
          :config="editorConfig"
        ></ckeditor
      ></v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-autocomplete
          v-model="selectedRoles"
          dense
          filled
          clearable
          multiple
          hide-details="auto"
          label="Rollen auswählen:"
          name="roles"
          :items="roles"
          item-value="name"
          item-text="name"
        ></v-autocomplete>
      </v-col>
      <v-col>
        <v-autocomplete
          v-model="selectedUsers"
          dense
          filled
          clearable
          multiple
          hide-details="auto"
          label="Benutzer auswählen:"
          name="users"
          :items="filteredUsers"
          item-value="id"
          item-text="name"
        ></v-autocomplete>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" lg="6">
        <v-file-input
          v-model="additionalFiles"
          :rules="additionalFileRules"
          multiple
          show-size
          dense
          filled
          label="Anhänge"
          hide-details="auto"
          prepend-inner-icon="mdi-file"
          prepend-icon=""
        ></v-file-input>
      </v-col>
    </v-row>
    <v-row>
      <v-spacer></v-spacer>
      <v-btn class="mt-4 mr-3 mb-4" color="primary" type="submit"
        >Nachricht speichern & veröffentlichen</v-btn
      >
    </v-row>
  </v-form>
</template>

<script>
import CKEditor from "@ckeditor/ckeditor5-vue2";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import "@ckeditor/ckeditor5-build-classic/build/translations/de";
import UploadAdapter from "../UploadAdapter";
import slugify from "@sindresorhus/slugify";
import moment from "moment/dist/moment";
import _ from "lodash";

function UploadAdapterPlugin(editor) {
  editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
    return new UploadAdapter(loader);
  };
}

export default {
  components: {
    ckeditor: CKEditor.component,
  },

  props: ["news", "savedRoles", "savedUsers"],

  created() {
    if (this.news) {
      this.title = this.news.title;
      this.slug = this.news.slug;
      this.excerpt = this.news.excerpt;
      this.editorData = this.news.content;
      this.publishDateTime = this.news.publishDate;

      const url = new URL(this.news.featuredImage + ".webp");
      const filename = url.pathname.split("/").pop();

      fetch(url)
        .then((result) => {
          return result.blob();
        })
        .then((blob) => {
          this.featuredImage = new File([blob], filename);
        })

        .catch(() => {
          this.$snackbar.showMessage({
            content: "Fehler beim Laden",
            color: "error",
          });
        });

      if (this.news.files && this.news.files.length > 0) {
        let fileArray = [];
        this.news.files.forEach((file) => {
          const url = new URL(file.path);
          const filename = file.name;

          fetch(url)
            .then((result) => {
              return result.blob();
            })
            .then((blob) => {
              fileArray.push(new File([blob], filename));
            });
        });
        this.additionalFiles = fileArray;
      }
    }

    if(this.savedRoles) {
      this.selectedRoles = _.map(this.savedRoles, 'name')
    }
    
    if(this.savedUsers) {
      this.selectedUsers = _.map(this.savedUsers, 'id')
    }

    axios
      .get(this.$root.route("getRoles"))
      .then((res) => {
        this.roles = _.sortBy(res.data, role => role.name.toLowerCase());
      })
      .catch(() => {
        this.$snackbar.showMessage({
          content: "Fehler beim Laden",
          color: "error",
        });
      });

    axios
      .get(this.$root.route("admin.getUsers"))
      .then((res) => {
        this.users = _.sortBy(res.data, user => user.name.toLowerCase());
      })
      .catch(() => {
        this.$snackbar.showMessage({
          content: "Fehler beim Laden",
          color: "error",
        });
      });
  },

  data: () => ({
    inputRules: [(v) => !!v || "Feld wird benötigt"],
    fileRules: [
      (v) => !!v || "Feld wird benötigt",
      (v) => !v || v.size < 10000000 || "Bild sollte kleiner als 10MB sein",
    ],
    additionalFileRules: [
      (v) =>
        !v ||
        !v.some((file) => file > 10000000) ||
        "Anhang sollte kleiner als 10MB sein",
    ],
    tab: "date-tab",
    menu: false,
    valid: true,
    editor: ClassicEditor,
    editorData: "",
    roles: [],
    users: [],
    featuredImage: null,
    additionalFiles: null,
    editorConfig: {
      language: "de",
      extraPlugins: [UploadAdapterPlugin],
      mediaEmbed: {
        previewsInData: true
      }
    },
    excerpt: "",
    title: "",
    publishDate: "",
    publishTime: "",
    selectedRoles: [],
    selectedUsers: [],
    slug: "",
    slugLoading: false,
    slugError: ""
  }),
  methods: {
    saveNewsArticle() {
      this.$refs.newsForm.validate();
      if (this.valid) {
        const formData = new FormData();
        if (this.news) {
          formData.append("id", this.news.id);
        }
        formData.append("title", this.title);
        formData.append("slug", this.seoSlug);
        formData.append("excerpt", this.excerpt);
        formData.append("publishDateTime", this.publishDateTime);
        formData.append("content", this.editorData);
        formData.append("featuredImage", this.featuredImage);
        if (this.additionalFiles && this.additionalFiles.length > 0) {
          this.additionalFiles.forEach((file) => {
            formData.append("additionalFiles[]", file);
          });
        }
        formData.append("roles", this.selectedRoles);
        formData.append("users", this.selectedUsers);

        axios
          .post("/admin/news/saveNews", formData)
          .then((res) => {
            this.$snackbar.showMessage({
              content: "Artikel gespeichert",
              color: "primary",
            });
            window.location.href = this.$root.route("admin.news.index");
          })
          .catch(() => {
            this.$snackbar.showMessage({
              content: "Fehler beim Speichern",
              color: "error",
            });
          });
      }
    },

    debouncedCheckSlug: _.debounce(function () {
      this.slugLoading = true;
      axios
        .get(this.$root.route("admin.news.checkSlug"), {
          params: {
            id: this.news?.id || false,
            slug: this.seoSlug,
          },
        })
        .then((res) => {
          this.slugLoading = false;
          if(res.data.result) this.slugError = 'News-URL schon belegt!'
          else this.slugError = ""
        })
        .catch(() => {
          this.slugLoading = false;
        });
    }, 500),
  },
  watch: {
    seoSlug: {
      handler() {
        this.debouncedCheckSlug()
      }
    }
  },

  computed: {
    seoSlug: {
      get() {
        if (this.slug) return slugify(this.slug);
        return slugify(this.title);
      },
      set(value) {
        this.slug = value;
      },
    },

    filteredUsers() {
      if (this.selectedRoles.length > 0) {
        return this.users.filter((user) => {
          return (
            _.intersection(
              this.selectedRoles,
              user.roles.map((elem) => elem.name)
            ).length == 0
          );
        });
      }
      return this.users;
    },

    publishDateTime: {
      get() {
        if (this.publishDate === "") return;
        return this.$filters.formatDateTime(
          `${this.publishDate} ${this.publishTime}`
        );
      },
      set(value) {
        this.publishDate = moment(value).format("YYYY-MM-DD");
        this.publishTime = moment(value).format("HH:mm:ss");
      },
    },
  },
};
</script>

<style lang="scss" scoped>
div::v-deep .ck-editor__editable_inline {
  min-height: 400px;
}
</style>