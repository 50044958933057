<template>
  <v-form
    v-model="valid"
    ref="roleForm"
    :action="route('admin.roles.save')"
    @submit.prevent="saveRole()"
  >
    <h2 class="primary--text :hover">Rollendaten</h2>
    <div class="profile-grid">
      <div class="profile-item my-1">
        <div class="profile-title pa-2">Rollenname</div>
        <div class="profile-divider"></div>
        <div class="profile-value py-2">
          <v-text-field
            filled
            dense
            hide-details="auto"
            :rules="inputRules"
            v-model="role.name"
          ></v-text-field>
        </div>
      </div>
    </div>
    <v-row class="mb-8">
      <v-col cols="12" md="6">
        <h3 class="primary--text mb-4">alle Bedingungen treffen zu:</h3>
        <v-row
          justify="center"
          align="center"
          class="py-4"
          v-if="conditionAll.length == 0"
        >
          <v-btn
            x-small
            color="primary"
            min-width="20px"
            max-width="20px"
            class="mr-2"
            @click="addCondition(0, 'all')"
          >
            <v-icon>mdi-plus</v-icon>
          </v-btn>
          <v-btn
            x-small
            color="error"
            min-width="20px"
            max-width="20px"
            @click="removeCondition(0, 'all')"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn></v-row
        >
        <v-row v-for="(condition, index) in conditionAll" :key="index">
          <v-col>
            <v-autocomplete
              dense
              filled
              clearable
              hide-details="auto"
              :rules="inputRules"
              :items="availableFields"
              item-value="id"
              :item-text="
                (item) =>
                  `${item.portal_field_category.name}: ${item.field_name}`
              "
              v-model="condition.field"
              required
              @change="setDataType(condition)"
            ></v-autocomplete>
          </v-col>
          <v-col cols="2" v-if="condition.data_type !== 'text'">
            <v-select
              class="condition-select mt-0"
              hide-details="auto"
              dense
              height="100%"
              full-width
              value="e"
              v-model="condition.operator"
              :menu-props="{
                'content-class': 'condition-select-menu',
                bottom: true,
                'offset-y': true,
              }"
              hide-selected
              :items="operators.number"
            >
              <template v-slot:selection="{ item }">
                <v-icon color="white" v-if="item.hasOwnProperty('icon')">{{
                  item.icon
                }}</v-icon>
                <span v-else class="white--text body-2 text-center">{{
                  item.text
                }}</span>
              </template>
              <template v-slot:item="{ item }">
                <v-icon v-if="item.hasOwnProperty('icon')">{{
                  item.icon
                }}</v-icon>
                <span class="body-2" v-else>{{ item.text }}</span>
              </template>
            </v-select>
          </v-col>
          <v-col cols="2" v-else>
            <v-select
              class="condition-select mt-0"
              hide-details="auto"
              dense
              height="100%"
              full-width
              value="e"
              v-model="condition.operator"
              :menu-props="{
                'content-class': 'condition-select-menu',
                bottom: true,
                'offset-y': true,
              }"
              hide-selected
              :items="operators.text"
            >
              <template v-slot:selection="{ item }">
                <span class="white--text body-2 text-center">{{
                  item.text
                }}</span>
              </template>
              <template v-slot:item="{ item }">
                <span class="body-2">{{ item.text }}</span>
              </template>
              <template v-slot:append-outer>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      class="mt-n1"
                      small
                      v-bind="attrs"
                      v-on="on"
                      color="info"
                      icon
                    >
                      <v-icon> mdi-help-circle-outline </v-icon>
                    </v-btn>
                  </template>
                  <span>% als Platzhalter verwenden</span>
                </v-tooltip>
              </template>
            </v-select>
          </v-col>
          <template>
            <v-col v-if="condition.data_type == 'date'">
              <v-menu
                ref="menu"
                v-model="condition.menu"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    prepend-inner-icon="mdi-calendar"
                    readonly
                    filled
                    dense
                    hide-details="auto"
                    :disabled="
                      condition.operator === 'null' ||
                      condition.operator === 'not_null'
                    "
                    :value="$filters.formatDate(condition.value)"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <template v-slot:append-outer>
                      <v-btn
                        x-small
                        color="primary"
                        min-width="20px"
                        max-width="20px"
                        class="mr-2"
                        @click="addCondition(index, 'all')"
                      >
                        <v-icon>mdi-plus</v-icon>
                      </v-btn>
                      <v-btn
                        x-small
                        color="error"
                        min-width="20px"
                        max-width="20px"
                        @click="removeCondition(index, 'all')"
                      >
                        <v-icon>mdi-close</v-icon>
                      </v-btn>
                    </template>
                  </v-text-field>
                </template>
                <v-card flat>
                  <v-card-text>
                    <v-date-picker
                      v-model="condition.value"
                      color="secondary"
                      first-day-of-week="1"
                      landscape
                      locale="de-DE"
                    >
                    </v-date-picker>
                  </v-card-text>
                </v-card>
              </v-menu>
            </v-col>
            <v-col v-else>
              <v-text-field
                dense
                filled
                hide-details="auto"
                :disabled="
                  condition.operator === 'null' ||
                  condition.operator === 'not_null'
                "
                v-model="condition.value"
                required
              >
                <template v-slot:append-outer>
                  <v-btn
                    x-small
                    color="primary"
                    min-width="20px"
                    max-width="20px"
                    class="mr-2"
                    @click="addCondition(index, 'all')"
                  >
                    <v-icon>mdi-plus</v-icon>
                  </v-btn>
                  <v-btn
                    x-small
                    color="error"
                    min-width="20px"
                    max-width="20px"
                    @click="removeCondition(index, 'all')"
                  >
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </template>
              </v-text-field>
            </v-col>
          </template>
        </v-row>
      </v-col>
      <v-col cols="12" md="6">
        <h3 class="primary--text mb-4">mindestens eine Bedingung trifft zu:</h3>
        <v-row
          justify="center"
          align="center"
          class="py-4"
          v-if="conditionOne.length == 0"
        >
          <v-btn
            x-small
            color="primary"
            min-width="20px"
            max-width="20px"
            class="mr-2"
            @click="addCondition(0, 'one')"
          >
            <v-icon>mdi-plus</v-icon>
          </v-btn>
          <v-btn
            x-small
            color="error"
            min-width="20px"
            max-width="20px"
            @click="removeCondition(0, 'one')"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn></v-row
        >
        <v-row v-for="(condition, index) in conditionOne" :key="index">
          <v-col>
            <v-autocomplete
              dense
              filled
              clearable
              hide-details="auto"
              :rules="inputRules"
              :items="availableFields"
              item-value="id"
              :item-text="
                (item) =>
                  `${item.portal_field_category.name}: ${item.field_name}`
              "
              v-model="condition.field"
              required
              @change="setDataType(condition)"
            ></v-autocomplete>
          </v-col>
          <v-col cols="2" v-if="condition.data_type !== 'text'">
            <v-select
              class="condition-select mt-0"
              hide-details="auto"
              dense
              height="100%"
              full-width
              value="e"
              v-model="condition.operator"
              :menu-props="{
                'content-class': 'condition-select-menu',
                bottom: true,
                'offset-y': true,
              }"
              hide-selected
              :items="operators.number"
            >
              <template v-slot:selection="{ item }">
                <v-icon color="white" v-if="item.hasOwnProperty('icon')">{{
                  item.icon
                }}</v-icon>
                <span v-else class="white--text body-2 text-center">{{
                  item.text
                }}</span>
              </template>
              <template v-slot:item="{ item }">
                <v-icon v-if="item.hasOwnProperty('icon')">{{
                  item.icon
                }}</v-icon>
                <span class="body-2" v-else>{{ item.text }}</span>
              </template>
            </v-select>
          </v-col>
          <v-col cols="2" v-else>
            <v-select
              class="condition-select mt-0"
              hide-details="auto"
              dense
              height="100%"
              full-width
              value="e"
              v-model="condition.operator"
              :menu-props="{
                'content-class': 'condition-select-menu',
                bottom: true,
                'offset-y': true,
              }"
              hide-selected
              :items="operators.text"
            >
              <template v-slot:selection="{ item }">
                <span class="white--text body-2 text-center">{{
                  item.text
                }}</span>
              </template>
              <template v-slot:item="{ item }">
                <span class="body-2">{{ item.text }}</span>
              </template>
              <template v-slot:append-outer>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      class="mt-n1"
                      small
                      v-bind="attrs"
                      v-on="on"
                      color="info"
                      icon
                    >
                      <v-icon> mdi-help-circle-outline </v-icon>
                    </v-btn>
                  </template>
                  <span>% als Platzhalter verwenden</span>
                </v-tooltip>
              </template>
            </v-select>
          </v-col>
          <template>
            <v-col v-if="condition.data_type == 'date'">
              <v-menu
                ref="menu"
                v-model="condition.menu"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    prepend-inner-icon="mdi-calendar"
                    readonly
                    filled
                    dense
                    :disabled="
                      condition.operator === 'null' ||
                      condition.operator === 'not_null'
                    "
                    hide-details="auto"
                    :value="$filters.formatDate(condition.value)"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <template v-slot:append-outer>
                      <v-btn
                        x-small
                        color="primary"
                        min-width="20px"
                        max-width="20px"
                        class="mr-2"
                        @click="addCondition(index, 'one')"
                      >
                        <v-icon>mdi-plus</v-icon>
                      </v-btn>
                      <v-btn
                        x-small
                        color="error"
                        min-width="20px"
                        max-width="20px"
                        @click="removeCondition(index, 'one')"
                      >
                        <v-icon>mdi-close</v-icon>
                      </v-btn>
                    </template>
                  </v-text-field>
                </template>
                <v-card flat>
                  <v-card-text>
                    <v-date-picker
                      v-model="condition.value"
                      color="secondary"
                      first-day-of-week="1"
                      landscape
                      locale="de-DE"
                    >
                    </v-date-picker>
                  </v-card-text>
                </v-card>
              </v-menu>
            </v-col>
            <v-col v-else
              ><v-text-field
                dense
                filled
                hide-details="auto"
                :disabled="
                  condition.operator === 'null' ||
                  condition.operator === 'not_null'
                "
                v-model="condition.value"
                required
              >
                <template v-slot:append-outer>
                  <v-btn
                    x-small
                    color="primary"
                    min-width="20px"
                    max-width="20px"
                    class="mr-2"
                    @click="addCondition(index, 'one')"
                  >
                    <v-icon>mdi-plus</v-icon>
                  </v-btn>
                  <v-btn
                    x-small
                    color="error"
                    min-width="20px"
                    max-width="20px"
                    @click="removeCondition(index, 'one')"
                  >
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </template>
              </v-text-field></v-col
            >
          </template>
        </v-row>
      </v-col>
    </v-row>
    <h2 class="primary--text mb-6">Berechtigungen</h2>
    <v-row>
      <v-col cols="12" lg="6">
        <v-switch
          v-model="canViewMap"
          :label="`Kann Karte von Tabelle erstellen? ${
            canViewMap ? 'Ja' : 'Nein'
          }`"
        ></v-switch>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" lg="6"
        ><h3 class="primary--text mb-5">Feld-Berechtigungen</h3>
        <roles-field-draggable
          v-if="fieldPermissions.length > 0"
          :items="fieldPermissions"
          :items-selected="selectedFieldPermissions"
          @change="selectedFieldPermissions = $event"
        ></roles-field-draggable
      ></v-col>
      <v-col cols="12" lg="6"
        ><h3 class="primary--text mb-5">Modul-Berechtigungen</h3>
        <roles-module-draggable
          v-if="modulePermissions.length > 0"
          :items="modulePermissions"
          :items-selected="selectedModulePermissions"
          @change="selectedModulePermissions = $event"
        ></roles-module-draggable
      ></v-col>
    </v-row>

    <!-- <v-row>
      <v-col cols="12" lg="3">
        <v-autocomplete
          class="my-4"
          filled
          dense
          multiple
          hide-details="auto"
          :items="fieldPermissions"
          v-model="selectedPermissions"
          label="Feld-Berechtigungen"
          item-text="name"
          item-value="id"
        ></v-autocomplete>
        <v-autocomplete
          class="my-4"
          filled
          dense
          multiple
          hide-details="auto"
          :items="modulePermissions"
          v-model="selectedPermissions"
          label="Modul-Berechtigungen"
          item-text="name"
          item-value="id"
        ></v-autocomplete>
      </v-col>
    </v-row> -->
    <v-row class="mx-0 mt-4">
      <v-btn color="primary" type="submit">Rolle speichern</v-btn>
    </v-row>
  </v-form>
</template>

<script>
import RolesFieldDraggable from "./RolesFieldDraggable.vue";
import RolesModuleDraggable from "./RolesModuleDraggable.vue";

export default {
  components: {
    "roles-field-draggable": RolesFieldDraggable,
    "roles-module-draggable": RolesModuleDraggable,
  },
  data: () => ({
    permissions: [],
    selectedFieldPermissions: [],
    selectedModulePermissions: [],
    valid: true,
    inputRules: [(v) => !!v || "Feld wird benötigt"],
    conditionAll: [],
    conditionOne: [],
    canViewMap: false,
    operators: {
      number: [
        { value: "e", icon: "mdi-equal" },
        { value: "gt", icon: "mdi-greater-than" },
        { value: "gte", icon: "mdi-greater-than-or-equal" },
        { value: "lt", icon: "mdi-less-than" },
        { value: "lte", icon: "mdi-less-than-or-equal" },
        { value: "null", text: "ist leer" },
        { value: "not_null", text: "ist nicht leer" },
      ],
      text: [
        { value: "contains", text: "Enthält" },
        { value: "not_contains", text: "Enthält nicht" },
        { value: "null", text: "ist leer" },
        { value: "not_null", text: "ist nicht leer" },
      ],
    },
  }),

  props: {
    role: Object,
  },

  created() {
    this.getPermissions();

    if (this.role) {
      this.role.permissions.forEach((element) => {
        if (element.type === "field") {
          this.selectedFieldPermissions.push(element);
        }

        if (element.type === "module") {
          this.selectedModulePermissions.push(element);
        }

        this.canViewMap = this.canViewMap || element.name === "view map";
      });

      this.role.conditions.forEach((condition) => {
        if (condition.conjunction === "and") {
          this.conditionAll.push({
            data_type: condition.portal_field.data_type,
            field: condition.portal_field_id,
            operator: condition.operator,
            value: condition.value,
          });
        }

        if (condition.conjunction === "or") {
          this.conditionOne.push({
            data_type: condition.portal_field.data_type,
            field: condition.portal_field_id,
            operator: condition.operator,
            value: condition.value,
          });
        }
      });
    }
  },

  computed: {
    fieldPermissions: {
      get() {
        return _.sortBy(
          _.sortBy(
            this.permissions.filter(
              (permission) => permission.type === "field"
            ),
            (field) => field.name.toLowerCase()
          ),
          "portal_field_category.name"
        );
      },
    },
    modulePermissions: {
      get() {
        return _.sortBy(
          _.sortBy(
            this.permissions.filter(
              (permission) => permission.type === "module"
            ),
            (field) => field.name.toLowerCase()
          ),
          "portal_field_category.name"
        );
      },
    },

    availableFields: {
      get() {
        let fields = [...this.$store.getters["fieldStore/getAllFields"]];

        return _.sortBy(
          _.sortBy(fields, (field) => field.field_name.toLowerCase()),
          "portal_field_category.name"
        );
      },
    },
  },
  methods: {
    addCondition(index, type) {
      if (type === "all") {
        this.conditionAll.splice(index + 1, 0, {
          field: "",
          operator: "e",
          value: "",
        });
      }

      if (type === "one") {
        this.conditionOne.splice(index + 1, 0, {
          field: "",
          operator: "e",
          value: "",
        });
      }
    },

    removeCondition(index, type) {
      if (type === "all") {
        this.conditionAll.splice(index, 1);
      }
      if (type === "one") {
        this.conditionOne.splice(index, 1);
      }
    },

    setDataType(condition) {
      condition["data_type"] = this.$store.getters[
        "fieldStore/getDataTypeById"
      ](condition.field);
      switch (condition.data_type) {
        case "text":
          condition.operator = "contains";
          break;
        case "number":
          condition.operator = "e";
          break;
        case "date":
          condition.value = null;
          condition.operator = "e";
          break;
        default:
          break;
      }
    },

    getPermissions() {
      axios
        .get(this.$root.route("admin.permissions.getAll"))
        .then((res) => {
          this.permissions = res.data;
        })
        .catch(() => {
          this.$snackbar.showMessage({
            content: "Fehler beim Laden",
            color: "error",
          });
        });
    },
    saveRole() {
      this.$refs.roleForm.validate();

      if (this.valid) {
        axios
          .post(this.$refs.roleForm.$attrs.action, {
            name: this.role.name,
            permissions: [
              ..._.map(this.selectedFieldPermissions, "id"),
              ..._.map(this.selectedModulePermissions, "id"),
            ],
            conditionsAnd: this.conditionAll,
            conditionsOr: this.conditionOne,
            canViewMap: this.canViewMap,
            id: this.role.id || null,
          })
          .then((res) => {
            this.$snackbar.showMessage({
              content: "Rolle gespeichert",
              color: "primary",
            });
          })
          .catch(() => {
            this.$snackbar.showMessage({
              content: "Fehler beim Speichern",
              color: "error",
            });
          });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.condition-select::v-deep {
  border-radius: 4px;
  .v-input__append-outer {
    margin-left: 4px;
  }
  .v-input__control {
    .v-input__slot::before,
    .v-input__slot::after {
      content: unset;
    }
    .v-input__slot {
      background: #ffc107;

      .v-select__slot {
        .v-select__selections {
          justify-content: center;
          align-items: center;
          input {
            display: none;
          }
        }
        .v-input__append-inner {
          display: none;
        }
      }
    }
  }
}
.condition-select-menu::v-deep {
  box-shadow: unset;

  .v-list {
    padding: 0;
    .v-list-item {
      margin: 4px 0;
      padding: 0;
      border-radius: 4px;
      justify-content: center;

      &::before {
        border-radius: 4px;
      }
    }
  }
}

.profile-grid {
  display: grid;
  grid-template-columns: 1fr;
  column-gap: 30px;

  .profile-item {
    display: flex;
    column-gap: 15px;
    align-items: center;

    .profile-title {
      flex-basis: 150px;
      text-align: start;
      color: #a6adaf;
    }

    .profile-value {
      color: #919699;
      font-weight: 700;
    }

    .profile-divider {
      border: 1px solid #f7f9fa;
      height: 100%;
    }
  }
}
</style>