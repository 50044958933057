<template>
  <v-form
    v-model="valid"
    ref="fieldForm"
    :action="route('admin.fields.save')"
    @submit.prevent="saveField()"
  >
    <h2 class="primary--text :hover">Feld-Daten</h2>
    <div class="profile-grid">
      <div class="profile-item my-1">
        <div class="profile-title pa-2">Feld-Name</div>
        <div class="profile-divider"></div>
        <div class="profile-value py-2">
          <v-text-field
            filled
            dense
            hide-details="auto"
            :rules="inputRules"
            v-model="field.field_name"
          ></v-text-field>
        </div>
      </div>
      <div class="profile-item my-1">
        <div class="profile-title pa-2">Feld-Beschreibung</div>
        <div class="profile-divider"></div>
        <div class="profile-value py-2">
          <v-text-field
            filled
            dense
            hide-details="auto"
            v-model="field.description"
          ></v-text-field>
        </div>
      </div>
      <div class="profile-item my-1">
        <div class="profile-title pa-2">Datentyp</div>
        <div class="profile-divider"></div>
        <div class="profile-value py-2">
          <v-select
            filled
            dense
            hide-details="auto"
            :rules="inputRules"
            :items="dataType"
            v-model="field.data_type"
          ></v-select>
        </div>
      </div>
      <div class="profile-item my-1">
        <div class="profile-title pa-2">Verlinkung Ziel:</div>
        <div class="profile-divider"></div>
        <div class="profile-value py-2">
          <v-select
            filled
            dense
            hide-details="auto"
            clearable
            :items="computedLinkCategories"
            item-value="name"
            item-text="name"
            v-model="module"
            @change="linkField = 0"
          ></v-select>
        </div>
      </div>

      <div class="profile-item my-1" v-if="module">
        <div class="profile-title pa-2">Verlinkung Suchfeld:</div>
        <div class="profile-divider"></div>
        <div class="profile-value py-2">
          <v-autocomplete
            filled
            dense
            hide-details="auto"
            clearable
            :items="computedPortalFields"
            item-value="id"
            item-text="field_name"
            v-model="linkField"
            :rules="inputRules"
          ></v-autocomplete>
        </div>
      </div>
      <div class="profile-item my-1">
        <div class="profile-title pa-2">Kategorie</div>
        <div class="profile-divider"></div>
        <div class="profile-value py-2">
          <v-select
            filled
            dense
            hide-details="auto"
            item-value="id"
            item-text="name"
            :items="categories"
            v-model="field.portal_field_category_id"
            @change="categoryId = $event"
          ></v-select>
        </div>
      </div>
      <div class="profile-item my-1">
        <div class="profile-title pa-2">API-Felder</div>
        <div class="profile-divider"></div>
        <div class="profile-value py-2">
          <v-autocomplete
            filled
            dense
            multiple
            :items="filteredApiFields"
            hide-details="auto"
            v-model="selectedFields"
            item-value="id"
            item-text="field_name"
          ></v-autocomplete>
        </div>
      </div>
    </div>
    <v-row class="mx-0 mt-4">
      <v-btn color="primary" type="submit">Feld speichern</v-btn>
    </v-row>
  </v-form>
</template>

<script>
export default {
  data: () => ({
    valid: true,
    selectedFields: [],
    apiFields: [],
    portalFields: [],
    module: "",
    linkField: null,
    categories: [],
    routes: [],
    categoryId: null,
    inputRules: [(v) => !!v || "Feld wird benötigt"],
    dataType: [
      {
        text: "Text",
        value: "text",
      },
      {
        text: "Zahl",
        value: "number",
      },
      {
        text: "Datum",
        value: "date",
      },
      {
        text: "GEO-Koordinate",
        value: "coordinate",
      },
    ],
  }),

  props: {
    field: Object,
  },

  created() {
    axios
      .get(this.$root.route("getCategories"))
      .then((res) => {
        this.categories = _.sortBy(res.data, "name");
      })
      .catch(() => {
        this.$snackbar.showMessage({
          content: "Fehler beim Laden",
          color: "error",
        });
      });

    axios
      .get(this.$root.route("getRoutes"))
      .then((res) => {
        this.routes = res.data
          .filter((route) => {
            return !(route.includes("admin") || route.includes("generated"));
          })
          .sort();
      })
      .catch(() => {
        this.$snackbar.showMessage({
          content: "Fehler beim Laden",
          color: "error",
        });
      });

    axios
      .get(this.$root.route("getApiFields"))
      .then((res) => {
        this.apiFields = _.sortBy(res.data, (field) =>
          field.field_name.toLowerCase()
        );
      })
      .catch(() => {
        this.$snackbar.showMessage({
          content: "Fehler beim Laden",
          color: "error",
        });
      });

    axios
      .get(this.$root.route("getPortalFields"))
      .then((res) => {
        this.portalFields = _.sortBy(res.data, (field) =>
          field.field_name.toLowerCase()
        );
      })
      .catch(() => {
        this.$snackbar.showMessage({
          content: "Fehler beim Laden",
          color: "error",
        });
      });

    if (!this.$attrs.create) {
      this.field.api_fields.forEach((apiField) => {
        this.selectedFields.push(apiField.id);
      });
    }

    this.module = this.field.route.module;
    this.linkField = this.field.route.linkField;

    this.categoryId = this.field.portal_field_category_id;
  },
  computed: {
    filteredApiFields() {
      const category = this.categories.find(
        (elem) => elem.id === this.categoryId
      );
      if (category) {
        return this.apiFields.filter(
          (apiField) => apiField.table_name === category.slug
        );
      }

      return this.apiFields;
    },
    computedPortalFields: {
      get() {
        return this.portalFields.filter(
          (portalField) =>
            portalField.portal_field_category.name === this.module
        );
      },
    },
    computedLinkCategories: {
      get() {
        return this.categories.filter(
          (category) =>
            !(
              category.name == "Betreiber" ||
              category.name == "Eigentümer" ||
              category.name.includes("Ansprechpartner ")
            )
        );
      },
    },
  },
  watch: {
    categoryId: {
      handler: function (val, oldVal) {
        if (oldVal && val !== oldVal) this.selectedFields = [];
      },
    },
  },
  methods: {
    saveField() {
      this.$refs.fieldForm.validate();

      this.field.route.module = this.module;
      this.field.route.linkField = this.linkField;

      if (this.valid) {
        axios
          .post(`/admin/fields/save`, {
            portalField: this.field,
            apiFields: this.selectedFields,
          })
          .then((res) => {
            localStorage.removeItem("vuex_expires");
            this.$snackbar.showMessage({
              content: "Feld gespeichert",
              color: "primary",
            });
          })
          .catch((error) => {
            if (error.response.data) {
              this.$snackbar.showMessage({
                content: error.response.data,
                color: "error",
              });
            } else {
              this.$snackbar.showMessage({
                content: "Fehler beim Speichern!",
                color: "error",
              });
            }
          });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.profile-grid {
  display: grid;
  grid-template-columns: 1fr;
  column-gap: 30px;

  .profile-item {
    display: flex;
    column-gap: 15px;
    align-items: center;

    .profile-title {
      flex-basis: 150px;
      text-align: start;
      color: #a6adaf;
    }

    .profile-value {
      color: #919699;
      font-weight: 700;
      flex-basis: 500px;
    }

    .profile-divider {
      border: 1px solid #f7f9fa;
      height: 100%;
    }
  }
}
</style>