import Vuetify from 'vuetify'
import Vue from 'vue'
import Vuex from 'vuex'
import UUID from 'vue-uuid'
import de from 'vuetify/src/locale/de.ts'
import moment from 'moment/dist/moment'
import SecureLS from 'secure-ls'

import { ZiggyVue } from 'ziggy'
import { Ziggy } from './ziggy'

// Vue Components
import AuthCard from './components/AuthCard.vue'
import DetailsTable from './components/DetailsTable.vue'
import LinkTable from './components/LinkTable.vue'
import DetailsPanels from './components/DetailsPanels.vue'
import BookmarkButton from './components/BookmarkButton.vue'
import FilterCard from './components/FilterCard.vue'
import FilterAutocomplete from './components/FilterAutocomplete.vue'
import UserTable from './admin/UserTable.vue'
import UserRolesAutocomplete from './admin/UserRolesAutocomplete.vue'
import UserPasswordReset from './admin/UserPasswordReset.vue'
import UserEmailChange from './admin/UserEmailChange.vue'
import UserLock from './admin/UserLock.vue'
import FilterComponent from './admin/FilterComponent.vue'
import FilterTable from './admin/FilterTable.vue'
import FilterDeleteTable from './admin/FilterDeleteTable.vue'
import FieldTable from './admin/FieldTable.vue'
import FieldsEdit from './admin/FieldsEdit.vue'
import RolesTable from './admin/RolesTable.vue'
import RolesEdit from './admin/RolesEdit.vue'
import PanelConfigTable from './admin/PanelConfigTable.vue'
import PanelConfigEdit from './admin/PanelConfigEdit.vue'
import PermissionsTable from './admin/PermissionsTable.vue'
import PermissionCreate from './admin/PermissionCreate.vue'
import NewsComponent from './admin/NewsComponent.vue'
import NewsTable from './admin/NewsTable.vue'
import Snackbar from './components/Snackbar.vue'
import ExportSnackbar from './components/ExportSnackbar.vue'
import NewsWidget from './components/widgets/NewsWidget.vue'
import BookmarksWidget from './components/widgets/BookmarksWidget.vue'
import UserProfile from './components/UserProfile.vue'
import Notifications from './components/Notifications.vue'
import DetailsPagination from './components/DetailsPagination.vue'
import BerichteTable from './components/berichte/Table.vue'
import BerichteCreate from './components/berichte/Create.vue'
import BerichteEdit from './components/berichte/Edit.vue'

import snackbarPlugin from './plugins/snackbar'
import exportSnackbarPlugin from './plugins/exportSnackbar'
import store from './store/index'
import axios from 'axios'
import _ from 'lodash'

require('./bootstrap')

Vue.use(Vuetify)
Vue.use(Vuex)
Vue.use(UUID)
Vue.use(snackbarPlugin, { store })
Vue.use(exportSnackbarPlugin, { store })
Vue.use(ZiggyVue, Ziggy)

Vue.component('auth-card', AuthCard)
Vue.component('details-table', DetailsTable)
Vue.component('link-table', LinkTable)
Vue.component('details-panels', DetailsPanels)
Vue.component('bookmark-button', BookmarkButton)
Vue.component('filter-card', FilterCard)
Vue.component('filter-autocomplete', FilterAutocomplete)
Vue.component('user-table', UserTable)
Vue.component('user-roles-autocomplete', UserRolesAutocomplete)
Vue.component('user-password-reset', UserPasswordReset)
Vue.component('user-email-change', UserEmailChange)
Vue.component('user-lock', UserLock)
Vue.component('filter-table', FilterTable)
Vue.component('filter-delete-table', FilterDeleteTable)
Vue.component('filter-component', FilterComponent)
Vue.component('fields-table', FieldTable)
Vue.component('fields-edit', FieldsEdit)
Vue.component('roles-table', RolesTable)
Vue.component('roles-edit', RolesEdit)
Vue.component('panel-config-table', PanelConfigTable)
Vue.component('panel-config-edit', PanelConfigEdit)
Vue.component('permissions-table', PermissionsTable)
Vue.component('permission-create', PermissionCreate)
Vue.component('news-component', NewsComponent)
Vue.component('news-table', NewsTable)
Vue.component('snackbar', Snackbar)
Vue.component('export-snackbar', ExportSnackbar)
Vue.component('news-widget', NewsWidget)
Vue.component('bookmarks-widget', BookmarksWidget)
Vue.component('user-profile', UserProfile)
Vue.component('notifications', Notifications)
Vue.component('details-pagination', DetailsPagination)
Vue.component('berichte-table', BerichteTable)
Vue.component('berichte-create', BerichteCreate)
Vue.component('berichte-edit', BerichteEdit)

moment.locale('de')

const ls = new SecureLS({ isCompression: true })

Vue.filter('formatDate', function (value) {
  if (!value) return ''
  if (moment(value).isValid()) return moment(value).format('DD.MM.YYYY')
  if (moment(value, 'MMM DD YYYY HH:mm:ss:A').isValid()) {
    return moment(value, 'MMM DD YYYY HH:mm:ss:A').format('DD.MM.YYYY')
  }
  return value
})

Vue.filter('formatNumber', function (value) {
  if (!value) return 0
  return value.toLocaleString('de-DE', { useGrouping: false })
})

Vue.filter('formatCoordinate', function (value) {
  if (!value) return 0
  return value.toLocaleString('de-DE', { useGrouping: false, minimumFractionDigits: 6, maximumFractionDigits: 6 })
})

Vue.filter('formatDateTime', function (value) {
  if (!value) return ''
  if (moment(value).isValid()) {
    return moment(value).format('DD.MM.YYYY HH:mm:ss')
  }
  if (moment(value, 'MMM DD YYYY HH:mm:ss:A').isValid()) {
    return moment(value, 'MMM DD YYYY HH:mm:ss:A').format('DD.MM.YYYY HH:mm:ss')
  }
  return value
})

Vue.filter('capitalize', function (value) {
  if (!value) return ''
  return value.charAt(0).toUpperCase() + value.slice(1)
})

Vue.prototype.$filters = Vue.options.filters

/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */

// const files = require.context('./', true, /\.vue$/i)
// files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default))

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */
const app = new Vue({
  el: '#app',
  store: store,
  data: () => ({
    search: '',
    searchDebounce: '',
    showFilterCard: false,
    currentItems: [],
    showExportDialog: false,
    showMap: false,
    navigation: false,
    timers: []
  }),
  created () {
    if (
      localStorage.hasOwnProperty('vuex_expires') &&
      new Date().getTime() >= Date.parse(localStorage.getItem('vuex_expires'))
    ) {
      localStorage.setItem(
        'vuex_expires',
        new Date(new Date().getTime() + 5 * 60000)
      )
      ls.remove('vuex')
      localStorage.removeItem('filter')
    }

    if (!localStorage.hasOwnProperty('vuex_expires')) {
      localStorage.setItem(
        'vuex_expires',
        new Date(new Date().getTime() + 5 * 60000)
      )
      ls.remove('vuex')
      localStorage.removeItem('filter')
    }

    if (!localStorage.hasOwnProperty('vuex')) {
      this.$store.dispatch('filter/getAllFilters')
      this.$store.dispatch('bookmark/loadBookmarks')
      this.$store.dispatch('favorite/loadFavorite')

      this.$store.dispatch('fieldStore/loadAllField').then(() => {
        if (localStorage.filter) {
          this.currentFilter = parseInt(localStorage.filter)
        }
        this.$store.dispatch('filter/loadDefault').then(() => {
          if (
            this.$store.state.filter.defaults.length > 0 &&
            (!this.currentFilter ||
              !this.$store.state.filter.availableFilters.find(
                filter => filter.id === this.currentFilter
              ))
          ) {
            this.currentFilter = this.$store.getters[
              'filter/getDefaultFilterByModule'
            ](this.module)
              ? this.$store.getters['filter/getDefaultFilterByModule'](
                  this.module
                ).id
              : 0
            localStorage.setItem('filter', this.currentFilter)
          }
        })
      })
    }

    if (sessionStorage.search) {
      const searchOptions = JSON.parse(sessionStorage.search)
      if (searchOptions.module === this.module) {
        this.searchDebounce = searchOptions.term
      }
    }

    this.$store.dispatch('notifications/loadNotifications', { vm: this })

    axios.interceptors.request.use(
      request => {
        this.requestStartedAt = new Date().getTime()
        request.timer = this.startTimer()
        return request
      },
      error => {
        return Promise.reject(error)
      }
    )

    axios.interceptors.response.use(
      response => {
        this.stopTimer(response.config.timer)
        return response
      },
      error => {
        this.stopTimer(error.response.config.timer)
        return Promise.reject(error)
      }
    )
  },
  computed: {
    module: {
      get () {
        return this.$filters.capitalize(window.location.pathname.split('/')[1])
      }
    },

    currentFilter: {
      get () {
        return this.$store.state.filter.id
      },

      set (id) {
        this.$store.dispatch('filter/setCurrent', id)
      }
    },

    hasFavorite: {
      get () {
        return this.$store.getters['favorite/checkFavorite']
      }
    },
    headers: {
      get () {
        return this.$store.state.fieldStore.selectedFields.map(field => ({
          ...field,
          value:
            this.getHeaderSlug(field.portal_field_category.name) +
            field.table_value
        }))
      }
    }
  },
  methods: {
    openNavigation () {
      this.navigation = true
    },
    closeNavigation () {
      this.navigation = false
    },
    startTimer () {
      const index = this.timers.push({ elapsedTime: 0 }) - 1
      this.timers[index].timer = setInterval(() => {
        this.timers[index].elapsedTime += 1000
      }, 1000)

      return index
    },

    stopTimer (index) {
      clearInterval(this.timers[index].timer)
    },

    getHeaderSlug (categoryName) {
      if (categoryName === this.module) return ''

      switch (categoryName) {
        case 'Ansprechpartner':
          return 'ap.'
        case 'Ansprechpartner Betreiber':
          return 'betreiber.ap.'
        case 'Ansprechpartner Eigentümer':
          return 'eigentümer.ap.'
        case 'Betreiber':
          return 'betreiber.'
        case 'Brennstoffanlagen':
          return 'bsa.'
        case 'Brennstoffe':
          return 'brennstoff.'
        case 'Eigentümer':
          return 'eigentümer.'
        case 'Organisationen':
          return 'organisation.'
        case 'Photovoltaikanlagen':
          return 'pva.'
        case 'Photovoltaikparks':
          return 'pv_park.'
        case 'Windenergieanlagen':
          return 'wea.'
        case 'Windparks':
          return 'windpark.'
        default:
          break
      }
    },

    openPrintPDF () {
      axios
        .post(
          this.route('excel.print'),
          {
            items: this.currentItems,
            headings: this.headers
          },
          {
            responseType: 'blob'
          }
        )
        .then(res => {
          const url = window.URL.createObjectURL(
            new Blob([res.data], { type: 'application/pdf' })
          )
          window.open(url)
        })
    },

    openPrintPanelPDF (item) {
      axios
        .post(
          this.route('excel.print.panel'),
          {
            item: item,
            blocks: this.$refs.detailsPanels.configData.panel_blocks,
            module: this.module
          },
          {
            responseType: 'blob'
          }
        )
        .then(res => {
          const url = window.URL.createObjectURL(
            new Blob([res.data], { type: 'application/pdf' })
          )
          window.open(url)
        })
    },

    toggleFilterCard () {
      this.showFilterCard = !this.showFilterCard
      this.$refs.detailsTable.getData()
    },

    toggleExportDialog () {
      this.showExportDialog = !this.showExportDialog
    },

    toggleMap () {
      this.showMap = !this.showMap
    },

    removeFavorite () {
      axios
        .delete('/favorite')
        .then(res => {
          this.$store.dispatch('favorite/removeFavorite')
          this.$snackbar.showMessage({
            content: 'Startseite entfernt!',
            color: 'primary'
          })
        })
        .catch(() => {
          this.$snackbar.showMessage({
            content: 'Fehler beim Speichern!',
            color: 'error'
          })
        })
    },

    toggleFavorite (route, id) {
      axios
        .post('/favorite', {
          route: route,
          id: id
        })
        .then(res => {
          this.$store.dispatch('favorite/setFavorite', res.data.favorite)
          this.$snackbar.showMessage({
            content: 'Startseite gesetzt!',
            color: 'primary'
          })
        })
        .catch(() => {
          this.$snackbar.showMessage({
            content: 'Fehler beim Speichern!',
            color: 'error'
          })
        })
    },
    getFilterSettings (val) {
      if (val === null || val === undefined || val === 0) {
        this.$store.dispatch('filter/setDefaultByModule', this.module)
        return
      }
      axios
        .post(`/filter/${val}`, {
          fieldIds: _.map(this.$store.getters['fieldStore/getAllFields'], 'id')
        })
        .then(res => {
          this.$store.commit(
            'filter/updateConditions',
            res.data.filterConditions
          )
          this.$store.dispatch(
            'fieldStore/setSelectedFields',
            res.data.filterFields
          )
        })
        .catch(() => {
          this.$snackbar.showMessage({
            content: 'Fehler beim Laden!',
            color: 'error'
          })
        })
    },
    setSearchString: _.debounce(function (string, module) {
      if (string.length >= 3) {
        sessionStorage.search = JSON.stringify({
          term: string,
          module: module
        })
        this.search = string
      } else {
        sessionStorage.search = JSON.stringify({
          term: '',
          module: module
        })
        this.search = ''
      }
    }, 500),

    startSQLTransfer () {
      axios
        .post('/admin/fields/startSqlTransfer')
        .then(res => {
          this.$snackbar.showMessage({
            content: 'Abgleich wurde gestartet.',
            color: 'info'
          })
        })
        .catch(() => {
          this.$snackbar.showMessage({
            content: 'Unerwarteter Fehler!',
            color: 'error'
          })
        })
    }
  },
  watch: {
    searchDebounce: {
      handler () {
        this.setSearchString(this.searchDebounce, this.module)
      }
    },
    currentFilter: {
      handler (newFilterId, oldFilterId) {
        localStorage.filter = newFilterId
        if (newFilterId !== oldFilterId) {
          this.getFilterSettings(newFilterId)
        }
      }
    },
    timers: {
      handler () {
        this.timers.forEach(elem => {
          if (elem.elapsedTime >= 15000) {
            this.$snackbar.showMessage({
              content:
                'Die Abfrage Ihrer Daten beinhaltet einen größeren Datensatz, daher kann sich die Ausgabe verzögern. Bitte warten Sie, bis die Liste vollständig geladen ist.',
              color: 'info'
            })
            clearInterval(elem.timer)
            elem.elapsedTime = 0
          }
        })
      },
      deep: true
    }
  },
  vuetify: new Vuetify({
    lang: {
      locales: { de },
      current: 'de'
    },
    theme: {
      themes: {
        light: {
          primary: '#84a315',
          secondary: '#646363',
          anchor: '#646363',
          accent: '#82B1FF',
          error: '#FF5252',
          info: '#2196F3',
          success: '#4CAF50',
          warning: '#FFC107'
        }
      }
    },
    icons: {
      iconfont: 'mdi'
    }
  })
})
