<template>
  <div>
    <v-data-table
      item-key="id"
      :search="search"
      :headers="headers"
      :items="cloneItems"
      :items-per-page.sync="tableOptions.itemsPerPage"
      :sort-by.sync="tableOptions.sortBy"
      :sort-desc.sync="tableOptions.sortDesc"
      :page.sync="tableOptions.page"
      @page-count="pageCount = $event"
      dense
      locale="de-DE"
      hide-default-footer
      :loading="loading"
      :footer-props="{
        itemsPerPageText: 'Zeilen pro Seite: ',
        itemsPerPageOptions: [10, 25, 50, -1],
      }"
    >
      <template v-slot:item.actions="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              icon
              color="info"
              :href="route('berichte.view', item.id)"
            >
              <v-icon> mdi-eye </v-icon>
            </v-btn>
          </template>
          <span>Anzeigen</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              icon
              color="warning"
              :href="route('berichte.edit', item.id)"
            >
              <v-icon> mdi-pencil </v-icon>
            </v-btn>
          </template>
          <span>Editieren</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              icon
              :href="route('berichte.copy', item.id)"
            >
              <v-icon> mdi-content-copy </v-icon>
            </v-btn>
          </template>
          <span>Kopieren</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              icon
              color="error"
              @click.stop="openDialog(item.id)"
            >
              <v-icon> mdi-delete </v-icon>
            </v-btn>
          </template>
          <span>Löschen</span>
        </v-tooltip>
      </template>
      <template
        v-slot:footer="{
          props: { pagination, itemsPerPageText, itemsPerPageOptions },
        }"
      >
        <table-footer
          :pagination="pagination"
          :itemsPerPageText="itemsPerPageText"
          :itemsPerPageOptions="itemsPerPageOptions"
          :tableOptions="tableOptions"
          :pageCount="pageCount"
        ></table-footer>
      </template>
    </v-data-table>
    <v-dialog v-model="dialog" max-width="400">
      <v-card>
        <v-toolbar color="primary" dark flat>
          <v-toolbar-title>Bericht löschen?</v-toolbar-title>
        </v-toolbar>
        <v-card-actions>
          <v-btn color="secondary" @click.stop="dialog = false" x-small
            >Abbrechen</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn color="error" type="btn" @click="deleteBericht()" small
            >Löschen</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import TableFooter from "../TableFooter.vue";

export default {
  components: {
    "table-footer": TableFooter,
  },
  data: () => ({
    pageCount: 0,
    dialog: false,
    currentId: null,
    cloneItems: [],
    tableOptions: {
      page: 1,
      itemsPerPage: 10,
      sortBy: [],
      sortDesc: [],
    },
    loading: false,
  }),
  props: {
    search: String,
    berichte: Array,
    headers: Array,
  },

  created() {
    this.loadLocalStorageTableOptions();
    this.cloneItems = _.cloneDeep(this.filteredItems);
  },
  computed: {
    filteredItems: {
      get() {
        if (!this.filter) return this.berichte;
        return this.berichte.filter(
          (bericht) => bericht.primary_module.id === this.filter.id
        );
      },
    },

    filter: {
      get() {
        return this.$root.$refs["module-filter"].selectedItems[0];
      },
    },
  },
  methods: {
    loadLocalStorageTableOptions() {
      if (localStorage.hasOwnProperty("tableOptions")) {
        const lsTableOptions = JSON.parse(localStorage.tableOptions);

        if (lsTableOptions.module === "Berichte")
          this.tableOptions = lsTableOptions;
      }
    },

    openDialog(id) {
      this.dialog = true;
      this.currentId = id;
    },

    deleteBericht() {
      axios
        .delete(this.$root.route("berichte.delete", this.currentId))
        .then((res) => {
          this.dialog = false;
          this.$snackbar.showMessage({
            content: "Bericht gelöscht",
            color: "primary",
          });
          this.cloneItems.splice(
            this.cloneItems.findIndex((elem) => elem.id === this.currentId),
            1
          );
        })
        .catch(() => {
          this.dialog = false;
          this.$snackbar.showMessage({
            content: "Fehler beim Löschen",
            color: "error",
          });
        });
    },
  },

  watch: {
    filteredItems: {
      handler() {
        this.cloneItems = _.cloneDeep(this.filteredItems);
      },
      deep: true,
    },
    tableOptions: {
      handler() {
        this.tableOptions.module = "Berichte";

        localStorage.tableOptions = JSON.stringify(this.tableOptions);
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.v-data-table::v-deep {
  .v-data-table-header th {
    white-space: nowrap;
  }
  .cursor-pointer {
    cursor: pointer;
  }

  .table-item {
    white-space: nowrap !important;
  }
}
</style>