<template>
  <div id="map"></div>
</template>

<script>
import { Loader } from "@googlemaps/js-api-loader";
export default {
  data: () => ({
    loader: new Loader({
      apiKey: "AIzaSyAIucv2axoHxJPcrqCkIzpLUh68hPQNZ2g",
      version: "weekly",
    }),
  }),
  props: {
    location: String,
    data: Object,
    module: String,
    wea: Array,
    pva: Array,
    bsa: Array
  },
  computed: {
    content() {
      switch (this.module) {
        case "Windparks":
          return `<h4 class="mb-2">Name Windpark: ${this.data.Name_Windpark}</h4><h4 class="mb-2">Betreiber: ${this.data.Betreiber_Windpark}</h4><h4 class="mb-2">Leistung Windpark: ${this.data.Nennleistung_Windpark} MW</h4><h4 class="mb-2">Anzahl Anlagen: ${this.data.Anzahl_Anlagen_im_Windpark}</h4>`;
        case "Photovoltaikparks":
          return `<h4 class="mb-2">Name PV-Park: ${this.data.Name_PV_Park}</h4><h4 class="mb-2">Betreiber: ${this.data.Anlagenbetreiber}</h4><h4 class="mb-2">Anzahl Solarmodule: ${this.data.Anzahl_SolarModule}</h4><h4 class="mb-2">Nennleistung: ${this.data.Nennleistung_PVP_Park} kW</h4>`;
        case "Brennstoffe":
          return `<h4 class="mb-2">Name EZ: ${this.data.Name_Anlage}</h4><h4 class="mb-2">Betreiber: ${this.data.Anlagenbetreiber}</h4><h4 class="mb-2">Anzahl der Anlagen: ${this.data.AnzahlderAnlagen}</h4><h4 class="mb-2">Energieträger: ${this.data.Energieträger}</h4><h4 class="mb-2">Bruttoleistung: ${this.data.Bruttoleistung_in_kW} kW</h4><h4 class="mb-2">Elektrische KWK-Leistung: ${this.data.ElektrischeKWKLeistung} kW</h4>`;
        case "Windenergieanlagen":
          return `<h4 class="mb-2">Name: ${
            this.data.Wind_Name
          }</h4><h4 class="mb-2">Betreiber: ${
            this.data.BetreibergesellschaftWindpark
          }</h4><h4 class="mb-2">Leistung: ${
            this.data.Nettonennleistung_in_kW
          } kW</h4><h4 class="mb-2">Inbetriebnahmedatum: ${this.$filters.formatDate(
            this.data.Inbetriebnahmedatum
          )}</h4><h4 class="mb-2">Hersteller: ${this.data.Hersteller}</h4>`;
        case "Photovoltaikanlagen":
          return `<h4 class="mb-2">Name: ${this.data.Photovoltaikname}</h4><h4 class="mb-2">Betreiber: ${this.data.Anlagenbetreiber}</h4><h4 class="mb-2">Anzahl Solarmodule: ${this.data.Anzahl_SolarModule}</h4><h4 class="mb-2">Bruttoleistung: ${this.data.Bruttoleistung_in_kW} kW</h4>`;
        case "Brennstoffanlagen":
          return `<h4 class="mb-2">Name: ${this.data.ThermischeAnlage_Name}</h4><h4 class="mb-2">Betreiber: ${this.data.Anlagenbetreiber}</h4><h4 class="mb-2">Hauptbrennstoff:: ${this.data.Hauptbrennstoff_der_Einheit}</h4><h4 class="mb-2">Bruttoleistung: ${this.data.Bruttoleistung_in_kW} kW</h4>`;
        case "Organisationen":
          return `<h4 class="mb-2">Firmenname: ${this.data["Firmenname Orga"]}</h4><h4 class="mb-2">Straße: ${this.data.Street}</h4><h4 class="mb-2">PLZ Ort: ${this.data.ZipCode} ${this.data.City}</h4><h4 class="mb-2">Telefon: ${this.data.CommPhoneCentral}</h4>`;
      }
    },
  },

  created() {
    this.loader.load().then(() => {
      const geocoder = new google.maps.Geocoder();

      const map = new google.maps.Map(document.getElementById("map"), {
        center: { lat: 51.163375, lng: 10.447683 },
        zoom: 6,
      });

      geocoder
        .geocode({ address: this.location })
        .then((result) => {
          const window = new google.maps.InfoWindow({
            content: this.content,
          });
          const marker = new google.maps.Marker({
            position: result.results[0].geometry.location,
            map: map,
          });
          marker.addListener("click", () => {
            window.open(map, marker);
          });
        })
        .catch(() => {
          this.$snackbar.showMessage({
            content: "Fehler beim Laden der Karte!",
            color: "error",
          });
        });

      if (this.wea) {
        this.wea.forEach((element) => {
          const window = new google.maps.InfoWindow(
            this.getContent(element, "Windenergieanlagen")
          );

          const marker = new google.maps.Marker({
            position: {
              lat: element.Lat,
              lng: element.Long,
            },
            map: map,
          });

          marker.addListener("click", () => {
            window.open(map, marker);
          });
        });
      }

      if (this.pva) {
        this.pva.forEach((element) => {
          const window = new google.maps.InfoWindow(
            this.getContent(element, "Photovoltaikanlagen")
          );

          const marker = new google.maps.Marker({
            position: {
              lat: element.Lat,
              lng: element.Long,
            },
            map: map,
          });

          marker.addListener("click", () => {
            window.open(map, marker);
          });
        });
      }

      if (this.bsa) {
        this.bsa.forEach((element) => {
          const window = new google.maps.InfoWindow(
            this.getContent(element, "Brennstoffanlagen")
          );

          const marker = new google.maps.Marker({
            position: {
              lat: element.Lat,
              lng: element.Long,
            },
            map: map,
          });

          marker.addListener("click", () => {
            window.open(map, marker);
          });
        });
      }
    });
  },
  methods: {
    getContent(item, module) {
      switch (module) {
        case "Windenergieanlagen":
          return {
            content: `<h4 class="mb-2">Name: ${
              item.Wind_Name
            }</h4><h4 class="mb-2">Betreiber: ${
              item.BetreibergesellschaftWindpark
            }</h4><h4 class="mb-2">Leistung: ${
              item.Nettonennleistung_in_kW
            } kW</h4><h4 class="mb-2">Inbetriebnahmedatum: ${this.$filters.formatDate(
              item.Inbetriebnahmedatum
            )}</h4><h4 class="mb-2">Hersteller: ${
              item.Hersteller
            }</h4><a style="float: right; color: #82B1FF" href="${this.$root.route(
              "windenergieanlagen.details",
              item.Id
            )}">Zur Windenergieanlage</a>`,
          };
        case "Photovoltaikanlagen":
          return {
            content: `<h4 class="mb-2">Name: ${
              item.Photovoltaikname
            }</h4><h4 class="mb-2">Betreiber: ${
              item.Anlagenbetreiber
            }</h4><h4 class="mb-2">Anzahl Solarmodule: ${
              item.Anzahl_SolarModule
            }</h4><h4 class="mb-2">Bruttoleistung: ${
              item.Bruttoleistung_in_kW
            } kW</h4><a style="float: right; color: #82B1FF" href="${this.$root.route(
              "photovoltaikanlagen.details",
              item.Id
            )}">Zur Photovoltaikanlage</a>`,
          };
        case "Brennstoffanlagen":
          return {
            content: `<h4 class="mb-2">Name: ${
              item.ThermischeAnlage_Name
            }</h4><h4 class="mb-2">Betreiber: ${
              item.Anlagenbetreiber
            }</h4><h4 class="mb-2">Hauptbrennstoff:: ${
              item.Hauptbrennstoff_der_Einheit
            }</h4><h4 class="mb-2">Bruttoleistung: ${
              item.Bruttoleistung_in_kW
            } kW</h4><a style="float: right; color: #82B1FF" href="${this.$root.route(
              "brennstoffanlagen.details",
              item.Id
            )}">Zur Thermischen Anlage</a>`,
          };
      }
    },
  },
};
</script>

<style lang="scss" scoped>
#map {
  height: 600px;
}
</style>
