<template>
  <v-form method="POST" @submit.prevent="sendPasswordResetLink">
    <v-btn color="primary" type="submit" block>Passwort zurücksetzen</v-btn>
  </v-form>
</template>

<script>
export default {
  props: {
    email: String,
  },
  methods: {
    sendPasswordResetLink() {
      axios
        .post(this.$root.route("admin.password.email"), {
          email: this.email,
        })
        .then(() => {
          this.$snackbar.showMessage({
            content: `Link zum zurücksetzen an "${this.email}" geschickt.`,
            color: "primary",
          });
        })
        .catch(() => {
          this.$snackbar.showMessage({
            content: "Unerwarteter Fehler!",
            color: "error",
          });
        });
    },
  },
};
</script>

<style lang="scss" scoped>
</style>