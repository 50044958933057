<template>
  <v-snackbar
    class="pt-0"
    top
    v-model="show"
    color="info"
    timeout="-1"
  >
    <v-row class="ma-0" align="center">
      <v-progress-circular size="24" class="mr-4" indeterminate color="white"></v-progress-circular>
      <div>Daten werden exportiert...</div>
    </v-row>
    <template v-slot:action>
      <v-btn @click="show = false" icon class="mt-1">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
export default {
  data() {
    return {
      show: false,
    };
  },

  created() {
    this.$store.subscribe((mutation, state) => {
      if (mutation.type === "exportSnackbar/showMessage") {
        this.show = true;
      }
      if (mutation.type === "exportSnackbar/closeMessage") {
        this.show = false;
      }
    });
  },
};
</script>

<style lang="scss">
.v-snack__action {
  align-items: flex-start;
  align-self: flex-start;
}
</style>