<template>
  <v-data-table
    v-model="selected"
    show-select
    hide-default-footer
    :headers="headers"
    :items="items"
    item-key="id"
    :search="search"
    :options="{ itemsPerPage: itemsPerPage }"
    :page.sync="page"
    @page-count="pageCount = $event"
    dense
    :footer-props="{
      itemsPerPageText: 'Zeilen pro Seite: ',
      itemsPerPageOptions: [5, 10, 15, 30, -1],
    }"
  >
    <template v-slot:item.actions="{ item }">
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            v-on="on"
            icon
            :href="route('admin.panels.details', item.id)"
          >
            <v-icon> mdi-pencil </v-icon>
          </v-btn>
        </template>
        <span>Editieren</span>
      </v-tooltip>
    </template>
    <template
      v-slot:footer="{
        props: { pagination, itemsPerPageText, itemsPerPageOptions, options },
      }"
    >
      <table-footer
        :pagination="pagination"
        :itemsPerPageText="itemsPerPageText"
        :itemsPerPageOptions="itemsPerPageOptions"
        :tableOptions="options"
        :pageCount="pageCount"
      ></table-footer>
    </template>
  </v-data-table>
</template>

<script>
import TableFooter from "../components/TableFooter.vue";

export default {
  components: {
    "table-footer": TableFooter,
  },
  data: () => ({
    page: 1,
    pageCount: 0,
    itemsPerPage: 10,
    selected: [],
  }),
  props: {
    items: Array,
    search: String,
    headers: Array,
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
</style>