<template>
  <div>
    <v-expansion-panels v-model="openPanels" multiple>
      <v-expansion-panel
        active-class="active"
        v-for="block in this.configData.panel_blocks"
        :key="block.id"
      >
        <v-expansion-panel-header class="text-h6">
          <v-col cols="6">
            {{ block.title }}
          </v-col>
          <v-row
            class="flex-nowrap mr-8"
            align="center"
            justify="end"
            style="column-gap: 30px"
          >
            <v-col class="text-right">{{ block.subtitle }}</v-col>
            <template v-if="block.featured_image">
              <v-col cols="3" class="py-0">
                <v-img
                  height="100"
                  width="150"
                  contain
                  :src="block.featured_image"
                ></v-img>
              </v-col>
            </template>
          </v-row>
        </v-expansion-panel-header>
        <v-expansion-panel-content v-if="block.type === 'table'">
          <v-divider class="mb-4"></v-divider>
          <v-row
            :class="{ 'flex-nowrap': $vuetify.breakpoint.lgAndUp }"
            v-if="block.module === module"
          >
            <template v-for="(column, index) in block.panel_columns">
              <v-col
                cols="12"
                :lg="12 / block.panel_columns.length"
                v-if="column.portal_fields.length > 0"
                :key="index"
              >
                <v-data-iterator
                  disable-filtering
                  disable-pagination
                  disable-sort
                  hide-default-footer
                  :items="column.portal_fields"
                >
                  <template v-slot:default="{ items }">
                    <div
                      v-for="item in items"
                      :key="item.id"
                      class="fields-item px-4 my-1"
                    >
                      <div class="field-title py-2">
                        {{ item.field_name }}
                        <template v-if="item.description">
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn color="info" icon v-bind="attrs" v-on="on">
                                <v-icon>mdi-information-outline</v-icon>
                              </v-btn>
                            </template>
                            <span>{{ item.description }}</span>
                          </v-tooltip>
                        </template>
                      </div>
                      <div class="field-divider"></div>
                      <div
                        class="field-value py-2"
                        v-if="block.module == module"
                      >
                        <template
                          v-if="
                            !(item.field_name in data) || !data[item.field_name]
                          "
                        >
                          -
                        </template>
                        <template v-else-if="item.data_type == 'date'">
                          {{ data[item.field_name] | formatDate }}
                          <v-btn
                            color="info"
                            icon
                            :href="
                              route(
                                `${item.portal_field_route.module.toLowerCase()}.linked`,
                                {
                                  field: item.portal_field_route.link_field_id,
                                  value: data[item.field_name],
                                }
                              )
                            "
                            v-if="item.portal_field_route"
                          >
                            <v-icon>mdi-link-variant</v-icon>
                          </v-btn>
                        </template>
                        <template v-else-if="item.data_type == 'number'">
                          {{ data[item.field_name] | formatNumber }}
                          <v-btn
                            color="info"
                            icon
                            :href="
                              route(
                                `${item.portal_field_route.module.toLowerCase()}.linked`,
                                {
                                  field: item.portal_field_route.link_field_id,
                                  value: data[item.field_name],
                                }
                              )
                            "
                            v-if="item.portal_field_route"
                          >
                            <v-icon>mdi-link-variant</v-icon>
                          </v-btn>
                        </template>
                        <template v-else-if="item.data_type == 'coordinate'">
                          {{ data[item.field_name] | formatCoordinate }}
                          <v-btn
                            color="info"
                            icon
                            :href="
                              route(
                                `${item.portal_field_route.module.toLowerCase()}.linked`,
                                {
                                  field: item.portal_field_route.link_field_id,
                                  value: data[item.field_name],
                                }
                              )
                            "
                            v-if="item.portal_field_route"
                          >
                            <v-icon>mdi-link-variant</v-icon>
                          </v-btn>
                        </template>
                        <template v-else-if="isURL(data[item.field_name])">
                          <a :href="isURL(data[item.field_name])">{{
                            data[item.field_name]
                          }}</a>
                        </template>
                        <template v-else>
                          {{ data[item.field_name] }}
                          <v-btn
                            color="info"
                            icon
                            :href="
                              route(
                                `${item.portal_field_route.module.toLowerCase()}.linked`,
                                {
                                  field: item.portal_field_route.link_field_id,
                                  value: data[item.field_name],
                                }
                              )
                            "
                            v-if="item.portal_field_route"
                          >
                            <v-icon>mdi-link-variant</v-icon>
                          </v-btn>
                        </template>
                      </div>
                      <div
                        class="field-value py-2"
                        v-else-if="data[block.module.toLowerCase()]"
                      >
                        <template v-if="item.data_type == 'date'">
                          {{
                            data[block.module.toLowerCase()][item.field_name]
                              | formatDate
                          }}
                          <v-btn
                            color="info"
                            icon
                            :href="
                              route(
                                `${item.portal_field_route.module.toLowerCase()}.linked`,
                                {
                                  field: item.portal_field_route.link_field_id,
                                  value:
                                    data[block.module.toLowerCase()][
                                      item.field_name
                                    ],
                                }
                              )
                            "
                            v-if="item.portal_field_route"
                          >
                            <v-icon>mdi-link-variant</v-icon>
                          </v-btn>
                        </template>
                        <template v-else-if="item.data_type == 'number'">
                          {{
                            data[block.module.toLowerCase()][item.field_name]
                              | formatNumber
                          }}
                          <v-btn
                            color="info"
                            icon
                            :href="
                              route(
                                `${item.portal_field_route.module.toLowerCase()}.linked`,
                                {
                                  field: item.portal_field_route.link_field_id,
                                  value:
                                    data[block.module.toLowerCase()][
                                      item.field_name
                                    ],
                                }
                              )
                            "
                            v-if="item.portal_field_route"
                          >
                            <v-icon>mdi-link-variant</v-icon>
                          </v-btn>
                        </template>
                        <template v-else-if="item.data_type == 'coordinate'">
                          {{
                            data[block.module.toLowerCase()][item.field_name]
                              | formatCoordinate
                          }}
                          <v-btn
                            color="info"
                            icon
                            :href="
                              route(
                                `${item.portal_field_route.module.toLowerCase()}.linked`,
                                {
                                  field: item.portal_field_route.link_field_id,
                                  value:
                                    data[block.module.toLowerCase()][
                                      item.field_name
                                    ],
                                }
                              )
                            "
                            v-if="item.portal_field_route"
                          >
                            <v-icon>mdi-link-variant</v-icon>
                          </v-btn>
                        </template>
                        <template
                          v-else-if="
                            isURL(
                              data[block.module.toLowerCase()][item.field_name]
                            )
                          "
                        >
                          <a
                            :href="
                              isURL(
                                data[block.module.toLowerCase()][
                                  item.field_name
                                ]
                              )
                            "
                            >{{
                              data[block.module.toLowerCase()][item.field_name]
                            }}</a
                          >
                        </template>
                        <template v-else>
                          {{
                            data[block.module.toLowerCase()][item.field_name]
                          }}
                          <v-btn
                            color="info"
                            icon
                            :href="
                              route(
                                `${item.portal_field_route.module.toLowerCase()}.linked`,
                                {
                                  field: item.portal_field_route.link_field_id,
                                  value:
                                    data[block.module.toLowerCase()][
                                      item.field_name
                                    ],
                                }
                              )
                            "
                            v-if="item.portal_field_route"
                          >
                            <v-icon>mdi-link-variant</v-icon>
                          </v-btn>
                        </template>
                      </div>
                      <div class="field-value py-2" v-else>-</div>
                    </div>
                  </template>
                </v-data-iterator>
              </v-col>
            </template>
          </v-row>
          <div v-else-if="block.module === 'Ansprechpartner'">
            <v-row v-for="(ap, index) in data['ap']" :key="index">
              <v-col cols="12">
                <h3>Ansprechpartner {{ index + 1 }}</h3>
              </v-col>
              <template v-for="(column, index) in block.panel_columns">
                <v-col
                  cols="12"
                  :lg="12 / block.panel_columns.length"
                  v-if="column.portal_fields.length > 0"
                  :key="index"
                >
                  <v-data-iterator
                    disable-filtering
                    disable-pagination
                    disable-sort
                    hide-default-footer
                    :items="column.portal_fields"
                  >
                    <template v-slot:default="{ items }">
                      <div
                        v-for="item in items"
                        :key="item.id"
                        class="fields-item px-4 my-1"
                      >
                        <div class="field-title py-2">
                          {{ item.field_name }}
                          <template v-if="item.description">
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                  color="info"
                                  icon
                                  v-bind="attrs"
                                  v-on="on"
                                >
                                  <v-icon>mdi-information-outline</v-icon>
                                </v-btn>
                              </template>
                              <span>{{ item.description }}</span>
                            </v-tooltip>
                          </template>
                        </div>
                        <div class="field-divider"></div>
                        <div class="field-value py-2">
                          <template
                            v-if="
                              !(item.field_name in ap) || !ap[item.field_name]
                            "
                          >
                            -
                          </template>
                          <template v-else-if="item.data_type == 'date'">
                            {{ ap[item.field_name] | formatDate }}
                            <v-btn
                              color="info"
                              icon
                              :href="
                                route(
                                  `${item.portal_field_route.module.toLowerCase()}.linked`,
                                  {
                                    field:
                                      item.portal_field_route.link_field_id,
                                    value: ap[item.field_name],
                                  }
                                )
                              "
                              v-if="item.portal_field_route"
                            >
                              <v-icon>mdi-link-variant</v-icon>
                            </v-btn>
                          </template>
                          <template v-else-if="item.data_type == 'number'">
                            {{ ap[item.field_name] | formatNumber }}
                            <v-btn
                              color="info"
                              icon
                              :href="
                                route(
                                  `${item.portal_field_route.module.toLowerCase()}.linked`,
                                  {
                                    field:
                                      item.portal_field_route.link_field_id,
                                    value: ap[item.field_name],
                                  }
                                )
                              "
                              v-if="item.portal_field_route"
                            >
                              <v-icon>mdi-link-variant</v-icon>
                            </v-btn>
                          </template>
                          <template v-else-if="item.data_type == 'coordinate'">
                            {{ ap[item.field_name] | formatCoordinate }}
                            <v-btn
                              color="info"
                              icon
                              :href="
                                route(
                                  `${item.portal_field_route.module.toLowerCase()}.linked`,
                                  {
                                    field:
                                      item.portal_field_route.link_field_id,
                                    value: ap[item.field_name],
                                  }
                                )
                              "
                              v-if="item.portal_field_route"
                            >
                              <v-icon>mdi-link-variant</v-icon>
                            </v-btn>
                          </template>
                          <template v-else-if="isURL(ap[item.field_name])">
                            <a :href="isURL(ap[item.field_name])">{{
                              ap[item.field_name]
                            }}</a>
                          </template>
                          <template v-else>
                            {{ ap[item.field_name] }}
                            <v-btn
                              color="info"
                              icon
                              :href="
                                route(
                                  `${item.portal_field_route.module.toLowerCase()}.linked`,
                                  {
                                    field:
                                      item.portal_field_route.link_field_id,
                                    value: ap[item.field_name],
                                  }
                                )
                              "
                              v-if="item.portal_field_route"
                            >
                              <v-icon>mdi-link-variant</v-icon>
                            </v-btn>
                          </template>
                        </div>
                      </div>
                    </template>
                  </v-data-iterator>
                </v-col>
              </template>
            </v-row>
          </div>
          <div v-else-if="block.module === 'Organisationen'">
            <template v-if="data['organisation']">
              <v-row>
                <template v-for="(column, index) in block.panel_columns">
                  <v-col
                    cols="12"
                    :lg="12 / block.panel_columns.length"
                    v-if="column.portal_fields.length > 0"
                    :key="index"
                  >
                    <v-data-iterator
                      disable-filtering
                      disable-pagination
                      disable-sort
                      hide-default-footer
                      :items="column.portal_fields"
                    >
                      <template v-slot:default="{ items }">
                        <div
                          v-for="item in items"
                          :key="item.id"
                          class="fields-item px-4 my-1"
                        >
                          <div class="field-title py-2">
                            {{ item.field_name }}
                            <template v-if="item.description">
                              <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn
                                    color="info"
                                    icon
                                    v-bind="attrs"
                                    v-on="on"
                                  >
                                    <v-icon>mdi-information-outline</v-icon>
                                  </v-btn>
                                </template>
                                <span>{{ item.description }}</span>
                              </v-tooltip>
                            </template>
                          </div>
                          <div class="field-divider"></div>
                          <div class="field-value py-2">
                            <template
                              v-if="
                                !(item.field_name in data['organisation']) ||
                                !data['organisation'][item.field_name]
                              "
                            >
                              -
                            </template>
                            <template v-else-if="item.data_type == 'date'">
                              {{
                                data["organisation"][item.field_name]
                                  | formatDate
                              }}
                              <v-btn
                                color="info"
                                icon
                                :href="
                                  route(
                                    `${item.portal_field_route.module.toLowerCase()}.linked`,
                                    {
                                      field:
                                        item.portal_field_route.link_field_id,
                                      value:
                                        data['organisation'][item.field_name],
                                    }
                                  )
                                "
                                v-if="item.portal_field_route"
                              >
                                <v-icon>mdi-link-variant</v-icon>
                              </v-btn>
                            </template>
                            <template v-else-if="item.data_type == 'number'">
                              {{
                                data["organisation"][item.field_name]
                                  | formatNumber
                              }}
                              <v-btn
                                color="info"
                                icon
                                :href="
                                  route(
                                    `${item.portal_field_route.module.toLowerCase()}.linked`,
                                    {
                                      field:
                                        item.portal_field_route.link_field_id,
                                      value:
                                        data['organisation'][item.field_name],
                                    }
                                  )
                                "
                                v-if="item.portal_field_route"
                              >
                                <v-icon>mdi-link-variant</v-icon>
                              </v-btn>
                            </template>
                            <template
                              v-else-if="item.data_type == 'coordinate'"
                            >
                              {{
                                data["organisation"][item.field_name]
                                  | formatCoordinate
                              }}
                              <v-btn
                                color="info"
                                icon
                                :href="
                                  route(
                                    `${item.portal_field_route.module.toLowerCase()}.linked`,
                                    {
                                      field:
                                        item.portal_field_route.link_field_id,
                                      value:
                                        data['organisation'][item.field_name],
                                    }
                                  )
                                "
                                v-if="item.portal_field_route"
                              >
                                <v-icon>mdi-link-variant</v-icon>
                              </v-btn>
                            </template>
                            <template
                              v-else-if="
                                isURL(data['organisation'][item.field_name])
                              "
                            >
                              <a
                                :href="
                                  isURL(data['organisation'][item.field_name])
                                "
                                >{{ data["organisation"][item.field_name] }}</a
                              >
                            </template>
                            <template v-else>
                              {{ data["organisation"][item.field_name] }}
                              <v-btn
                                color="info"
                                icon
                                :href="
                                  route(
                                    `${item.portal_field_route.module.toLowerCase()}.linked`,
                                    {
                                      field:
                                        item.portal_field_route.link_field_id,
                                      value:
                                        data['organisation'][item.field_name],
                                    }
                                  )
                                "
                                v-if="item.portal_field_route"
                              >
                                <v-icon>mdi-link-variant</v-icon>
                              </v-btn>
                            </template>
                          </div>
                        </div>
                      </template>
                    </v-data-iterator>
                  </v-col>
                </template>
              </v-row>
            </template>
          </div>
          <div v-else-if="block.module.includes('Ansprechpartner')">
            <v-row
              v-for="(ap, index) in getAnsprechpartnerArray(
                block.module
                  .match(/(?![Ansprechpartner ])[\s\S]*/)[0]
                  .toLowerCase()
              )"
              :key="index"
            >
              <v-col cols="12">
                <h3>Ansprechpartner {{ index + 1 }}</h3>
              </v-col>
              <template v-for="(column, index) in block.panel_columns">
                <v-col
                  cols="12"
                  :lg="12 / block.panel_columns.length"
                  v-if="column.portal_fields.length > 0"
                  :key="index"
                >
                  <v-data-iterator
                    disable-filtering
                    disable-pagination
                    disable-sort
                    hide-default-footer
                    :items="column.portal_fields"
                  >
                    <template v-slot:default="{ items }">
                      <div
                        v-for="item in items"
                        :key="item.id"
                        class="fields-item px-4 my-1"
                      >
                        <div class="field-title py-2">
                          {{ item.field_name }}
                          <template v-if="item.description">
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                  color="info"
                                  icon
                                  v-bind="attrs"
                                  v-on="on"
                                >
                                  <v-icon>mdi-information-outline</v-icon>
                                </v-btn>
                              </template>
                              <span>{{ item.description }}</span>
                            </v-tooltip>
                          </template>
                        </div>
                        <div class="field-divider"></div>
                        <div class="field-value py-2">
                          <template
                            v-if="
                              !(item.field_name in ap) || !ap[item.field_name]
                            "
                          >
                            -
                          </template>
                          <template v-else-if="item.data_type == 'date'">
                            {{ ap[item.field_name] | formatDate }}
                            <v-btn
                              color="info"
                              icon
                              :href="
                                route(
                                  `${item.portal_field_route.module.toLowerCase()}.linked`,
                                  {
                                    field:
                                      item.portal_field_route.link_field_id,
                                    value: ap[item.field_name],
                                  }
                                )
                              "
                              v-if="item.portal_field_route"
                            >
                              <v-icon>mdi-link-variant</v-icon>
                            </v-btn>
                          </template>
                          <template v-else-if="item.data_type == 'number'">
                            {{ ap[item.field_name] | formatNumber }}
                            <v-btn
                              color="info"
                              icon
                              :href="
                                route(
                                  `${item.portal_field_route.module.toLowerCase()}.linked`,
                                  {
                                    field:
                                      item.portal_field_route.link_field_id,
                                    value: ap[item.field_name],
                                  }
                                )
                              "
                              v-if="item.portal_field_route"
                            >
                              <v-icon>mdi-link-variant</v-icon>
                            </v-btn>
                          </template>
                          <template v-else-if="item.data_type == 'coordinate'">
                            {{ ap[item.field_name] | formatCoordinate }}
                            <v-btn
                              color="info"
                              icon
                              :href="
                                route(
                                  `${item.portal_field_route.module.toLowerCase()}.linked`,
                                  {
                                    field:
                                      item.portal_field_route.link_field_id,
                                    value: ap[item.field_name],
                                  }
                                )
                              "
                              v-if="item.portal_field_route"
                            >
                              <v-icon>mdi-link-variant</v-icon>
                            </v-btn>
                          </template>
                          <template v-else-if="isURL(ap[item.field_name])">
                            <a :href="isURL(ap[item.field_name])">{{
                              ap[item.field_name]
                            }}</a>
                          </template>
                          <template v-else>
                            {{ ap[item.field_name] }}
                            <v-btn
                              color="info"
                              icon
                              :href="
                                route(
                                  `${item.portal_field_route.module.toLowerCase()}.linked`,
                                  {
                                    field:
                                      item.portal_field_route.link_field_id,
                                    value: ap[item.field_name],
                                  }
                                )
                              "
                              v-if="item.portal_field_route"
                            >
                              <v-icon>mdi-link-variant</v-icon>
                            </v-btn>
                          </template>
                        </div>
                      </div>
                    </template>
                  </v-data-iterator>
                </v-col>
              </template>
            </v-row>
          </div>
          <div v-else-if="block.module.includes('Windparks')">
            <template v-if="data['windparks']">
              <v-row v-for="(wp, index) in data['windparks']" :key="index">
                <v-col cols="12">
                  <h3>Windpark {{ index + 1 }}</h3>
                </v-col>
                <template v-for="(column, index) in block.panel_columns">
                  <v-col
                    cols="12"
                    :lg="12 / block.panel_columns.length"
                    v-if="column.portal_fields.length > 0"
                    :key="index"
                  >
                    <v-data-iterator
                      disable-filtering
                      disable-pagination
                      disable-sort
                      hide-default-footer
                      :items="column.portal_fields"
                    >
                      <template v-slot:default="{ items }">
                        <div
                          v-for="item in items"
                          :key="item.id"
                          class="fields-item px-4 my-1"
                        >
                          <div class="field-title py-2">
                            {{ item.field_name }}
                            <template v-if="item.description">
                              <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn
                                    color="info"
                                    icon
                                    v-bind="attrs"
                                    v-on="on"
                                  >
                                    <v-icon>mdi-information-outline</v-icon>
                                  </v-btn>
                                </template>
                                <span>{{ item.description }}</span>
                              </v-tooltip>
                            </template>
                          </div>
                          <div class="field-divider"></div>
                          <div class="field-value py-2">
                            <template
                              v-if="
                                !(item.field_name in wp) || !wp[item.field_name]
                              "
                            >
                              -
                            </template>
                            <template v-else-if="item.data_type == 'date'">
                              {{ wp[item.field_name] | formatDate }}
                              <v-btn
                                color="info"
                                icon
                                :href="
                                  route(
                                    `${item.portal_field_route.module.toLowerCase()}.linked`,
                                    {
                                      field:
                                        item.portal_field_route.link_field_id,
                                      value: wp[item.field_name],
                                    }
                                  )
                                "
                                v-if="item.portal_field_route"
                              >
                                <v-icon>mdi-link-variant</v-icon>
                              </v-btn>
                            </template>
                            <template v-else-if="item.data_type == 'number'">
                              {{ wp[item.field_name] | formatNumber }}
                              <v-btn
                                color="info"
                                icon
                                :href="
                                  route(
                                    `${item.portal_field_route.module.toLowerCase()}.linked`,
                                    {
                                      field:
                                        item.portal_field_route.link_field_id,
                                      value: wp[item.field_name],
                                    }
                                  )
                                "
                                v-if="item.portal_field_route"
                              >
                                <v-icon>mdi-link-variant</v-icon>
                              </v-btn>
                            </template>
                            <template
                              v-else-if="item.data_type == 'coordinate'"
                            >
                              {{ wp[item.field_name] | formatCoordinate }}
                              <v-btn
                                color="info"
                                icon
                                :href="
                                  route(
                                    `${item.portal_field_route.module.toLowerCase()}.linked`,
                                    {
                                      field:
                                        item.portal_field_route.link_field_id,
                                      value: wp[item.field_name],
                                    }
                                  )
                                "
                                v-if="item.portal_field_route"
                              >
                                <v-icon>mdi-link-variant</v-icon>
                              </v-btn>
                            </template>
                            <template v-else-if="isURL(wp[item.field_name])">
                              <a :href="isURL(wp[item.field_name])">{{
                                wp[item.field_name]
                              }}</a>
                            </template>
                            <template v-else>
                              {{ wp[item.field_name] }}
                              <v-btn
                                color="info"
                                icon
                                :href="
                                  route(
                                    `${item.portal_field_route.module.toLowerCase()}.linked`,
                                    {
                                      field:
                                        item.portal_field_route.link_field_id,
                                      value: wp[item.field_name],
                                    }
                                  )
                                "
                                v-if="item.portal_field_route"
                              >
                                <v-icon>mdi-link-variant</v-icon>
                              </v-btn>
                            </template>
                          </div>
                        </div>
                      </template>
                    </v-data-iterator>
                  </v-col>
                </template>
              </v-row>
            </template>
            <template v-if="data['windpark']">
              <v-row>
                <template v-for="(column, index) in block.panel_columns">
                  <v-col
                    cols="12"
                    :lg="12 / block.panel_columns.length"
                    v-if="column.portal_fields.length > 0"
                    :key="index"
                  >
                    <v-data-iterator
                      disable-filtering
                      disable-pagination
                      disable-sort
                      hide-default-footer
                      :items="column.portal_fields"
                    >
                      <template v-slot:default="{ items }">
                        <div
                          v-for="item in items"
                          :key="item.id"
                          class="fields-item px-4 my-1"
                        >
                          <div class="field-title py-2">
                            {{ item.field_name }}
                            <template v-if="item.description">
                              <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn
                                    color="info"
                                    icon
                                    v-bind="attrs"
                                    v-on="on"
                                  >
                                    <v-icon>mdi-information-outline</v-icon>
                                  </v-btn>
                                </template>
                                <span>{{ item.description }}</span>
                              </v-tooltip>
                            </template>
                          </div>
                          <div class="field-divider"></div>
                          <div class="field-value py-2">
                            <template
                              v-if="
                                !(item.field_name in data['windpark']) ||
                                !data['windpark'][item.field_name]
                              "
                            >
                              -
                            </template>
                            <template v-else-if="item.data_type == 'date'">
                              {{
                                data["windpark"][item.field_name] | formatDate
                              }}
                              <v-btn
                                color="info"
                                icon
                                :href="
                                  route(
                                    `${item.portal_field_route.module.toLowerCase()}.linked`,
                                    {
                                      field:
                                        item.portal_field_route.link_field_id,
                                      value: data['windpark'][item.field_name],
                                    }
                                  )
                                "
                                v-if="item.portal_field_route"
                              >
                                <v-icon>mdi-link-variant</v-icon>
                              </v-btn>
                            </template>
                            <template v-else-if="item.data_type == 'number'">
                              {{
                                data["windpark"][item.field_name] | formatNumber
                              }}
                              <v-btn
                                color="info"
                                icon
                                :href="
                                  route(
                                    `${item.portal_field_route.module.toLowerCase()}.linked`,
                                    {
                                      field:
                                        item.portal_field_route.link_field_id,
                                      value: data['windpark'][item.field_name],
                                    }
                                  )
                                "
                                v-if="item.portal_field_route"
                              >
                                <v-icon>mdi-link-variant</v-icon>
                              </v-btn>
                            </template>
                            <template
                              v-else-if="item.data_type == 'coordinate'"
                            >
                              {{
                                data["windpark"][item.field_name]
                                  | formatCoordinate
                              }}
                              <v-btn
                                color="info"
                                icon
                                :href="
                                  route(
                                    `${item.portal_field_route.module.toLowerCase()}.linked`,
                                    {
                                      field:
                                        item.portal_field_route.link_field_id,
                                      value: data['windpark'][item.field_name],
                                    }
                                  )
                                "
                                v-if="item.portal_field_route"
                              >
                                <v-icon>mdi-link-variant</v-icon>
                              </v-btn>
                            </template>
                            <template
                              v-else-if="
                                isURL(data['windpark'][item.field_name])
                              "
                            >
                              <a
                                :href="isURL(data['windpark'][item.field_name])"
                                >{{ data["windpark"][item.field_name] }}</a
                              >
                            </template>
                            <template v-else>
                              {{ data["windpark"][item.field_name] }}
                              <v-btn
                                color="info"
                                icon
                                :href="
                                  route(
                                    `${item.portal_field_route.module.toLowerCase()}.linked`,
                                    {
                                      field:
                                        item.portal_field_route.link_field_id,
                                      value: data['windpark'][item.field_name],
                                    }
                                  )
                                "
                                v-if="item.portal_field_route"
                              >
                                <v-icon>mdi-link-variant</v-icon>
                              </v-btn>
                            </template>
                          </div>
                        </div>
                      </template>
                    </v-data-iterator>
                  </v-col>
                </template>
              </v-row>
            </template>
          </div>
          <div v-else-if="block.module.includes('Photovoltaikparks')">
            <template v-if="data['pv_parks']">
              <v-row v-for="(pv_park, index) in data['pv_parks']" :key="index">
                <v-col cols="12">
                  <h3>Windpark {{ index + 1 }}</h3>
                </v-col>
                <template v-for="(column, index) in block.panel_columns">
                  <v-col
                    cols="12"
                    :lg="12 / block.panel_columns.length"
                    v-if="column.portal_fields.length > 0"
                    :key="index"
                  >
                    <v-data-iterator
                      disable-filtering
                      disable-pagination
                      disable-sort
                      hide-default-footer
                      :items="column.portal_fields"
                    >
                      <template v-slot:default="{ items }">
                        <div
                          v-for="item in items"
                          :key="item.id"
                          class="fields-item px-4 my-1"
                        >
                          <div class="field-title py-2">
                            {{ item.field_name }}
                            <template v-if="item.description">
                              <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn
                                    color="info"
                                    icon
                                    v-bind="attrs"
                                    v-on="on"
                                  >
                                    <v-icon>mdi-information-outline</v-icon>
                                  </v-btn>
                                </template>
                                <span>{{ item.description }}</span>
                              </v-tooltip>
                            </template>
                          </div>
                          <div class="field-divider"></div>
                          <div class="field-value py-2">
                            <template
                              v-if="
                                !(item.field_name in pv_park) ||
                                !pv_park[item.field_name]
                              "
                            >
                              -
                            </template>
                            <template v-else-if="item.data_type == 'date'">
                              {{ pv_park[item.field_name] | formatDate }}
                              <v-btn
                                color="info"
                                icon
                                :href="
                                  route(
                                    `${item.portal_field_route.module.toLowerCase()}.linked`,
                                    {
                                      field:
                                        item.portal_field_route.link_field_id,
                                      value: pv_park[item.field_name],
                                    }
                                  )
                                "
                                v-if="item.portal_field_route"
                              >
                                <v-icon>mdi-link-variant</v-icon>
                              </v-btn>
                            </template>
                            <template v-else-if="item.data_type == 'number'">
                              {{ pv_park[item.field_name] | formatNumber }}
                              <v-btn
                                color="info"
                                icon
                                :href="
                                  route(
                                    `${item.portal_field_route.module.toLowerCase()}.linked`,
                                    {
                                      field:
                                        item.portal_field_route.link_field_id,
                                      value: pv_park[item.field_name],
                                    }
                                  )
                                "
                                v-if="item.portal_field_route"
                              >
                                <v-icon>mdi-link-variant</v-icon>
                              </v-btn>
                            </template>
                            <template
                              v-else-if="item.data_type == 'coordinate'"
                            >
                              {{ pv_park[item.field_name] | formatCoordinate }}
                              <v-btn
                                color="info"
                                icon
                                :href="
                                  route(
                                    `${item.portal_field_route.module.toLowerCase()}.linked`,
                                    {
                                      field:
                                        item.portal_field_route.link_field_id,
                                      value: pv_park[item.field_name],
                                    }
                                  )
                                "
                                v-if="item.portal_field_route"
                              >
                                <v-icon>mdi-link-variant</v-icon>
                              </v-btn>
                            </template>
                            <template
                              v-else-if="isURL(pv_park[item.field_name])"
                            >
                              <a :href="isURL(pv_park[item.field_name])">{{
                                pv_park[item.field_name]
                              }}</a>
                            </template>
                            <template v-else>
                              {{ pv_park[item.field_name] }}
                              <v-btn
                                color="info"
                                icon
                                :href="
                                  route(
                                    `${item.portal_field_route.module.toLowerCase()}.linked`,
                                    {
                                      field:
                                        item.portal_field_route.link_field_id,
                                      value: pv_park[item.field_name],
                                    }
                                  )
                                "
                                v-if="item.portal_field_route"
                              >
                                <v-icon>mdi-link-variant</v-icon>
                              </v-btn>
                            </template>
                          </div>
                        </div>
                      </template>
                    </v-data-iterator>
                  </v-col>
                </template>
              </v-row>
            </template>
            <template v-if="data['pv_park']">
              <v-row>
                <template v-for="(column, index) in block.panel_columns">
                  <v-col
                    cols="12"
                    :lg="12 / block.panel_columns.length"
                    v-if="column.portal_fields.length > 0"
                    :key="index"
                  >
                    <v-data-iterator
                      disable-filtering
                      disable-pagination
                      disable-sort
                      hide-default-footer
                      :items="column.portal_fields"
                    >
                      <template v-slot:default="{ items }">
                        <div
                          v-for="item in items"
                          :key="item.id"
                          class="fields-item px-4 my-1"
                        >
                          <div class="field-title py-2">
                            {{ item.field_name }}
                            <template v-if="item.description">
                              <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn
                                    color="info"
                                    icon
                                    v-bind="attrs"
                                    v-on="on"
                                  >
                                    <v-icon>mdi-information-outline</v-icon>
                                  </v-btn>
                                </template>
                                <span>{{ item.description }}</span>
                              </v-tooltip>
                            </template>
                          </div>
                          <div class="field-divider"></div>
                          <div class="field-value py-2">
                            <template
                              v-if="
                                !(item.field_name in data['pv_park']) ||
                                !data['pv_park'][item.field_name]
                              "
                            >
                              -
                            </template>
                            <template v-else-if="item.data_type == 'date'">
                              {{
                                data["pv_park"][item.field_name] | formatDate
                              }}
                              <v-btn
                                color="info"
                                icon
                                :href="
                                  route(
                                    `${item.portal_field_route.module.toLowerCase()}.linked`,
                                    {
                                      field:
                                        item.portal_field_route.link_field_id,
                                      value: data['pv_park'][item.field_name],
                                    }
                                  )
                                "
                                v-if="item.portal_field_route"
                              >
                                <v-icon>mdi-link-variant</v-icon>
                              </v-btn>
                            </template>
                            <template v-else-if="item.data_type == 'number'">
                              {{
                                data["pv_park"][item.field_name] | formatNumber
                              }}
                              <v-btn
                                color="info"
                                icon
                                :href="
                                  route(
                                    `${item.portal_field_route.module.toLowerCase()}.linked`,
                                    {
                                      field:
                                        item.portal_field_route.link_field_id,
                                      value: data['pv_park'][item.field_name],
                                    }
                                  )
                                "
                                v-if="item.portal_field_route"
                              >
                                <v-icon>mdi-link-variant</v-icon>
                              </v-btn>
                            </template>
                            <template
                              v-else-if="item.data_type == 'coordinate'"
                            >
                              {{
                                data["pv_park"][item.field_name]
                                  | formatCoordinate
                              }}
                              <v-btn
                                color="info"
                                icon
                                :href="
                                  route(
                                    `${item.portal_field_route.module.toLowerCase()}.linked`,
                                    {
                                      field:
                                        item.portal_field_route.link_field_id,
                                      value: data['pv_park'][item.field_name],
                                    }
                                  )
                                "
                                v-if="item.portal_field_route"
                              >
                                <v-icon>mdi-link-variant</v-icon>
                              </v-btn>
                            </template>
                            <template
                              v-else-if="
                                isURL(data['pv_park'][item.field_name])
                              "
                            >
                              <a
                                :href="isURL(data['pv_park'][item.field_name])"
                                >{{ data["pv_park"][item.field_name] }}</a
                              >
                            </template>
                            <template v-else>
                              {{ data["pv_park"][item.field_name] }}
                              <v-btn
                                color="info"
                                icon
                                :href="
                                  route(
                                    `${item.portal_field_route.module.toLowerCase()}.linked`,
                                    {
                                      field:
                                        item.portal_field_route.link_field_id,
                                      value: data['pv_park'][item.field_name],
                                    }
                                  )
                                "
                                v-if="item.portal_field_route"
                              >
                                <v-icon>mdi-link-variant</v-icon>
                              </v-btn>
                            </template>
                          </div>
                        </div>
                      </template>
                    </v-data-iterator>
                  </v-col>
                </template>
              </v-row>
            </template>
          </div>
          <div v-else-if="block.module.includes('Brennstoffe')">
            <template v-if="data['brennstoffe']">
              <v-row
                v-for="(brennstoff, index) in data['brennstoffe']"
                :key="index"
              >
                <v-col cols="12">
                  <h3>Windpark {{ index + 1 }}</h3>
                </v-col>
                <template v-for="(column, index) in block.panel_columns">
                  <v-col
                    cols="12"
                    :lg="12 / block.panel_columns.length"
                    v-if="column.portal_fields.length > 0"
                    :key="index"
                  >
                    <v-data-iterator
                      disable-filtering
                      disable-pagination
                      disable-sort
                      hide-default-footer
                      :items="column.portal_fields"
                    >
                      <template v-slot:default="{ items }">
                        <div
                          v-for="item in items"
                          :key="item.id"
                          class="fields-item px-4 my-1"
                        >
                          <div class="field-title py-2">
                            {{ item.field_name }}
                            <template v-if="item.description">
                              <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn
                                    color="info"
                                    icon
                                    v-bind="attrs"
                                    v-on="on"
                                  >
                                    <v-icon>mdi-information-outline</v-icon>
                                  </v-btn>
                                </template>
                                <span>{{ item.description }}</span>
                              </v-tooltip>
                            </template>
                          </div>
                          <div class="field-divider"></div>
                          <div class="field-value py-2">
                            <template
                              v-if="
                                !(item.field_name in brennstoff) ||
                                !brennstoff[item.field_name]
                              "
                            >
                              -
                            </template>
                            <template v-else-if="item.data_type == 'date'">
                              {{ brennstoff[item.field_name] | formatDate }}
                              <v-btn
                                color="info"
                                icon
                                :href="
                                  route(
                                    `${item.portal_field_route.module.toLowerCase()}.linked`,
                                    {
                                      field:
                                        item.portal_field_route.link_field_id,
                                      value: brennstoff[item.field_name],
                                    }
                                  )
                                "
                                v-if="item.portal_field_route"
                              >
                                <v-icon>mdi-link-variant</v-icon>
                              </v-btn>
                            </template>
                            <template v-else-if="item.data_type == 'number'">
                              {{ brennstoff[item.field_name] | formatNumber }}
                              <v-btn
                                color="info"
                                icon
                                :href="
                                  route(
                                    `${item.portal_field_route.module.toLowerCase()}.linked`,
                                    {
                                      field:
                                        item.portal_field_route.link_field_id,
                                      value: brennstoff[item.field_name],
                                    }
                                  )
                                "
                                v-if="item.portal_field_route"
                              >
                                <v-icon>mdi-link-variant</v-icon>
                              </v-btn>
                            </template>
                            <template
                              v-else-if="item.data_type == 'coordinate'"
                            >
                              {{
                                brennstoff[item.field_name] | formatCoordinate
                              }}
                              <v-btn
                                color="info"
                                icon
                                :href="
                                  route(
                                    `${item.portal_field_route.module.toLowerCase()}.linked`,
                                    {
                                      field:
                                        item.portal_field_route.link_field_id,
                                      value: brennstoff[item.field_name],
                                    }
                                  )
                                "
                                v-if="item.portal_field_route"
                              >
                                <v-icon>mdi-link-variant</v-icon>
                              </v-btn>
                            </template>
                            <template
                              v-else-if="isURL(brennstoff[item.field_name])"
                            >
                              <a :href="isURL(brennstoff[item.field_name])">{{
                                brennstoff[item.field_name]
                              }}</a>
                            </template>
                            <template v-else>
                              {{ brennstoff[item.field_name] }}
                              <v-btn
                                color="info"
                                icon
                                :href="
                                  route(
                                    `${item.portal_field_route.module.toLowerCase()}.linked`,
                                    {
                                      field:
                                        item.portal_field_route.link_field_id,
                                      value: brennstoff[item.field_name],
                                    }
                                  )
                                "
                                v-if="item.portal_field_route"
                              >
                                <v-icon>mdi-link-variant</v-icon>
                              </v-btn>
                            </template>
                          </div>
                        </div>
                      </template>
                    </v-data-iterator>
                  </v-col>
                </template>
              </v-row>
            </template>
            <template v-if="data['brennstoff']">
              <v-row>
                <template v-for="(column, index) in block.panel_columns">
                  <v-col
                    cols="12"
                    :lg="12 / block.panel_columns.length"
                    v-if="column.portal_fields.length > 0"
                    :key="index"
                  >
                    <v-data-iterator
                      disable-filtering
                      disable-pagination
                      disable-sort
                      hide-default-footer
                      :items="column.portal_fields"
                    >
                      <template v-slot:default="{ items }">
                        <div
                          v-for="item in items"
                          :key="item.id"
                          class="fields-item px-4 my-1"
                        >
                          <div class="field-title py-2">
                            {{ item.field_name }}
                            <template v-if="item.description">
                              <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn
                                    color="info"
                                    icon
                                    v-bind="attrs"
                                    v-on="on"
                                  >
                                    <v-icon>mdi-information-outline</v-icon>
                                  </v-btn>
                                </template>
                                <span>{{ item.description }}</span>
                              </v-tooltip>
                            </template>
                          </div>
                          <div class="field-divider"></div>
                          <div class="field-value py-2">
                            <template
                              v-if="
                                !(item.field_name in data['brennstoff']) ||
                                !data['brennstoff'][item.field_name]
                              "
                            >
                              -
                            </template>
                            <template v-else-if="item.data_type == 'date'">
                              {{
                                data["brennstoff"][item.field_name] | formatDate
                              }}
                              <v-btn
                                color="info"
                                icon
                                :href="
                                  route(
                                    `${item.portal_field_route.module.toLowerCase()}.linked`,
                                    {
                                      field:
                                        item.portal_field_route.link_field_id,
                                      value:
                                        data['brennstoff'][item.field_name],
                                    }
                                  )
                                "
                                v-if="item.portal_field_route"
                              >
                                <v-icon>mdi-link-variant</v-icon>
                              </v-btn>
                            </template>
                            <template v-else-if="item.data_type == 'number'">
                              {{
                                data["brennstoff"][item.field_name]
                                  | formatNumber
                              }}
                              <v-btn
                                color="info"
                                icon
                                :href="
                                  route(
                                    `${item.portal_field_route.module.toLowerCase()}.linked`,
                                    {
                                      field:
                                        item.portal_field_route.link_field_id,
                                      value:
                                        data['brennstoff'][item.field_name],
                                    }
                                  )
                                "
                                v-if="item.portal_field_route"
                              >
                                <v-icon>mdi-link-variant</v-icon>
                              </v-btn>
                            </template>
                            <template
                              v-else-if="item.data_type == 'coordinate'"
                            >
                              {{
                                data["brennstoff"][item.field_name]
                                  | formatCoordinate
                              }}
                              <v-btn
                                color="info"
                                icon
                                :href="
                                  route(
                                    `${item.portal_field_route.module.toLowerCase()}.linked`,
                                    {
                                      field:
                                        item.portal_field_route.link_field_id,
                                      value:
                                        data['brennstoff'][item.field_name],
                                    }
                                  )
                                "
                                v-if="item.portal_field_route"
                              >
                                <v-icon>mdi-link-variant</v-icon>
                              </v-btn>
                            </template>
                            <template
                              v-else-if="
                                isURL(data['brennstoff'][item.field_name])
                              "
                            >
                              <a
                                :href="
                                  isURL(data['brennstoff'][item.field_name])
                                "
                                >{{ data["brennstoff"][item.field_name] }}</a
                              >
                            </template>
                            <template v-else>
                              {{ data["brennstoff"][item.field_name] }}
                              <v-btn
                                color="info"
                                icon
                                :href="
                                  route(
                                    `${item.portal_field_route.module.toLowerCase()}.linked`,
                                    {
                                      field:
                                        item.portal_field_route.link_field_id,
                                      value:
                                        data['brennstoff'][item.field_name],
                                    }
                                  )
                                "
                                v-if="item.portal_field_route"
                              >
                                <v-icon>mdi-link-variant</v-icon>
                              </v-btn>
                            </template>
                          </div>
                        </div>
                      </template>
                    </v-data-iterator>
                  </v-col>
                </template>
              </v-row>
            </template>
          </div>
          <div v-else-if="block.module.includes('Windenergieanlagen')">
            <v-row v-for="(wea, index) in data['wea']" :key="index">
              <v-col cols="12">
                <h3>Windenergieanlage {{ index + 1 }}</h3>
              </v-col>
              <template v-for="(column, index) in block.panel_columns">
                <v-col
                  cols="12"
                  :lg="12 / block.panel_columns.length"
                  v-if="column.portal_fields.length > 0"
                  :key="index"
                >
                  <v-data-iterator
                    disable-filtering
                    disable-pagination
                    disable-sort
                    hide-default-footer
                    :items="column.portal_fields"
                  >
                    <template v-slot:default="{ items }">
                      <div
                        v-for="item in items"
                        :key="item.id"
                        class="fields-item px-4 my-1"
                      >
                        <div class="field-title py-2">
                          {{ item.field_name }}
                          <template v-if="item.description">
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                  color="info"
                                  icon
                                  v-bind="attrs"
                                  v-on="on"
                                >
                                  <v-icon>mdi-information-outline</v-icon>
                                </v-btn>
                              </template>
                              <span>{{ item.description }}</span>
                            </v-tooltip>
                          </template>
                        </div>
                        <div class="field-divider"></div>
                        <div class="field-value py-2">
                          <template v-if="!wea[item.field_name]"> - </template>
                          <template v-else-if="item.data_type == 'date'">
                            {{ wea[item.field_name] | formatDate }}
                            <v-btn
                              color="info"
                              icon
                              :href="
                                route(
                                  `${item.portal_field_route.module.toLowerCase()}.linked`,
                                  {
                                    field:
                                      item.portal_field_route.link_field_id,
                                    value: wea[item.field_name],
                                  }
                                )
                              "
                              v-if="item.portal_field_route"
                            >
                              <v-icon>mdi-link-variant</v-icon>
                            </v-btn>
                          </template>
                          <template v-else-if="item.data_type == 'number'">
                            {{ wea[item.field_name] | formatNumber }}
                            <v-btn
                              color="info"
                              icon
                              :href="
                                route(
                                  `${item.portal_field_route.module.toLowerCase()}.linked`,
                                  {
                                    field:
                                      item.portal_field_route.link_field_id,
                                    value: wea[item.field_name],
                                  }
                                )
                              "
                              v-if="item.portal_field_route"
                            >
                              <v-icon>mdi-link-variant</v-icon>
                            </v-btn>
                          </template>
                          <template v-else-if="item.data_type == 'coordinate'">
                            {{ wea[item.field_name] | formatCoordinate }}
                            <v-btn
                              color="info"
                              icon
                              :href="
                                route(
                                  `${item.portal_field_route.module.toLowerCase()}.linked`,
                                  {
                                    field:
                                      item.portal_field_route.link_field_id,
                                    value: wea[item.field_name],
                                  }
                                )
                              "
                              v-if="item.portal_field_route"
                            >
                              <v-icon>mdi-link-variant</v-icon>
                            </v-btn>
                          </template>
                          <template v-else-if="isURL(wea[item.field_name])">
                            <a :href="isURL(wea[item.field_name])">{{
                              wea[item.field_name]
                            }}</a>
                          </template>
                          <template v-else>
                            {{ wea[item.field_name] }}
                            <v-btn
                              color="info"
                              icon
                              :href="
                                route(
                                  `${item.portal_field_route.module.toLowerCase()}.linked`,
                                  {
                                    field:
                                      item.portal_field_route.link_field_id,
                                    value: wea[item.field_name],
                                  }
                                )
                              "
                              v-if="item.portal_field_route"
                            >
                              <v-icon>mdi-link-variant</v-icon>
                            </v-btn>
                          </template>
                        </div>
                      </div>
                    </template>
                  </v-data-iterator>
                </v-col>
              </template>
            </v-row>
          </div>
          <div v-else-if="block.module.includes('Photovoltaikanlagen')">
            <v-row v-for="(pva, index) in data['pva']" :key="index">
              <v-col cols="12">
                <h3>Photovoltaikanlage {{ index + 1 }}</h3>
              </v-col>
              <template v-for="(column, index) in block.panel_columns">
                <v-col
                  cols="12"
                  :lg="12 / block.panel_columns.length"
                  v-if="column.portal_fields.length > 0"
                  :key="index"
                >
                  <v-data-iterator
                    disable-filtering
                    disable-pagination
                    disable-sort
                    hide-default-footer
                    :items="column.portal_fields"
                  >
                    <template v-slot:default="{ items }">
                      <div
                        v-for="item in items"
                        :key="item.id"
                        class="fields-item px-4 my-1"
                      >
                        <div class="field-title py-2">
                          {{ item.field_name }}
                        </div>
                        <div class="field-divider"></div>
                        <div class="field-value py-2">
                          <template
                            v-if="
                              !(item.field_name in pva) || !pva[item.field_name]
                            "
                          >
                            -
                          </template>
                          <template v-else-if="item.data_type == 'date'">
                            {{ pva[item.field_name] | formatDate }}
                            <v-btn
                              color="info"
                              icon
                              :href="
                                route(
                                  `${item.portal_field_route.module.toLowerCase()}.linked`,
                                  {
                                    field:
                                      item.portal_field_route.link_field_id,
                                    value: pva[item.field_name],
                                  }
                                )
                              "
                              v-if="item.portal_field_route"
                            >
                              <v-icon>mdi-link-variant</v-icon>
                            </v-btn>
                          </template>
                          <template v-else-if="item.data_type == 'number'">
                            {{ pva[item.field_name] | formatNumber }}
                            <v-btn
                              color="info"
                              icon
                              :href="
                                route(
                                  `${item.portal_field_route.module.toLowerCase()}.linked`,
                                  {
                                    field:
                                      item.portal_field_route.link_field_id,
                                    value: pva[item.field_name],
                                  }
                                )
                              "
                              v-if="item.portal_field_route"
                            >
                              <v-icon>mdi-link-variant</v-icon>
                            </v-btn>
                          </template>
                          <template v-else-if="item.data_type == 'coordinate'">
                            {{ pva[item.field_name] | formatCoordinate }}
                            <v-btn
                              color="info"
                              icon
                              :href="
                                route(
                                  `${item.portal_field_route.module.toLowerCase()}.linked`,
                                  {
                                    field:
                                      item.portal_field_route.link_field_id,
                                    value: pva[item.field_name],
                                  }
                                )
                              "
                              v-if="item.portal_field_route"
                            >
                              <v-icon>mdi-link-variant</v-icon>
                            </v-btn>
                          </template>
                          <template v-else-if="isURL(pva[item.field_name])">
                            <a :href="isURL(pva[item.field_name])">{{
                              pva[item.field_name]
                            }}</a>
                          </template>
                          <template v-else>
                            {{ pva[item.field_name] }}
                            <v-btn
                              color="info"
                              icon
                              :href="
                                route(
                                  `${item.portal_field_route.module.toLowerCase()}.linked`,
                                  {
                                    field:
                                      item.portal_field_route.link_field_id,
                                    value: pva[item.field_name],
                                  }
                                )
                              "
                              v-if="item.portal_field_route"
                            >
                              <v-icon>mdi-link-variant</v-icon>
                            </v-btn>
                          </template>
                        </div>
                      </div>
                    </template>
                  </v-data-iterator>
                </v-col>
              </template>
            </v-row>
          </div>
          <div v-else-if="block.module.includes('Brennstoffanlagen')">
            <v-row v-for="(bsa, index) in data['bsa']" :key="index">
              <v-col cols="12">
                <h3>Thermische Anlage {{ index + 1 }}</h3>
              </v-col>
              <template v-for="(column, index) in block.panel_columns">
                <v-col
                  cols="12"
                  :lg="12 / block.panel_columns.length"
                  v-if="column.portal_fields.length > 0"
                  :key="index"
                >
                  <v-data-iterator
                    disable-filtering
                    disable-pagination
                    disable-sort
                    hide-default-footer
                    :items="column.portal_fields"
                  >
                    <template v-slot:default="{ items }">
                      <div
                        v-for="item in items"
                        :key="item.id"
                        class="fields-item px-4 my-1"
                      >
                        <div class="field-title py-2">
                          {{ item.field_name }}
                        </div>
                        <div class="field-divider"></div>
                        <div class="field-value py-2">
                          <template
                            v-if="
                              !(item.field_name in bsa) || !bsa[item.field_name]
                            "
                          >
                            -
                          </template>
                          <template v-else-if="item.data_type == 'date'">
                            {{ bsa[item.field_name] | formatDate }}
                            <v-btn
                              color="info"
                              icon
                              :href="
                                route(
                                  `${item.portal_field_route.module.toLowerCase()}.linked`,
                                  {
                                    field:
                                      item.portal_field_route.link_field_id,
                                    value: bsa[item.field_name],
                                  }
                                )
                              "
                              v-if="item.portal_field_route"
                            >
                              <v-icon>mdi-link-variant</v-icon>
                            </v-btn>
                          </template>
                          <template v-else-if="item.data_type == 'number'">
                            {{ bsa[item.field_name] | formatNumber }}
                            <v-btn
                              color="info"
                              icon
                              :href="
                                route(
                                  `${item.portal_field_route.module.toLowerCase()}.linked`,
                                  {
                                    field:
                                      item.portal_field_route.link_field_id,
                                    value: bsa[item.field_name],
                                  }
                                )
                              "
                              v-if="item.portal_field_route"
                            >
                              <v-icon>mdi-link-variant</v-icon>
                            </v-btn>
                          </template>
                          <template v-else-if="item.data_type == 'coordinate'">
                            {{ bsa[item.field_name] | formatCoordinate }}
                            <v-btn
                              color="info"
                              icon
                              :href="
                                route(
                                  `${item.portal_field_route.module.toLowerCase()}.linked`,
                                  {
                                    field:
                                      item.portal_field_route.link_field_id,
                                    value: bsa[item.field_name],
                                  }
                                )
                              "
                              v-if="item.portal_field_route"
                            >
                              <v-icon>mdi-link-variant</v-icon>
                            </v-btn>
                          </template>
                          <template v-else-if="isURL(bsa[item.field_name])">
                            <a :href="isURL(bsa[item.field_name])">{{
                              bsa[item.field_name]
                            }}</a>
                          </template>
                          <template v-else>
                            {{ bsa[item.field_name] }}
                            <v-btn
                              color="info"
                              icon
                              :href="
                                route(
                                  `${item.portal_field_route.module.toLowerCase()}.linked`,
                                  {
                                    field:
                                      item.portal_field_route.link_field_id,
                                    value: bsa[item.field_name],
                                  }
                                )
                              "
                              v-if="item.portal_field_route"
                            >
                              <v-icon>mdi-link-variant</v-icon>
                            </v-btn>
                          </template>
                        </div>
                      </div>
                    </template>
                  </v-data-iterator>
                </v-col>
              </template>
            </v-row>
          </div>
          <v-row :class="{ 'flex-nowrap': $vuetify.breakpoint.lgAndUp }" v-else>
            <template v-for="(column, index) in block.panel_columns">
              <v-col
                cols="12"
                :lg="12 / block.panel_columns.length"
                v-if="column.portal_fields.length > 0"
                :key="index"
              >
                <v-data-iterator
                  disable-filtering
                  disable-pagination
                  disable-sort
                  hide-default-footer
                  :items="column.portal_fields"
                >
                  <template v-slot:default="{ items }">
                    <div
                      v-for="item in items"
                      :key="item.id"
                      class="fields-item px-4 my-1"
                    >
                      <div class="field-title py-2">
                        {{ item.field_name }}
                        <template v-if="item.description">
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn color="info" icon v-bind="attrs" v-on="on">
                                <v-icon>mdi-information-outline</v-icon>
                              </v-btn>
                            </template>
                            <span>{{ item.description }}</span>
                          </v-tooltip>
                        </template>
                      </div>
                      <div class="field-divider"></div>
                      <div
                        class="field-value py-2"
                        v-if="block.module == module"
                      >
                        <template
                          v-if="
                            !(item.field_name in data) || !data[item.field_name]
                          "
                        >
                          -
                        </template>
                        <template v-else-if="item.data_type == 'date'">
                          {{ data[item.field_name] | formatDate }}
                          <v-btn
                            color="info"
                            icon
                            :href="
                              route(
                                `${item.portal_field_route.module.toLowerCase()}.linked`,
                                {
                                  field: item.portal_field_route.link_field_id,
                                  value: data[item.field_name],
                                }
                              )
                            "
                            v-if="item.portal_field_route"
                          >
                            <v-icon>mdi-link-variant</v-icon>
                          </v-btn>
                        </template>
                        <template v-else-if="item.data_type == 'number'">
                          {{ data[item.field_name] | formatNumber }}
                          <v-btn
                            color="info"
                            icon
                            :href="
                              route(
                                `${item.portal_field_route.module.toLowerCase()}.linked`,
                                {
                                  field: item.portal_field_route.link_field_id,
                                  value: data[item.field_name],
                                }
                              )
                            "
                            v-if="item.portal_field_route"
                          >
                            <v-icon>mdi-link-variant</v-icon>
                          </v-btn>
                        </template>
                        <template v-else-if="item.data_type == 'coordinate'">
                          {{ data[item.field_name] | formatCoordinate }}
                          <v-btn
                            color="info"
                            icon
                            :href="
                              route(
                                `${item.portal_field_route.module.toLowerCase()}.linked`,
                                {
                                  field: item.portal_field_route.link_field_id,
                                  value: data[item.field_name],
                                }
                              )
                            "
                            v-if="item.portal_field_route"
                          >
                            <v-icon>mdi-link-variant</v-icon>
                          </v-btn>
                        </template>
                        <template v-else-if="isURL(data[item.field_name])">
                          <a :href="isURL(data[item.field_name])">{{
                            data[item.field_name]
                          }}</a>
                        </template>
                        <template v-else>
                          {{ data[item.field_name] }}
                          <v-btn
                            color="info"
                            icon
                            :href="
                              route(
                                `${item.portal_field_route.module.toLowerCase()}.linked`,
                                {
                                  field: item.portal_field_route.link_field_id,
                                  value: data[item.field_name],
                                }
                              )
                            "
                            v-if="item.portal_field_route"
                          >
                            <v-icon>mdi-link-variant</v-icon>
                          </v-btn>
                        </template>
                      </div>
                      <div
                        class="field-value py-2"
                        v-else-if="data[block.module.toLowerCase()]"
                      >
                        <template
                          v-if="
                            !(
                              item.field_name in
                              data[block.module.toLowerCase()]
                            ) ||
                            !data[block.module.toLowerCase()][item.field_name]
                          "
                        >
                          -
                        </template>
                        <template v-else-if="item.data_type == 'date'">
                          {{
                            data[block.module.toLowerCase()][item.field_name]
                              | formatDate
                          }}
                          <v-btn
                            color="info"
                            icon
                            :href="
                              route(
                                `${item.portal_field_route.module.toLowerCase()}.linked`,
                                {
                                  field: item.portal_field_route.link_field_id,
                                  value:
                                    data[block.module.toLowerCase()][
                                      item.field_name
                                    ],
                                }
                              )
                            "
                            v-if="item.portal_field_route"
                          >
                            <v-icon>mdi-link-variant</v-icon>
                          </v-btn>
                        </template>
                        <template v-else-if="item.data_type == 'number'">
                          {{ data[block.module.toLowerCase()][
                                      item.field_name
                                    ], | formatNumber }}
                          <v-btn
                            color="info"
                            icon
                            :href="
                              route(
                                `${item.portal_field_route.module.toLowerCase()}.linked`,
                                {
                                  field: item.portal_field_route.link_field_id,
                                  value:
                                    data[block.module.toLowerCase()][
                                      item.field_name
                                    ],
                                }
                              )
                            "
                            v-if="item.portal_field_route"
                          >
                            <v-icon>mdi-link-variant</v-icon>
                          </v-btn>
                        </template>
                        <template v-else-if="item.data_type == 'coordinate'">
                          {{ data[block.module.toLowerCase()][
                                      item.field_name
                                    ], | formatCoordinate }}
                          <v-btn
                            color="info"
                            icon
                            :href="
                              route(
                                `${item.portal_field_route.module.toLowerCase()}.linked`,
                                {
                                  field: item.portal_field_route.link_field_id,
                                  value:
                                    data[block.module.toLowerCase()][
                                      item.field_name
                                    ],
                                }
                              )
                            "
                            v-if="item.portal_field_route"
                          >
                            <v-icon>mdi-link-variant</v-icon>
                          </v-btn>
                        </template>
                        <template
                          v-else-if="
                            isURL(
                              data[block.module.toLowerCase()][item.field_name]
                            )
                          "
                        >
                          <a
                            :href="
                              isURL(
                                data[block.module.toLowerCase()][
                                  item.field_name
                                ]
                              )
                            "
                            >{{
                              data[block.module.toLowerCase()][item.field_name]
                            }}</a
                          >
                        </template>
                        <template v-else>
                          {{
                            data[block.module.toLowerCase()][item.field_name]
                          }}
                          <v-btn
                            color="info"
                            icon
                            :href="
                              route(
                                `${item.portal_field_route.module.toLowerCase()}.linked`,
                                {
                                  field: item.portal_field_route.link_field_id,
                                  value:
                                    data[block.module.toLowerCase()][
                                      item.field_name
                                    ],
                                }
                              )
                            "
                            v-if="item.portal_field_route"
                          >
                            <v-icon>mdi-link-variant</v-icon>
                          </v-btn>
                        </template>
                      </div>
                      <div class="field-value py-2" v-else>-</div>
                    </div>
                  </template>
                </v-data-iterator>
              </v-col>
            </template>
          </v-row>
        </v-expansion-panel-content>
        <v-expansion-panel-content v-else-if="block.type === 'map'">
          <v-divider class="mb-4"></v-divider>
          <google-map
            :location="data[`location${block.id}`]"
            :data="data"
            :wea="module === 'Windparks' ? data['wea'] : undefined"
            :pva="module === 'Photovoltaikparks' ? data['pva'] : undefined"
            :bsa="module === 'Brennstoffe' ? data['bsa'] : undefined"
            :module="module"
          ></google-map>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-row
        class="my-2"
        v-if="
          panelsLoaded &&
          (module === 'Organisationen' ||
            module === 'Ansprechpartner' ||
            module === 'Betreiber' ||
            module === 'Eigentümer')
        "
      >
        <v-col
          cols="12"
          lg="4"
          v-if="data.windparks && data.windparks.length > 0"
        >
          <v-expansion-panel active-class="active">
            <v-expansion-panel-header class="text-h6">
              <v-col cols="6"> Windparks </v-col>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-divider class="mb-4"></v-divider>
              <v-row :class="{ 'flex-nowrap': $vuetify.breakpoint.lgAndUp }">
                <v-col cols="12">
                  <v-data-iterator
                    disable-filtering
                    disable-pagination
                    disable-sort
                    hide-default-footer
                    :items="data.windparks"
                  >
                    <template v-slot:default="{ items }">
                      <a
                        v-for="item in items"
                        :key="item.Id"
                        class="d-block primary--text"
                        :href="route('windparks.details', item.Id)"
                      >
                        {{ item.Name_Windpark }}
                      </a>
                    </template>
                  </v-data-iterator>
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-col>
        <v-col
          cols="12"
          lg="4"
          v-if="data.pv_parks && data.pv_parks.length > 0"
        >
          <v-expansion-panel active-class="active">
            <v-expansion-panel-header class="text-h6">
              <v-col cols="6"> Photovoltaikparks </v-col>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-divider class="mb-4"></v-divider>
              <v-row :class="{ 'flex-nowrap': $vuetify.breakpoint.lgAndUp }">
                <v-col cols="12">
                  <v-data-iterator
                    disable-filtering
                    disable-pagination
                    disable-sort
                    hide-default-footer
                    :items="data.pv_parks"
                  >
                    <template v-slot:default="{ items }">
                      <a
                        v-for="item in items"
                        :key="item.Id"
                        class="d-block primary--text"
                        :href="route('photovoltaikparks.details', item.Id)"
                      >
                        {{ item.Name_PV_Park }}
                      </a>
                    </template>
                  </v-data-iterator>
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-col>
        <v-col
          cols="12"
          lg="4"
          v-if="data.brennstoffe && data.brennstoffe.length > 0"
        >
          <v-expansion-panel active-class="active">
            <v-expansion-panel-header class="text-h6">
              <v-col cols="6"> Brennstoffe </v-col>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-divider class="mb-4"></v-divider>
              <v-row :class="{ 'flex-nowrap': $vuetify.breakpoint.lgAndUp }">
                <v-col cols="12">
                  <v-data-iterator
                    disable-filtering
                    disable-pagination
                    disable-sort
                    hide-default-footer
                    :items="data.brennstoffe"
                  >
                    <template v-slot:default="{ items }">
                      <a
                        v-for="item in items"
                        :key="item.Id"
                        class="d-block primary--text"
                        :href="route('brennstoffe.details', item.Id)"
                      >
                        {{ item.Name_Anlage }}
                      </a>
                    </template>
                  </v-data-iterator>
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-col>
      </v-row>
    </v-expansion-panels>
    <v-dialog v-model="show" max-width="400">
      <v-card>
        <v-toolbar color="info" dark flat>
          <v-toolbar-title>Daten exportieren</v-toolbar-title>
        </v-toolbar>
        <v-card-text class="pt-4 px-4">
          <v-form v-model="exportValid" ref="exportSettings">
            <v-text-field
              v-model="fileName"
              :rules="validationRules"
              filled
              dense
              hide-details="auto"
              label="Dateiname"
              class="mb-4"
            ></v-text-field>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn color="error" @click.stop="$emit('close')" x-small
            >Abbrechen</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn color="primary" type="btn" small @click="exportData"
            >Exportieren</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import GoogleMap from "./GoogleMap.vue";

export default {
  components: {
    GoogleMap,
  },
  props: {
    data: Object,
    config: Number,
    module: String,
    show: Boolean,
  },
  data: () => ({
    configData: {},
    openPanels: [],
    exportValid: true,
    panelsLoaded: false,
    validationRules: [(v) => !!v || "Bitte Feld ausfüllen"],
    fileName: "",
    exportFormat: "xlsx",
  }),
  created() {
    this.getConfigData();
  },

  methods: {
    exportData() {
      this.$refs.exportSettings.validate();

      if (this.exportValid) {
        axios
          .post(
            this.$root.route("excel.export.panel"),
            {
              item: this.data,
              blocks: this.configData.panel_blocks,
              format: this.exportFormat,
            },
            {
              responseType: "blob",
            }
          )
          .then((res) => {
            const url = window.URL.createObjectURL(new Blob([res.data]));
            const link = document.createElement("a");
            link.href = url;
            const fileName = `${this.fileName}.${this.exportFormat}`;
            link.setAttribute("download", fileName);
            document.body.appendChild(link);
            link.click();
            link.remove();
            this.$emit("close");
          });
      }
    },

    isURL(string) {
      try {
        if (string.startsWith("www.")) {
          string = "https://" + string;
        }
        if (!string.startsWith("https://") && !string.startsWith("http://"))
          return false;
        new URL(string);
      } catch (_) {
        return false;
      }

      return string;
    },

    getAnsprechpartnerArray(module) {
      if (
        this.data[module] &&
        this.data[module].hasOwnProperty("ap") &&
        this.data[module]["ap"].length > 0
      )
        return this.data[module]["ap"];

      if (this.data.hasOwnProperty("ap") && this.data["ap"].length > 0)
        return this.data["ap"];

      return [0];
    },
    getConfigData() {
      axios
        .get(this.$root.route("panels.getConfig", this.config))
        .then((res) => {
          this.configData = res.data;

          this.configData.panel_blocks.forEach((elem, index) => {
            if (elem.open_on_load) this.openPanels.push(index);
            if (elem.type !== "table") return;
          });

          if (this.openPanels.length == 0) this.openPanels.push(0);

          this.panelsLoaded = true;
        })
        .catch(() => {});
    },
  },
};
</script>

<style lang="scss" scoped>
.fields-item:nth-child(2n) {
  background-color: #f7f9fa;
  .field-divider {
    border: 1px solid #fff;
  }
}

.fields-item {
  display: grid;
  grid-template-columns: minmax(50%, auto) 0fr 1fr;
  column-gap: 15px;
  align-items: flex-start;

  .field-title {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-align: end;
    color: #a6adaf;
  }

  .field-value {
    color: #919699;
    font-weight: 700;
    // overflow: hidden;
    // text-overflow: ellipsis;
    // white-space: nowrap;
  }

  .field-divider {
    border: 1px solid #f7f9fa;
    height: 100%;
  }
}

.v-expansion-panel {
  .v-expansion-panel-header {
    transition: all 250ms ease-in-out;
  }

  &.active {
    .v-expansion-panel-header {
      color: #84a315 !important;
    }
  }
}
</style>