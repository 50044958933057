<template>
  <v-form v-if="locked" method="POST" @submit.prevent="unlockUser">
    <v-btn type="submit" color="primary">Benutzer entsperren</v-btn>
  </v-form>
  <v-form v-else method="POST" @submit.prevent="lockUser">
    <v-btn type="submit" color="primary">Benutzer sperren</v-btn>
  </v-form>
</template>

<script>
export default {
  props: {
    locked: Boolean,
    userId: Number,
  },
  methods: {
    lockUser() {
      axios
        .post(this.$root.route("admin.users.lock", { user: this.userId }))
        .then(() => {
          this.$snackbar.showMessage({
            content: `Benutzer gesperrt`,
            color: "primary",
          });
        })
        .catch(() => {
          this.$snackbar.showMessage({
            content: "Unerwarteter Fehler!",
            color: "error",
          });
        });
    },

    unlockUser() {
      axios
        .post(this.$root.route("admin.users.unlock", { user: this.userId }))
        .then(() => {
          this.$snackbar.showMessage({
            content: `Benutzer entsperrt`,
            color: "primary",
          });
        })
        .catch(() => {
          this.$snackbar.showMessage({
            content: "Unerwarteter Fehler!",
            color: "error",
          });
        });
    },
  },
};
</script>

<style lang="scss" scoped>
</style>