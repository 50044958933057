const state = () => ({
})

const mutations = {
  showMessage (state, payload) {
  },
  closeMessage (state, payload) {
  }
}

export default {
  namespaced: true,
  state,
  mutations
}
