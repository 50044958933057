<template>
  <div>
    <v-row>
      <v-col>
        <v-btn class="mb-6" color="primary" @click="show = true">Export</v-btn>
      </v-col>
    </v-row>
    <v-data-iterator
      class="d-none"
      v-if="itemsLoaded"
      :search="search"
      :items="items"
      :items-per-page="-1"
      :sort-by="tableOptions.sortBy"
      :sort-desc="tableOptions.sortDesc"
      locale="de-DE"
      hide-default-footer
      :footer-props="{
        itemsPerPageText: 'Zeilen pro Seite: ',
        itemsPerPageOptions: [-1],
      }"
      @current-items="cloneItems = $event"
    >
    </v-data-iterator>
    <v-data-table
      :group-by="groupBy.field.table_value"
      v-if="itemsLoaded && groupBy.field"
      item-key="uuid"
      :search="search"
      :headers="computedHeaders"
      :items="items"
      :item-class="setCursor"
      :items-per-page.sync="tableOptions.itemsPerPage"
      :sort-by.sync="tableOptions.sortBy"
      :sort-desc.sync="tableOptions.sortDesc"
      :page.sync="tableOptions.page"
      @page-count="pageCount = $event"
      dense
      locale="de-DE"
      hide-default-footer
      :loading="loading"
      :footer-props="{
        itemsPerPageText: 'Zeilen pro Seite: ',
        itemsPerPageOptions: [10, 25, 50, 100],
      }"
      @current-items="$emit('current-items', $event)"
    >
      <template
        v-for="header in headers"
        v-slot:[`item.${header.value}`]="{ value }"
      >
        <template v-if="header.data_type == 'date'">
          {{ value | formatDate }}
        </template>
        <template v-else-if="header.data_type == 'number'">
          {{ value | formatNumber }}
        </template>
        <template v-else-if="header.data_type == 'coordinate'">
          {{ value | formatCoordinate }}
        </template>
        <template v-else>
          {{ value }}
        </template>
      </template>
      <template v-slot:group.header="{ group, isOpen, toggle, groupBy }">
        <th colspan="25">
          <v-icon @click="toggle"
            >{{ isOpen ? "mdi-minus" : "mdi-plus" }}
          </v-icon>
          <span style="font-weight: 600; vertical-align: middle"
            >{{ groupBy[0] }}: {{ group }}</span
          >
        </th>
      </template>
      <template
        v-slot:footer="{
          props: { pagination, itemsPerPageText, itemsPerPageOptions },
        }"
      >
        <table-footer
          :pagination="pagination"
          :itemsPerPageText="itemsPerPageText"
          :itemsPerPageOptions="itemsPerPageOptions"
          :tableOptions="tableOptions"
          :pageCount="pageCount"
        ></table-footer>
        <v-row>
          <code>
            {{ sql }}
          </code>
        </v-row>
      </template>
    </v-data-table>
    <v-data-table
      v-else-if="itemsLoaded && !groupBy.field"
      item-key="uuid"
      :search="search"
      :headers="computedHeaders"
      :items="items"
      :item-class="setCursor"
      :items-per-page.sync="tableOptions.itemsPerPage"
      :sort-by.sync="tableOptions.sortBy"
      :sort-desc.sync="tableOptions.sortDesc"
      :page.sync="tableOptions.page"
      @page-count="pageCount = $event"
      dense
      locale="de-DE"
      hide-default-footer
      :loading="loading"
      :footer-props="{
        itemsPerPageText: 'Zeilen pro Seite: ',
        itemsPerPageOptions: [10, 25, 50, -1],
      }"
      @current-items="$emit('current-items', $event)"
    >
      <template
        v-for="header in headers"
        v-slot:[`item.${header.value}`]="{ value }"
      >
        <template v-if="header.data_type == 'date'">
          {{ value | formatDate }}
        </template>
        <template v-else-if="header.data_type == 'number'">
          {{ value | formatNumber }}
        </template>
        <template v-else>
          {{ value }}
        </template>
      </template>
      <template v-slot:group.header="{ group, isOpen, toggle, groupBy }">
        <th colspan="25">
          <v-icon @click="toggle"
            >{{ isOpen ? "mdi-minus" : "mdi-plus" }}
          </v-icon>
          <span style="font-weight: 600; vertical-align: middle"
            >{{ groupBy[0] }}: {{ group }}</span
          >
        </th>
      </template>
      <template
        v-slot:footer="{
          props: { pagination, itemsPerPageText, itemsPerPageOptions },
        }"
      >
        <table-footer
          :pagination="pagination"
          :itemsPerPageText="itemsPerPageText"
          :itemsPerPageOptions="itemsPerPageOptions"
          :tableOptions="tableOptions"
          :pageCount="pageCount"
        ></table-footer>
        <v-row>
          <code>
            {{ sql }}
          </code>
        </v-row>
      </template>
    </v-data-table>
    <div class="d-flex justify-center mt-16" v-else>
      <v-progress-circular
        indeterminate
        size="60"
        color="primary"
      ></v-progress-circular>
    </div>
    <v-dialog v-model="show" max-width="400">
      <v-card>
        <v-toolbar color="info" dark flat>
          <v-toolbar-title>Tabelle exportieren</v-toolbar-title>
        </v-toolbar>
        <v-card-text class="pt-4 px-4">
          <v-form v-model="exportValid" ref="exportSettings">
            <v-text-field
              v-model="fileName"
              :rules="validationRules"
              filled
              dense
              hide-details="auto"
              label="Dateiname"
              class="mb-4"
            ></v-text-field>
            <v-select
              v-model="exportFormat"
              :rules="validationRules"
              filled
              dense
              hide-details="auto"
              :items="dropdownItems"
              label="Als Excel oder CSV exportieren?"
            ></v-select>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn color="error" @click.stop="show = false" x-small
            >Abbrechen</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn color="primary" type="btn" small @click="exportData"
            >Exportieren</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import TableFooter from "../TableFooter.vue";
import moment from "moment/dist/moment";

export default {
  components: {
    "table-footer": TableFooter,
  },
  data: () => ({
    pageCount: 0,
    show: false,
    cloneItems: [],
    itemsLoaded: true,
    tableOptions: {
      page: 1,
      itemsPerPage: 10,
      sortBy: [],
      sortDesc: [],
    },
    loading: false,
    loadingData: false,
    exportFormat: "",
    fileName: "",
    sql: "",
    exportValid: true,
    dropdownItems: [
      {
        text: "Excel (.xlsx)",
        value: "xlsx",
      },
      {
        text: "CSV (.csv)",
        value: "csv",
      },
    ],
    validationRules: [(v) => !!v || "Bitte Feld ausfüllen"],
  }),
  props: {
    search: String,
    headers: Array,
    items: Array,
    groupBy: Object,
    primaryModule: Object,
    calculations: Array,
  },

  created() {
    this.loadLocalStorageTableOptions();
  },

  computed: {
    computedHeaders: {
      get() {
        return [
          ...this.headers.map((header) => {
            /* if(header.portal_field_category_id === this.primaryModule.id) */
            if (header.data_type === "date")
              return {
                text: header.field_name,
                value:
                  this.getHeaderSlug(header.portal_field_category.name) +
                  header.table_value,
                table_value: header.table_value,
                field_name: header.field_name,
                data_type: header.data_type,
                sort: (a, b) => {
                  if (
                    moment(a, "MMM DD YYYY HH:mm:ss:A").isBefore(
                      moment(b, "MMM DD YYYY HH:mm:ss:A")
                    )
                  )
                    return -1;
                  if (
                    moment(a, "MMM DD YYYY HH:mm:ss:A").isAfter(
                      moment(b, "MMM DD YYYY HH:mm:ss:A")
                    )
                  )
                    return 1;
                  if (
                    moment(a, "MMM DD YYYY HH:mm:ss:A").isSame(
                      moment(b, "MMM DD YYYY HH:mm:ss:A")
                    )
                  )
                    return 0;
                },
              };

            return {
              text: header.field_name,
              value:
                this.getHeaderSlug(header.portal_field_category.name) +
                header.table_value,
              table_value: header.table_value,
              field_name: header.field_name,
              data_type: header.data_type,
            };
            // return {text: header.field_name, value: `${header.portal_field_category.slug}.${header.table_value}`}
          }),
          ...(this.calculations
            ? this.calculations.map((calculation) => {
                return {
                  text: `${calculation.method}(${calculation.field.field_name})`,
                  value: `${calculation.method}(${calculation.field.field_name})`,
                  table_value: `${calculation.method}(${calculation.field.field_name})`,
                  field_name: `${calculation.method}(${calculation.field.field_name})`,
                  data_type: `${calculation.field.data_type}`,
                };
              })
            : []),
        ];
      },
    },
  },

  methods: {
    loadLocalStorageTableOptions() {
      if (localStorage.hasOwnProperty("tableOptions")) {
        const lsTableOptions = JSON.parse(localStorage.tableOptions);

        if (lsTableOptions.module === "bericht")
          this.tableOptions = lsTableOptions;
      }
    },

    getHeaderSlug(categoryName) {
      if (categoryName === this.primaryModule.name) return "";

      switch (categoryName) {
        case "Ansprechpartner":
          return "ap.";
        case "Ansprechpartner Betreiber":
          return "betreiber.ap.";
        case "Ansprechpartner Eigentümer":
          return "eigentümer.ap.";
        case "Betreiber":
          return "betreiber.";
        case "Brennstoffanlagen":
          return "bsa.";
        case "Brennstoffe":
          return "brennstoff.";
        case "Eigentümer":
          return "eigentümer.";
        case "Organisationen":
          return "organisation.";
        case "Photovoltaikanlagen":
          return "pva.";
        case "Photovoltaikparks":
          return "pv_park.";
        case "Windenergieanlagen":
          return "wea.";
        case "Windparks":
          return "windpark.";
        default:
          break;
      }
    },

    // openDetails(row) {
    //   const index = _.findIndex(this.cloneItems, (item) => {
    //     return _.isEqual(item, row);
    //   });

    //   this.$store.dispatch("detailsPagination/setIndizes", index);
    //   window.location.href = this.$root.route(
    //     `${bericht.toLowerCase()}.details`,
    //     row.Id
    //   );
    // },

    setCursor() {
      return "cursor-pointer table-item";
    },

    exportData() {
      this.$refs.exportSettings.validate();

      if (this.exportValid) {
        axios
          .post(
            this.$root.route("excel.export"),
            {
              items: this.cloneItems,
              headings: this.computedHeaders,
              format: this.exportFormat,
            },
            {
              responseType: "blob",
            }
          )
          .then((res) => {
            const url = window.URL.createObjectURL(new Blob([res.data]));
            const link = document.createElement("a");
            link.href = url;
            const fileName = `${this.fileName}.${this.exportFormat}`;
            link.setAttribute("download", fileName);
            document.body.appendChild(link);
            link.click();
            link.remove();
            this.$emit("close");
          });
      }
    },
  },
  watch: {
    cloneItems: {
      handler() {
        this.key++;
      },
    },
    tableOptions: {
      handler() {
        this.tableOptions.module = "bericht";

        localStorage.tableOptions = JSON.stringify(this.tableOptions);
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.v-data-table::v-deep {
  .v-data-table-header th {
    white-space: nowrap;
  }
  .cursor-pointer {
    cursor: pointer;
  }

  .table-item {
    white-space: nowrap !important;
  }
}
</style>