<template>
  <div>
    <template v-if="notifications.length > 0">
      <v-row
        style="position: relative"
        v-for="item in notifications"
        :key="item.id"
      >
        <template v-if="item.type.includes('NewsCreated')">
          <v-col cols="8">
            <h4>{{ item.data.title }}</h4>
            <p>{{ item.data.excerpt }}</p>
            <v-row justify="end" class="mx-0">
              <v-btn
                link
                :href="route('news.show', item.data.slug)"
                text
                color="primary"
                >weiterlesen</v-btn
              >
            </v-row>
          </v-col>
          <v-col cols="4">
            <v-img
              max-width="200"
              contain
              :src="item.data.featuredImage + '_thumbnail.webp'"
            ></v-img>
          </v-col>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                absolute
                class="mt-n2 mr-n2 white"
                rounded
                top
                x-small
                icon
                elevation="2"
                right
                @click.stop="markAsRead(item.id)"
                color="error"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </template>
            <span>Als gelesen markieren</span>
          </v-tooltip>
        </template>
        <template v-else>
          <v-col cols="12"> Keine ungelesenen Nachrichten </v-col>
        </template>
      </v-row>
    </template>
    <template v-else>
      <v-row>
        <v-col cols="12"> Keine ungelesenen Nachrichten </v-col>
      </v-row>
    </template>
  </div>
</template>

<script>
export default {
  computed: {
    notifications: {
      get() {
        return this.$store.state.notifications.notifications;
      },
    },
  },
  methods: {
    markAsRead(id) {
      axios
        .post(this.$root.route("markRead"), {
          id: id,
        })
        .then((res) => {
          this.$store.dispatch("notifications/removeNotification", id);
        })
        .catch(() => {
          this.$snackbar.showMessage({
            content: "Unerwarter Fehler!",
            color: "error",
          });
        });
    },

    markAllAsRead() {
      axios
        .post("/mark-as-read")
        .then((res) => {
          // TODO: Notification Store and remove from store
        })
        .catch(() => {
          this.$snackbar.showMessage({
            content: "Unerwarter Fehler!",
            color: "error",
          });
        });
    },
  },
};
</script>

<style lang="scss" scoped>
</style>