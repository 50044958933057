<template>
  <div>
    <v-btn
      icon
      @click.prevent="moveToPrev"
      v-if="prev !== false"
      :href="
        route(
          `${module.toLowerCase()}.details`,
          $store.state.detailsPagination.items[prev]
        )
      "
    >
      <v-icon size="40">mdi-chevron-left</v-icon>
    </v-btn>
    <v-btn icon v-else disabled>
      <v-icon size="40">mdi-chevron-left</v-icon>
    </v-btn>
    <v-btn
      icon
      @click.prevent="moveToNext"
      v-if="next !== false"
      :href="
        route(
          `${module.toLowerCase()}.details`,
          $store.state.detailsPagination.items[next]
        )
      "
    >
      <v-icon size="40">mdi-chevron-right</v-icon>
    </v-btn>
    <v-btn icon v-else disabled>
      <v-icon size="40">mdi-chevron-right</v-icon>
    </v-btn>
  </div>
</template>

<script>
export default {
  props: {
    module: String,
  },
  methods: {
    moveToPrev(event) {
      const url = event.currentTarget.getAttribute("href");
      this.$store.dispatch("detailsPagination/moveToPrev");

      window.location = url;
    },

    moveToNext(event) {
      const url = event.currentTarget.getAttribute("href");
      this.$store.dispatch("detailsPagination/moveToNext");

      window.location = url;
    },
  },
  computed: {
    prev: {
      get() {
        return this.$store.state.detailsPagination.prevIndex;
      },
    },
    next: {
      get() {
        return this.$store.state.detailsPagination.nextIndex;
      },
    },
  },
};
</script>

<style lang="scss" scoped>
</style>