<template>
  <div>
    <v-data-table
      v-model="selected"
      show-select
      hide-default-footer
      :headers="headers"
      :items="cloneItems"
      item-key="id"
      :search="search"
      :options="tableOptions"
      :page.sync="page"
      @page-count="pageCount = $event"
      dense
      :footer-props="{
        itemsPerPageText: 'Zeilen pro Seite: ',
        itemsPerPageOptions: [5, 10, 15, 30, -1],
      }"
    >
      <template v-slot:item.field_name="{ item }">
        <v-edit-dialog
          :return-value.sync="item.field_name"
          @save="save(item)"
          @cancel="cancel"
        >
          {{ item.field_name }}
          <template v-slot:input>
            <v-form v-model="valid" ref="editForm">
              <v-text-field
                :rules="nameRules"
                class="pb-4 mt-3"
                v-model="item.field_name"
                label="Feldnamen eintragen"
                hide-details="auto"
              ></v-text-field>
            </v-form>
          </template>
        </v-edit-dialog>
      </template>
      <template v-slot:item.route="{ item }">
        <v-edit-dialog
          :return-value.sync="item.route"
          @save="save(item)"
          @cancel="cancel"
        >
          {{ item.route }}
          <template v-slot:input>
            <v-text-field
              class="pb-4 mt-3"
              v-model="item.route"
              label="Route eintragen"
              hide-details="auto"
            ></v-text-field>
          </template>
        </v-edit-dialog>
      </template>
      <template v-slot:item.api_fields="{ item }">
        <v-row style="column-gap: 15px">
          <v-chip
            v-for="(elem, index) in item.api_fields"
            :key="index"
            :color="item.portal_field_category.color"
            >{{ elem.field_name }}
          </v-chip>
        </v-row>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              icon
              :href="route('admin.fields.details', item.id)"
            >
              <v-icon> mdi-pencil </v-icon>
            </v-btn>
          </template>
          <span>Editieren</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              icon
              color="error"
              @click.stop="openDialog(item.id)"
            >
              <v-icon> mdi-delete </v-icon>
            </v-btn>
          </template>
          <span>Löschen</span>
        </v-tooltip>
      </template>
      <template
        v-slot:footer="{
          props: { pagination, itemsPerPageText, itemsPerPageOptions, options },
        }"
      >
        <table-footer
          :pagination="pagination"
          :itemsPerPageText="itemsPerPageText"
          :itemsPerPageOptions="itemsPerPageOptions"
          :tableOptions="tableOptions"
          :pageCount="pageCount"
        ></table-footer>
      </template>
    </v-data-table>
    <v-dialog v-model="dialog" max-width="400">
      <v-card>
        <v-toolbar color="primary" dark flat>
          <v-toolbar-title>Feld löschen?</v-toolbar-title>
        </v-toolbar>
        <v-card-actions>
          <v-btn color="secondary" @click.stop="dialog = false" x-small
            >Abbrechen</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn color="error" type="btn" @click="deleteField()" small
            >Löschen</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import TableFooter from "../components/TableFooter.vue";

export default {
  components: {
    "table-footer": TableFooter,
  },
  data: () => ({
    page: 1,
    pageCount: 0,
    itemsPerPage: 10,
    valid: true,
    selected: [],
    tableOptions: {
      page: 1,
      itemsPerPage: 10,
      sortBy: [],
      sortDesc: [],
      module: "Felder",
    },
    dialog: false,
    currentId: 0,
    nameRules: [
      (v) => !!v || "Feldname wird benötigt",
      (v) =>
        !v ||
        !/[^a-zA-Z0-9\s]+/.test(v) ||
        "Feldname darf nur Alphanumerische Zeichen enthalten.",
    ],
    cloneItems: [],
  }),
  props: {
    items: Array,
    search: String,
    headers: Array,
  },
  created() {
    this.loadLocalStorageTableOptions();
    this.cloneItems = _.cloneDeep(this.items);
  },
  methods: {
    loadLocalStorageTableOptions() {
      if (localStorage.hasOwnProperty('tableOptions')) {
        const lsTableOptions = JSON.parse(localStorage.tableOptions);

        if (lsTableOptions.module === "Felder")
          this.tableOptions = lsTableOptions;
      }
    },
    openDialog(id) {
      this.dialog = true;
      this.currentId = id;
    },

    deleteField() {
      axios
        .delete(this.$root.route("admin.fields.delete", this.currentId))
        .then((res) => {
          this.dialog = false;
          this.cloneItems.splice(
            this.cloneItems.findIndex((elem) => elem.id === this.currentId),
            1
          );
          this.$snackbar.showMessage({
            content: "Feld gelöscht",
            color: "primary",
          });
        })
        .catch(() => {
          this.dialog = false;
          this.$snackbar.showMessage({
            content: "Fehler beim Löschen",
            color: "error",
          });
        });
    },
    save(item) {
      this.$refs.editForm.validate();
      if (this.valid) {
        axios
          .post(`/admin/fields/save`, {
            portalField: item,
          })
          .then((res) => {
            this.$snackbar.showMessage({
              content: "Feld gespeichert",
              color: "primary",
            });
          })
          .catch(() => {
            this.$snackbar.showMessage({
              content: "Fehler beim Speichern!",
              color: "error",
            });
          });
      }
    },
    cancel() {
      this.$snackbar.showMessage({
        content: "Änderungen verworfen",
        color: "error",
      });
    },
  },
  watch: {
    tableOptions: {
      handler() {
        this.tableOptions.module = "Felder";

        localStorage.tableOptions = JSON.stringify(this.tableOptions);
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss" scoped>
</style>