import axios from 'axios'
// require('dotenv').config()

const state = () => ({
  availableFields: [],
  selectedFields: []
})

const mutations = {
  updateAvailableFields (state, payload) {
    state.availableFields = payload
  },

  updateSelectedFields (state, payload) {
    state.selectedFields.splice(0, state.selectedFields.length)
    state.selectedFields.push(...payload)
  },

  removeSelectedItem (state, payload) {
    state.selectedFields.splice(payload, 1)
  },

  removeAvailableItem (state, payload) {
    const index = _.findIndex(state.availableFields, ['id', payload])
    if (index >= 0) state.availableFields.splice(index, 1)
  },

  addAvailableElement (state, payload) {
    state.availableFields.push(payload)
  },

  addSelectedElement (state, payload) {
    state.selectedFields.push(payload)
  },

  resetFields (state) {
    state.availableFields = [...state.availableFields, ...state.selectedFields]
    state.selectedFields = []
  }
}

const actions = {
  loadAllField ({ commit }) {
    return new Promise((resolve, reject) => {
      commit('resetFields')

      axios
        .get('/getPortalFields')
        .then(res => {
          commit('updateAvailableFields', res.data)
          resolve()
        })
        .catch(() => {
          reject()
        })
    })
  },

  setSelectedFields ({ commit }, filterFields) {
    commit('resetFields')

    filterFields.forEach(elem => {
      commit('removeAvailableItem', elem.id)
    })

    commit('updateSelectedFields', filterFields)
  },

  removeSelected ({ commit }, index) {
    commit('removeSelectedItem', index)
  },

  removeAvailable ({ commit }, id) {
    commit('removeAvailableItem', id)
  },

  addAvailable ({ commit }, element) {
    commit('addAvailableElement', element)
  },

  addSelected ({ commit }, element) {
    commit('addSelectedElement', element)
  }
}

const getters = {
  getAvailableFieldsByCategoryName: state => name => {
    return state.availableFields.filter(field => {
      return field.portal_field_category.name === name
    })
  },

  getAllFieldsByCategoryName: state => name => {
    return [
      ...state.availableFields.filter(field => {
        return field.portal_field_category.name === name
      }),

      ...state.selectedFields.filter(field => {
        return field.portal_field_category.name === name
      })
    ]
  },

  getAllFields: state => {
    return [...state.availableFields, ...state.selectedFields]
  },

  getAvailableFieldsByCategoryId: state => id => {
    return state.availableFields.filter(field => {
      return field.portal_field_category.id === id
    })
  },

  getDataTypeById: state => id => {
    let field = state.availableFields.find(field => {
      return field.id === id
    })

    if (field) return field.data_type

    field = state.selectedFields.find(field => {
      return field.id === id
    })

    if (field) return field.data_type
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
