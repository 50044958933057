<template>
  <div>
    <v-data-table
      hide-default-footer
      :headers="headers"
      :items="cloneItems"
      item-key="id"
      :search="search"
      :options="{ itemsPerPage: itemsPerPage }"
      :page.sync="page"
      @page-count="pageCount = $event"
      dense
      :footer-props="{
        itemsPerPageText: 'Zeilen pro Seite: ',
        itemsPerPageOptions: [5, 10, 15, 30, -1],
      }"
    >
      <template v-slot:item.last_login_time="{ item }">
        {{ item.last_login_time | formatDateTime }}
      </template>
      <template v-slot:item.email_verified_at="{ item }">
        <v-icon color="primary" v-if="item.email_verified_at"
          >mdi-check-circle-outline</v-icon
        >
        <v-icon color="error" v-else>mdi-close-circle-outline</v-icon>
      </template>

      <template v-slot:item.approved="{ item }">
        <v-icon
          color="primary"
          style="margin-left: 6px"
          v-if="item.approved == true"
          >mdi-check-circle-outline</v-icon
        >
        <v-btn icon v-else :href="route('admin.users.approve', item.id)"
          ><v-icon color="error">mdi-close-circle-outline</v-icon></v-btn
        >
      </template>
      <template v-slot:item.locked="{ item }">
        <v-icon color="primary" v-if="!item.locked"
          >mdi-check-circle-outline</v-icon
        >
        <v-icon v-else color="error">mdi-close-circle-outline</v-icon>
      </template>
      <template v-slot:item.deleted="{ item }">
        <v-icon
          color="error"
          style="margin-left: 6px"
          v-if="item.deleted_at === null"
          >mdi-close-circle-outline</v-icon
        >
        <v-btn icon v-else @click="restoreUser(item.id)"
          ><v-icon color="primary">mdi-check-circle-outline</v-icon></v-btn
        >
      </template>
      <template v-slot:item.actions="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              icon
              :href="route('admin.users.details', item.id)"
            >
              <v-icon> mdi-pencil </v-icon>
            </v-btn>
          </template>
          <span>Editieren</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              icon
              color="error"
              @click.stop="openDialog(item.id)"
            >
              <v-icon> mdi-delete </v-icon>
            </v-btn>
          </template>
          <span>Löschen</span>
        </v-tooltip>
      </template>
      <template
        v-slot:footer="{
          props: { pagination, itemsPerPageText, itemsPerPageOptions, options },
        }"
      >
        <table-footer
          :pagination="pagination"
          :itemsPerPageText="itemsPerPageText"
          :itemsPerPageOptions="itemsPerPageOptions"
          :tableOptions="options"
          :pageCount="pageCount"
        ></table-footer>
      </template>
    </v-data-table>
    <v-dialog v-model="dialog" max-width="400">
      <v-card>
        <v-toolbar color="primary" dark flat>
          <v-toolbar-title>Benutzer löschen?</v-toolbar-title>
        </v-toolbar>
        <v-card-actions>
          <v-btn color="secondary" @click.stop="dialog = false" x-small
            >Abbrechen</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn color="error" type="btn" @click="deleteUser()" small
            >Löschen</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import TableFooter from "../components/TableFooter.vue";

export default {
  components: {
    "table-footer": TableFooter,
  },
  data: () => ({
    page: 1,
    pageCount: 0,
    itemsPerPage: 10,
    currentId: 0,
    dialog: false,
    selected: [],
    cloneItems: [],
  }),
  props: {
    items: Array,
    search: String,
    headers: Array,
  },
  created() {
    this.cloneItems = _.cloneDeep(this.items);
  },
  methods: {
    openDialog(id) {
      this.dialog = true;
      this.currentId = id;
    },
    deleteUser() {
      axios
        .delete(this.$root.route("admin.users.delete", this.currentId))
        .then((res) => {
          this.dialog = false;
          this.$snackbar.showMessage({
            content: "Benutzer gelöscht",
            color: "primary",
          });
          this.cloneItems.splice(this.cloneItems.findIndex(
            (elem) => elem.id === this.currentId
          ), 1);
        })
        .catch(() => {
          this.dialog = false;
          this.$snackbar.showMessage({
            content: "Fehler beim Löschen",
            color: "error",
          });
        });
    },

    restoreUser(id) {
      axios
        .post(this.$root.route("admin.users.restore", id))
        .then((res) => {
          this.$snackbar.showMessage({
            content: "Benutzer wieder hergestellt!",
            color: "primary",
          });
          this.cloneItems.find((elem) => elem.id === id).deleted_at = null;
        })
        .catch(() => {
          this.dialog = false;
          this.$snackbar.showMessage({
            content: "Fehler beim Löschen",
            color: "error",
          });
        });
    },
  },
};
</script>

<style lang="scss" scoped>
</style>