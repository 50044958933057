<template>
  <div>
    <transition name="slide">
      <v-card elevation="2" class="mr-8 pr-16 mb-8 filter-card" v-if="show">
        <v-btn fab absolute x-large color="primary" class="filter-card-fab"
          ><v-icon size="40">mdi-filter-plus</v-icon></v-btn
        >
        <v-card-text>
          <v-form ref="filterForm" v-model="valid">
            <v-row>
              <v-col cols="12" md="6">
                <v-row class="ma-0">
                  <v-text-field
                    v-model="filterName"
                    :loading="loadingName"
                    filled
                    dense
                    :rules="validationRules"
                    hide-details="auto"
                    label="Filter Name"
                    required
                    @input="isPublicEdit = false"
                  ></v-text-field>
                </v-row>
                <v-row>
                  <div :class="{'px-4': isAdmin}">
                    <v-switch
                      v-if="isAdmin"
                      dense
                      class="my-2 inputSwitch"
                      v-model="switchPublic"
                      inset
                      :label="`Filter veröffentlichen? ${
                        switchPublic ? 'Ja' : 'Nein'
                      }`"
                      hide-details="auto"
                    ></v-switch>
                  </div>
                  <div class="px-4">
                    <v-switch
                      dense
                      class="my-2 inputSwitch"
                      v-model="switchDefault"
                      inset
                      :label="`Als Standard setzen? ${
                        switchDefault ? 'Ja' : 'Nein'
                      }`"
                      hide-details="auto"
                    >
                    </v-switch>
                  </div>
                  <v-spacer></v-spacer>
                  <div class="px-4" v-if="isEdit">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-hover v-slot="{ hover }">
                          <v-btn
                            v-bind="attrs"
                            v-on="on"
                            icon
                            @click="showDeleteDialog = true"
                            :color="hover ? 'error' : 'secondary'"
                            class="mt-1"
                          >
                            <v-icon> mdi-delete </v-icon>
                          </v-btn>
                        </v-hover>
                      </template>
                      <span>Geladenen Filter löschen</span>
                    </v-tooltip>
                  </div>
                </v-row>
              </v-col>
              <v-spacer></v-spacer>
              <v-col cols="12" md="6">
                <v-row class="ma-0">
                  <v-autocomplete
                    dense
                    filled
                    clearable
                    hide-details="auto"
                    label="Einstellungen laden von:"
                    :items="filters"
                    item-value="id"
                    v-model="currentFilter"
                    @change="loadFilter"
                  ></v-autocomplete>
                </v-row>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="6">
                <h3 class="primary--text mb-4">alle Bedingungen treffen zu:</h3>
                <v-row
                  justify="center"
                  align="center"
                  class="py-4"
                  v-if="conditionAll.length == 0"
                >
                  <v-btn
                    x-small
                    color="primary"
                    min-width="20px"
                    max-width="20px"
                    class="mr-2"
                    @click="addCondition(0, 'all')"
                  >
                    <v-icon>mdi-plus</v-icon>
                  </v-btn>
                  <v-btn
                    x-small
                    color="error"
                    min-width="20px"
                    max-width="20px"
                    @click="removeCondition(0, 'all')"
                  >
                    <v-icon>mdi-close</v-icon>
                  </v-btn></v-row
                >
                <v-row v-for="(condition, index) in conditionAll" :key="index">
                  <v-col>
                    <v-autocomplete
                      dense
                      filled
                      clearable
                      hide-details="auto"
                      :rules="validationRules"
                      :items="fields"
                      item-value="id"
                      :item-text="
                        (item) =>
                          `${item.portal_field_category.name}: ${item.field_name}`
                      "
                      v-model="condition.field"
                      required
                      @change="setDataType(condition)"
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="2" v-if="condition.data_type !== 'text'">
                    <v-select
                      class="condition-select mt-0"
                      hide-details="auto"
                      dense
                      height="100%"
                      full-width
                      value="e"
                      v-model="condition.operator"
                      :menu-props="{
                        'content-class': 'condition-select-menu',
                        bottom: true,
                        'offset-y': true,
                      }"
                      hide-selected
                      :items="operators.number"
                    >
                      <template v-slot:selection="{ item }">
                        <v-icon
                          color="white"
                          v-if="item.hasOwnProperty('icon')"
                          >{{ item.icon }}</v-icon
                        >
                        <span v-else class="white--text body-2 text-center">{{
                          item.text
                        }}</span>
                      </template>
                      <template v-slot:item="{ item }">
                        <v-icon v-if="item.hasOwnProperty('icon')">{{
                          item.icon
                        }}</v-icon>
                        <span class="body-2" v-else>{{ item.text }}</span>
                      </template>
                    </v-select>
                  </v-col>
                  <v-col cols="2" v-else>
                    <v-select
                      class="condition-select mt-0"
                      hide-details="auto"
                      dense
                      height="100%"
                      full-width
                      value="e"
                      v-model="condition.operator"
                      :menu-props="{
                        'content-class': 'condition-select-menu',
                        bottom: true,
                        'offset-y': true,
                      }"
                      hide-selected
                      :items="operators.text"
                    >
                      <template v-slot:selection="{ item }">
                        <span class="white--text body-2 text-center">{{
                          item.text
                        }}</span>
                      </template>
                      <template v-slot:item="{ item }">
                        <span class="body-2">{{ item.text }}</span>
                      </template>
                      <template v-slot:append-outer>
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              class="mt-n1"
                              small
                              v-bind="attrs"
                              v-on="on"
                              color="info"
                              icon
                            >
                              <v-icon> mdi-help-circle-outline </v-icon>
                            </v-btn>
                          </template>
                          <span>% als Platzhalter verwenden</span>
                        </v-tooltip>
                      </template>
                    </v-select>
                  </v-col>
                  <template>
                    <v-col v-if="condition.data_type == 'date'">
                      <v-menu
                        ref="menu"
                        v-model="condition.menu"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            prepend-inner-icon="mdi-calendar"
                            readonly
                            filled
                            dense
                            hide-details="auto"
                            :disabled="
                              condition.operator === 'null' ||
                              condition.operator === 'not_null'
                            "
                            :value="$filters.formatDate(condition.value)"
                            v-bind="attrs"
                            v-on="on"
                          >
                            <template v-slot:append-outer>
                              <v-btn
                                x-small
                                color="primary"
                                min-width="20px"
                                max-width="20px"
                                class="mr-2"
                                @click="addCondition(index, 'all')"
                              >
                                <v-icon>mdi-plus</v-icon>
                              </v-btn>
                              <v-btn
                                x-small
                                color="error"
                                min-width="20px"
                                max-width="20px"
                                @click="removeCondition(index, 'all')"
                              >
                                <v-icon>mdi-close</v-icon>
                              </v-btn>
                            </template>
                          </v-text-field>
                        </template>
                        <v-card flat>
                          <v-card-text>
                            <v-date-picker
                              v-model="condition.value"
                              color="secondary"
                              first-day-of-week="1"
                              landscape
                              locale="de-DE"
                            >
                            </v-date-picker>
                          </v-card-text>
                        </v-card>
                      </v-menu>
                    </v-col>
                    <v-col v-else>
                      <v-text-field
                        dense
                        filled
                        hide-details="auto"
                        :disabled="
                          condition.operator === 'null' ||
                          condition.operator === 'not_null'
                        "
                        v-model="condition.value"
                        required
                      >
                        <template v-slot:append-outer>
                          <v-btn
                            x-small
                            color="primary"
                            min-width="20px"
                            max-width="20px"
                            class="mr-2"
                            @click="addCondition(index, 'all')"
                          >
                            <v-icon>mdi-plus</v-icon>
                          </v-btn>
                          <v-btn
                            x-small
                            color="error"
                            min-width="20px"
                            max-width="20px"
                            @click="removeCondition(index, 'all')"
                          >
                            <v-icon>mdi-close</v-icon>
                          </v-btn>
                        </template>
                      </v-text-field>
                    </v-col>
                  </template>
                </v-row>
              </v-col>
              <v-col cols="12" md="6">
                <h3 class="primary--text mb-4">
                  mindestens eine Bedingung trifft zu:
                </h3>
                <v-row
                  justify="center"
                  align="center"
                  class="py-4"
                  v-if="conditionOne.length == 0"
                >
                  <v-btn
                    x-small
                    color="primary"
                    min-width="20px"
                    max-width="20px"
                    class="mr-2"
                    @click="addCondition(0, 'one')"
                  >
                    <v-icon>mdi-plus</v-icon>
                  </v-btn>
                  <v-btn
                    x-small
                    color="error"
                    min-width="20px"
                    max-width="20px"
                    @click="removeCondition(0, 'one')"
                  >
                    <v-icon>mdi-close</v-icon>
                  </v-btn></v-row
                >
                <v-row v-for="(condition, index) in conditionOne" :key="index">
                  <v-col>
                    <v-autocomplete
                      dense
                      filled
                      clearable
                      hide-details="auto"
                      :rules="validationRules"
                      :items="fields"
                      item-value="id"
                      :item-text="
                        (item) =>
                          `${item.portal_field_category.name}: ${item.field_name}`
                      "
                      v-model="condition.field"
                      required
                      @change="setDataType(condition)"
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="2" v-if="condition.data_type !== 'text'">
                    <v-select
                      class="condition-select mt-0"
                      hide-details="auto"
                      dense
                      height="100%"
                      full-width
                      value="e"
                      v-model="condition.operator"
                      :menu-props="{
                        'content-class': 'condition-select-menu',
                        bottom: true,
                        'offset-y': true,
                      }"
                      hide-selected
                      :items="operators.number"
                    >
                      <template v-slot:selection="{ item }">
                        <v-icon
                          color="white"
                          v-if="item.hasOwnProperty('icon')"
                          >{{ item.icon }}</v-icon
                        >
                        <span v-else class="white--text body-2 text-center">{{
                          item.text
                        }}</span>
                      </template>
                      <template v-slot:item="{ item }">
                        <v-icon v-if="item.hasOwnProperty('icon')">{{
                          item.icon
                        }}</v-icon>
                        <span class="body-2" v-else>{{ item.text }}</span>
                      </template>
                    </v-select>
                  </v-col>
                  <v-col cols="2" v-else>
                    <v-select
                      class="condition-select mt-0"
                      hide-details="auto"
                      dense
                      height="100%"
                      full-width
                      value="e"
                      v-model="condition.operator"
                      :menu-props="{
                        'content-class': 'condition-select-menu',
                        bottom: true,
                        'offset-y': true,
                      }"
                      hide-selected
                      :items="operators.text"
                    >
                      <template v-slot:selection="{ item }">
                        <span class="white--text body-2 text-center">{{
                          item.text
                        }}</span>
                      </template>
                      <template v-slot:item="{ item }">
                        <span class="body-2">{{ item.text }}</span>
                      </template>
                      <template v-slot:append-outer>
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              class="mt-n1"
                              small
                              v-bind="attrs"
                              v-on="on"
                              color="info"
                              icon
                            >
                              <v-icon> mdi-help-circle-outline </v-icon>
                            </v-btn>
                          </template>
                          <span>% als Platzhalter verwenden</span>
                        </v-tooltip>
                      </template>
                    </v-select>
                  </v-col>
                  <template>
                    <v-col v-if="condition.data_type == 'date'">
                      <v-menu
                        ref="menu"
                        v-model="condition.menu"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            prepend-inner-icon="mdi-calendar"
                            readonly
                            filled
                            dense
                            :disabled="
                              condition.operator === 'null' ||
                              condition.operator === 'not_null'
                            "
                            hide-details="auto"
                            :value="$filters.formatDate(condition.value)"
                            v-bind="attrs"
                            v-on="on"
                          >
                            <template v-slot:append-outer>
                              <v-btn
                                x-small
                                color="primary"
                                min-width="20px"
                                max-width="20px"
                                class="mr-2"
                                @click="addCondition(index, 'one')"
                              >
                                <v-icon>mdi-plus</v-icon>
                              </v-btn>
                              <v-btn
                                x-small
                                color="error"
                                min-width="20px"
                                max-width="20px"
                                @click="removeCondition(index, 'one')"
                              >
                                <v-icon>mdi-close</v-icon>
                              </v-btn>
                            </template>
                          </v-text-field>
                        </template>
                        <v-card flat>
                          <v-card-text>
                            <v-date-picker
                              v-model="condition.value"
                              color="secondary"
                              first-day-of-week="1"
                              landscape
                              locale="de-DE"
                            >
                            </v-date-picker>
                          </v-card-text>
                        </v-card>
                      </v-menu>
                    </v-col>
                    <v-col v-else
                      ><v-text-field
                        dense
                        filled
                        hide-details="auto"
                        :disabled="
                          condition.operator === 'null' ||
                          condition.operator === 'not_null'
                        "
                        v-model="condition.value"
                        required
                      >
                        <template v-slot:append-outer>
                          <v-btn
                            x-small
                            color="primary"
                            min-width="20px"
                            max-width="20px"
                            class="mr-2"
                            @click="addCondition(index, 'one')"
                          >
                            <v-icon>mdi-plus</v-icon>
                          </v-btn>
                          <v-btn
                            x-small
                            color="error"
                            min-width="20px"
                            max-width="20px"
                            @click="removeCondition(index, 'one')"
                          >
                            <v-icon>mdi-close</v-icon>
                          </v-btn>
                        </template>
                      </v-text-field></v-col
                    >
                  </template>
                </v-row>
              </v-col>
            </v-row>
          </v-form>
          <table-field-draggable
            :disabled="tablesDisabled"
            :module="module"
          ></table-field-draggable>
        </v-card-text>
        <v-card-actions class="px-4 pb-4">
          <v-btn small @click="closeFilterCard">abbrechen</v-btn>
          <v-btn small @click="resetForm" color="warning"
            >Formular zurücksetzen</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="saveFilter"
            >Speichern + Anzeigen</v-btn
          >
        </v-card-actions>
        <v-overlay absolute v-model="loading">
          <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
      </v-card>
    </transition>
    <v-dialog v-model="showDialog" max-width="400">
      <v-card v-if="currentFilter !== null && !isPublicEdit">
        <v-toolbar color="info" dark flat>
          <v-toolbar-title>Hinweis</v-toolbar-title>
        </v-toolbar>
        <v-card-text class="pt-4 px-4">
          Ihre Einstellungen für diesen Filter werden mit den neuen
          Einstellungen überschrieben. Sind Sie sicher?
        </v-card-text>
        <v-card-actions>
          <v-btn color="error" @click.stop="showDialog = false" x-small
            >Nein</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            type="btn"
            @click="saveFilterFromDialog(false)"
            small
            >Ja</v-btn
          >
        </v-card-actions>
      </v-card>
      <v-card v-else-if="currentFilter !== null && isPublicEdit">
        <v-toolbar color="info" dark flat>
          <v-toolbar-title>Hinweis</v-toolbar-title>
        </v-toolbar>
        <v-card-text class="pt-4 px-4">
          Öffentliche Filter können nur mit Administrator-Rechten bearbeitet
          werden. Es wird ein neuer, privater Filter mit den gleichen
          Einstellungen erstellt.
        </v-card-text>
        <v-card-actions>
          <v-btn color="error" @click.stop="showDialog = false" x-small
            >Abbrechen</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            type="btn"
            @click="saveFilterFromDialog(true)"
            small
            >Ok</v-btn
          >
        </v-card-actions>
      </v-card>
      <v-card v-else>
        <v-toolbar color="info" dark flat>
          <v-toolbar-title>Hinweis</v-toolbar-title>
        </v-toolbar>
        <v-card-text class="pt-4 px-4">
          Sie haben bereits einen Filter "{{ filterName }}".<br />Um einen neuen
          Filter zu erstellen müssen Sie den Namen ändern oder den Filter laden
          und bearbeiten.
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click.stop="showDialog = false" small
            >Ok</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="showDeleteDialog" max-width="400">
      <v-card>
        <v-toolbar color="error" dark flat>
          <v-toolbar-title>Hinweis</v-toolbar-title>
        </v-toolbar>
        <v-card-text class="pt-4 px-4">
          Geladenen Filter wirklich löschen?
        </v-card-text>
        <v-card-actions>
          <v-btn color="error" @click.stop="showDeleteDialog = false" x-small
            >Nein</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn color="primary" type="btn" @click="deleteFilter" small
            >Ja</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import tableFieldDraggable from "./TableFieldDraggable.vue";

export default {
  components: {
    tableFieldDraggable,
  },
  data: () => ({
    switchDefault: false,
    tablesDisabled: false,
    switchPublic: false,
    filterName: "",
    loading: false,
    loadingName: false,
    valid: true,
    isEdit: false,
    isPublicEdit: false,
    showDialog: false,
    showDeleteDialog: false,
    isChanged: false,
    conditionAll: [],
    conditionOne: [],
    loadedFilter: null,
    loadedPublic: false,
    menus: {
      all: false,
      one: false,
    },
    operators: {
      number: [
        { value: "e", icon: "mdi-equal" },
        { value: "gt", icon: "mdi-greater-than" },
        { value: "gte", icon: "mdi-greater-than-or-equal" },
        { value: "lt", icon: "mdi-less-than" },
        { value: "lte", icon: "mdi-less-than-or-equal" },
        { value: "null", text: "ist leer" },
        { value: "not_null", text: "ist nicht leer" },
      ],
      text: [
        { value: "contains", text: "Enthält" },
        { value: "not_contains", text: "Enthält nicht" },
        { value: "null", text: "ist leer" },
        { value: "not_null", text: "ist nicht leer" },
      ],
    },

    validationRules: [(v) => !!v || "Bitte Feld ausfüllen"],
  }),
  props: {
    show: Boolean,
    module: String,
    isAdmin: Boolean
  },
  mounted() {
    this.loadFilter(this.currentFilter);
  },
  computed: {
    fields() {
      return _.sortBy(_.sortBy(this.$store.getters["fieldStore/getAllFields"].filter(field => _.map(this.categories, 'name').includes(field.portal_field_category.name)), field => field.field_name.toLowerCase()), 'portal_field_category.name');
    },

    categories() {
      let categories = _.flatten(
        _.partition(
          _.sortBy(
            _.uniqBy(
              _.map(
                this.$store.getters["fieldStore/getAllFields"],
                "portal_field_category"
              ),
              "id"
            ),
            "id"
          ),
          (o) =>
            o.name === "Windparks" ||
            o.name === "Windenergieanlagen" ||
            o.name === "Organisationen" ||
            o.name === "Photovoltaikparks" ||
            o.name === "Photovoltaikanlagen" ||
            o.name === "Brennstoffe" ||
            o.name === "Brennstoffanlagen"
        )
      );

      if (this.module === "Organisationen" || this.module === "Ansprechpartner") {
        categories = categories.filter(
          (elem) =>
            elem.name === "Organisationen" || elem.name === "Ansprechpartner"
        );
      } else {
        categories = categories.filter(
          (elem) =>
            elem.name !== "Organisationen" && elem.name !== "Ansprechpartner"
        );
      }

      if (
        this.module === "Photovoltaikparks" ||
        this.module === "Photovoltaikanlagen"
      ) {
        categories = categories.filter(
          (elem) =>
            elem.name !== "Windparks" &&
            elem.name !== "Windenergieanlagen" &&
            elem.name !== "Brennstoffe" &&
            elem.name !== "Brennstoffanlagen"
        );
      }

      if (
        this.module === "Brennstoffe" ||
        this.module === "Brennstoffanlagen"
      ) {
        categories = categories.filter(
          (elem) =>
            elem.name !== "Windparks" &&
            elem.name !== "Windenergieanlagen" &&
            elem.name !== "Photovoltaikparks" &&
            elem.name !== "Photovoltaikanlagen"
        );
      }

      if (this.module === "Windparks" || this.module === "Windenergieanlagen") {
        categories = categories.filter(
          (elem) =>
            elem.name !== "Brennstoffe" &&
            elem.name !== "Brennstoffanlagen" &&
            elem.name !== "Photovoltaikparks" &&
            elem.name !== "Photovoltaikanlagen"
        );
      }

      if (this.module === "Eigentümer") {
        categories = categories.filter(
          (elem) => !elem.name.includes("Betreiber")
        );
      }

      if (this.module === "Betreiber") {
        categories = categories.filter(
          (elem) => !elem.name.includes("Eigentümer")
        );
      }

      return categories;
    },

    filters() {
      return this.$store.state.filter.availableFilters.filter(
        (filter) =>
          filter.hasOwnProperty("header") || filter.module === this.module
      );
    },

    currentFilter: {
      get() {
        return this.$root.currentFilter;
      },
      set(value) {
        return;
      },
    },
  },
  watch: {
    show: {
      handler() {
        if (this.show) {
          this.loadFilter(this.currentFilter);
          this.checkIfEdit();
        }
      },
    },
    conditionAll: {
      handler() {
        this.disableTables();
      },
    },

    conditionOne: {
      handler() {
        this.disableTables();
      },
    },

    currentFilter: {
      handler() {
        if (this.show) this.loadFilter(this.currentFilter);
      },
    },
    filterName: {
      handler() {
        if (this.show) this.checkIfEdit();
      },
    },
    switchPublic: {
      handler() {
        if(this.switchPublic && !this.isAdmin) this.switchPublic = false;
      }
    }
  },
  methods: {
    disableTables() {
      if (this.loading) return;
      if (this.conditionAll.length > 0 || this.conditionOne.length > 0) {
        this.tablesDisabled = false;
        return;
      }

      this.$store.dispatch(
        "fieldStore/setSelectedFields",
        this.$store.state.fieldStore.selectedFields.filter(
          (field) => field.portal_field_category.name === this.module
        )
      );

      this.tablesDisabled = true;
    },

    resetForm() {
      this.$refs.filterForm.reset();
      this.conditionAll = [];
      this.conditionOne = [];

      this.$store.dispatch("fieldStore/setSelectedFields", []);
    },

    setDataType(condition) {
      condition["data_type"] = this.$store.getters[
        "fieldStore/getDataTypeById"
      ](condition.field);
      switch (condition.data_type) {
        case "text":
          condition.operator = "contains";
          break;
        case "number":
          condition.operator = "e";
          break;
        case "date":
          condition.value = null;
          condition.operator = "e";
          break;
        default:
          break;
      }
    },
    checkIfEdit() {
      this.loadingName = true;

      axios
        .get(this.$root.route("filter.isEdit"), {
          params: {
            name: this.filterName,
          },
        })
        .then(() => {
          this.loadingName = false;
          this.isEdit = true;
        })
        .catch(() => {
          this.loadingName = false;
          this.isEdit = false;
        });
    },
    addCondition(index, type) {
      if (type === "all") {
        this.conditionAll.splice(index + 1, 0, {
          field: "",
          operator: "e",
          value: "",
        });
      }

      if (type === "one") {
        this.conditionOne.splice(index + 1, 0, {
          field: "",
          operator: "e",
          value: "",
        });
      }
    },

    removeCondition(index, type) {
      if (type === "all") {
        this.conditionAll.splice(index, 1);
      }
      if (type === "one") {
        this.conditionOne.splice(index, 1);
      }
    },

    loadFilter(filterId) {
      if (filterId === "" || filterId === null || filterId === 0) return;

      this.conditionAll = [];
      this.conditionOne = [];

      this.loading = true;
      axios
        .post(`/filter/${filterId}`, {
          fieldIds: _.map(this.$store.getters["fieldStore/getAllFields"], "id"),
        })
        .then((res) => {
          if (res.data.module !== this.module || res.data.global) {
            this.$store.dispatch(
              "fieldStore/setSelectedFields",
              res.data.filterFields
            );
            if (this.conditionAll.length == 0 && this.conditionOne.length == 0)
              this.tablesDisabled = true;
            this.loading = false;
            return;
          }

          this.$store.dispatch(
            "fieldStore/setSelectedFields",
            res.data.filterFields
          );

          this.filterName = res.data.filterName;
          this.switchPublic = res.data.public;
          this.switchDefault = res.data.default;

          this.loadedFilter = filterId;
          this.isPublicEdit = res.data.isPublicEdit;

          for (let condition of res.data.filterConditions) {
            let newCondition = {
              field: condition.portal_field_id,
              operator: condition.operator,
              value: condition.value,
              data_type: condition.portal_field.data_type,
            };

            if (condition.conjunction == "or") {
              this.conditionOne.push(newCondition);
              continue;
            }

            if (condition.conjunction == "and") {
              this.conditionAll.push(newCondition);
              continue;
            }
          }

          if (this.conditionAll.length == 0 && this.conditionOne.length == 0)
            this.tablesDisabled = true;
          this.loading = false;
        })
        .catch(() => {
          this.$snackbar.showMessage({
            content: "Fehler beim Laden!",
            color: "error",
          });
          this.loading = false;
        });
    },

    saveFilter() {
      this.$refs.filterForm.validate();

      if (this.valid && !this.isEdit && !this.isPublicEdit) {
        this.saveRequest(false);
      } else {
        this.showDialog = true;
      }
    },

    saveFilterFromDialog(makeCopy) {
      this.showDialog = false;
      if (this.valid) {
        this.saveRequest(makeCopy);
      }
    },

    saveRequest(makeCopy) {
      axios
        .post(`/filter`, {
          name: this.filterName + (makeCopy ? " (Privat)" : ""),
          public: this.switchPublic,
          default: this.switchDefault,
          conditionsAnd: this.conditionAll,
          conditionsOr: this.conditionOne,
          module: this.module,
          selectedFields: this.$store.state.fieldStore.selectedFields,
        })
        .then((res) => {
          this.$store.dispatch("filter/getAllFilters", res.data.id);
          this.$store.dispatch("filter/setCurrent", res.data.id);
          this.$emit("close-card");
        })
        .catch(() => {
          this.$snackbar.showMessage({
            content: "Fehler beim Speichern!",
            color: "error",
          });
        });
    },

    deleteFilter() {
      axios
        .delete(this.$root.route("filter.delete", this.loadedFilter))
        .then((res) => {
          this.$store.dispatch("filter/getAllFilters", res.data.id);
          this.showDeleteDialog = false;

          this.resetForm();

          this.$snackbar.showMessage({
            content: "Filter gelöscht!",
            color: "primary",
          });
          // this.$store.dispatch("filter/setCurrent", res.data.id);
        })
        .catch(() => {
          this.$snackbar.showMessage({
            content: "Fehler beim Löschen!",
            color: "error",
          });
        });
    },

    closeFilterCard() {
      this.$emit("close-card");
    },
  },
};
</script>

<style lang="scss" scoped>
.condition-select::v-deep {
  border-radius: 4px;
  .v-input__append-outer {
    margin-left: 4px;
  }
  .v-input__control {
    .v-input__slot::before,
    .v-input__slot::after {
      content: unset;
    }
    .v-input__slot {
      background: #ffc107;

      .v-select__slot {
        .v-select__selections {
          justify-content: center;
          align-items: center;
          input {
            display: none;
          }
        }
        .v-input__append-inner {
          display: none;
        }
      }
    }
  }
}

.v-input::v-deep {
  .v-input__append-outer {
    pointer-events: all;
  }
}

.filter-card {
  position: absolute;
  left: 0;
  right: 0;
  top: 5px;
  z-index: 15;

  &-fab {
    right: 0;
    top: 16px;
    transform: translateX(50%);
  }
}

.inputSwitch {
  min-width: 250px;

  &::v-deep .v-label {
    font-size: 14px;
  }
}

.slide-enter-active,
.slide-leave-active {
  transition: top 0.5s, opacity 0.5s;
}
.slide-enter,
.slide-leave-to {
  opacity: 0;
  top: -50%;
}
.condition-select-menu::v-deep {
  box-shadow: unset;

  .v-list {
    padding: 0;
    .v-list-item {
      margin: 4px 0;
      padding: 0;
      border-radius: 4px;
      justify-content: center;

      &::before {
        border-radius: 4px;
      }
    }
  }
}
</style>