var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{attrs:{"item-key":"id","search":_vm.search,"headers":_vm.headers,"items":_vm.cloneItems,"items-per-page":_vm.tableOptions.itemsPerPage,"sort-by":_vm.tableOptions.sortBy,"sort-desc":_vm.tableOptions.sortDesc,"page":_vm.tableOptions.page,"dense":"","locale":"de-DE","hide-default-footer":"","loading":_vm.loading,"footer-props":{
      itemsPerPageText: 'Zeilen pro Seite: ',
      itemsPerPageOptions: [10, 25, 50, -1],
    }},on:{"update:itemsPerPage":function($event){return _vm.$set(_vm.tableOptions, "itemsPerPage", $event)},"update:items-per-page":function($event){return _vm.$set(_vm.tableOptions, "itemsPerPage", $event)},"update:sortBy":function($event){return _vm.$set(_vm.tableOptions, "sortBy", $event)},"update:sort-by":function($event){return _vm.$set(_vm.tableOptions, "sortBy", $event)},"update:sortDesc":function($event){return _vm.$set(_vm.tableOptions, "sortDesc", $event)},"update:sort-desc":function($event){return _vm.$set(_vm.tableOptions, "sortDesc", $event)},"update:page":function($event){return _vm.$set(_vm.tableOptions, "page", $event)},"page-count":function($event){_vm.pageCount = $event}},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
    var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"info","href":_vm.route('berichte.view', item.id)}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-eye ")])],1)]}}],null,true)},[_vm._v(" "),_c('span',[_vm._v("Anzeigen")])]),_vm._v(" "),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"warning","href":_vm.route('berichte.edit', item.id)}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-pencil ")])],1)]}}],null,true)},[_vm._v(" "),_c('span',[_vm._v("Editieren")])]),_vm._v(" "),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","href":_vm.route('berichte.copy', item.id)}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-content-copy ")])],1)]}}],null,true)},[_vm._v(" "),_c('span',[_vm._v("Kopieren")])]),_vm._v(" "),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"error"},on:{"click":function($event){$event.stopPropagation();return _vm.openDialog(item.id)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-delete ")])],1)]}}],null,true)},[_vm._v(" "),_c('span',[_vm._v("Löschen")])])]}},{key:"footer",fn:function(ref){
      var ref_props = ref.props;
      var pagination = ref_props.pagination;
      var itemsPerPageText = ref_props.itemsPerPageText;
      var itemsPerPageOptions = ref_props.itemsPerPageOptions;
return [_c('table-footer',{attrs:{"pagination":pagination,"itemsPerPageText":itemsPerPageText,"itemsPerPageOptions":itemsPerPageOptions,"tableOptions":_vm.tableOptions,"pageCount":_vm.pageCount}})]}}])}),_vm._v(" "),_c('v-dialog',{attrs:{"max-width":"400"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-toolbar',{attrs:{"color":"primary","dark":"","flat":""}},[_c('v-toolbar-title',[_vm._v("Bericht löschen?")])],1),_vm._v(" "),_c('v-card-actions',[_c('v-btn',{attrs:{"color":"secondary","x-small":""},on:{"click":function($event){$event.stopPropagation();_vm.dialog = false}}},[_vm._v("Abbrechen")]),_vm._v(" "),_c('v-spacer'),_vm._v(" "),_c('v-btn',{attrs:{"color":"error","type":"btn","small":""},on:{"click":function($event){return _vm.deleteBericht()}}},[_vm._v("Löschen")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }