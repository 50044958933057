var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{attrs:{"show-select":"","hide-default-footer":"","headers":_vm.headers,"items":_vm.cloneItems,"item-key":"id","search":_vm.search,"options":_vm.tableOptions,"page":_vm.page,"dense":"","footer-props":{
      itemsPerPageText: 'Zeilen pro Seite: ',
      itemsPerPageOptions: [5, 10, 15, 30, -1],
    }},on:{"update:page":function($event){_vm.page=$event},"page-count":function($event){_vm.pageCount = $event}},scopedSlots:_vm._u([{key:"item.field_name",fn:function(ref){
    var item = ref.item;
return [_c('v-edit-dialog',{attrs:{"return-value":item.field_name},on:{"update:returnValue":function($event){return _vm.$set(item, "field_name", $event)},"update:return-value":function($event){return _vm.$set(item, "field_name", $event)},"save":function($event){return _vm.save(item)},"cancel":_vm.cancel},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('v-form',{ref:"editForm",model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-text-field',{staticClass:"pb-4 mt-3",attrs:{"rules":_vm.nameRules,"label":"Feldnamen eintragen","hide-details":"auto"},model:{value:(item.field_name),callback:function ($$v) {_vm.$set(item, "field_name", $$v)},expression:"item.field_name"}})],1)]},proxy:true}],null,true)},[_vm._v("\n        "+_vm._s(item.field_name)+"\n        ")])]}},{key:"item.route",fn:function(ref){
    var item = ref.item;
return [_c('v-edit-dialog',{attrs:{"return-value":item.route},on:{"update:returnValue":function($event){return _vm.$set(item, "route", $event)},"update:return-value":function($event){return _vm.$set(item, "route", $event)},"save":function($event){return _vm.save(item)},"cancel":_vm.cancel},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('v-text-field',{staticClass:"pb-4 mt-3",attrs:{"label":"Route eintragen","hide-details":"auto"},model:{value:(item.route),callback:function ($$v) {_vm.$set(item, "route", $$v)},expression:"item.route"}})]},proxy:true}],null,true)},[_vm._v("\n        "+_vm._s(item.route)+"\n        ")])]}},{key:"item.api_fields",fn:function(ref){
    var item = ref.item;
return [_c('v-row',{staticStyle:{"column-gap":"15px"}},_vm._l((item.api_fields),function(elem,index){return _c('v-chip',{key:index,attrs:{"color":item.portal_field_category.color}},[_vm._v(_vm._s(elem.field_name)+"\n        ")])}),1)]}},{key:"item.actions",fn:function(ref){
    var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","href":_vm.route('admin.fields.details', item.id)}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-pencil ")])],1)]}}],null,true)},[_vm._v(" "),_c('span',[_vm._v("Editieren")])]),_vm._v(" "),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"error"},on:{"click":function($event){$event.stopPropagation();return _vm.openDialog(item.id)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-delete ")])],1)]}}],null,true)},[_vm._v(" "),_c('span',[_vm._v("Löschen")])])]}},{key:"footer",fn:function(ref){
      var ref_props = ref.props;
      var pagination = ref_props.pagination;
      var itemsPerPageText = ref_props.itemsPerPageText;
      var itemsPerPageOptions = ref_props.itemsPerPageOptions;
      var options = ref_props.options;
return [_c('table-footer',{attrs:{"pagination":pagination,"itemsPerPageText":itemsPerPageText,"itemsPerPageOptions":itemsPerPageOptions,"tableOptions":_vm.tableOptions,"pageCount":_vm.pageCount}})]}}]),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}}),_vm._v(" "),_c('v-dialog',{attrs:{"max-width":"400"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-toolbar',{attrs:{"color":"primary","dark":"","flat":""}},[_c('v-toolbar-title',[_vm._v("Feld löschen?")])],1),_vm._v(" "),_c('v-card-actions',[_c('v-btn',{attrs:{"color":"secondary","x-small":""},on:{"click":function($event){$event.stopPropagation();_vm.dialog = false}}},[_vm._v("Abbrechen")]),_vm._v(" "),_c('v-spacer'),_vm._v(" "),_c('v-btn',{attrs:{"color":"error","type":"btn","small":""},on:{"click":function($event){return _vm.deleteField()}}},[_vm._v("Löschen")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }