var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{attrs:{"show-select":"","hide-default-footer":"","headers":_vm.headers,"items":_vm.items,"item-key":"id","search":_vm.search,"options":{ itemsPerPage: _vm.itemsPerPage },"page":_vm.page,"dense":"","footer-props":{
    itemsPerPageText: 'Zeilen pro Seite: ',
    itemsPerPageOptions: [5, 10, 15, 30, -1],
  }},on:{"update:page":function($event){_vm.page=$event},"page-count":function($event){_vm.pageCount = $event}},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
  var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
  var on = ref.on;
  var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","href":_vm.route('admin.panels.details', item.id)}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-pencil ")])],1)]}}],null,true)},[_vm._v(" "),_c('span',[_vm._v("Editieren")])])]}},{key:"footer",fn:function(ref){
    var ref_props = ref.props;
    var pagination = ref_props.pagination;
    var itemsPerPageText = ref_props.itemsPerPageText;
    var itemsPerPageOptions = ref_props.itemsPerPageOptions;
    var options = ref_props.options;
return [_c('table-footer',{attrs:{"pagination":pagination,"itemsPerPageText":itemsPerPageText,"itemsPerPageOptions":itemsPerPageOptions,"tableOptions":options,"pageCount":_vm.pageCount}})]}}]),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})}
var staticRenderFns = []

export { render, staticRenderFns }