<template>
  <v-row class="mx-0 mt-4" align="center">
    <v-chip label
      >Zeige {{ pagination.pageStart + 1 }}-{{ pagination.pageStop }} von
      {{ pagination.itemsLength }}</v-chip
    >
    <v-spacer></v-spacer>
    <div>
      {{ itemsPerPageText }}
    </div>
    <v-col cols="2" lg="1">
      <v-select
        filled
        dense
        hide-details
        :value="tableOptions.itemsPerPage"
        :items="itemsPerPageOptions"
        @input="tableOptions.itemsPerPage = parseInt($event, 10)"
      >
        <template v-slot:item="{ item }">
          {{ item === -1 ? "Alle" : item }}
        </template>
        <template v-slot:selection="{ item }">
          {{ item === -1 ? "Alle" : item }}
        </template>
      </v-select>
    </v-col>
    <v-pagination
      :total-visible="$vuetify.breakpoint.mdAndDown ? 12 : 9"
      v-model="tableOptions.page"
      :length="pageCount"
      class="flex-grow-1 flex-lg-grow-0"
    ></v-pagination>
  </v-row>
</template>

<script>
export default {
  props: {
    pagination: Object,
    itemsPerPageText: String,
    itemsPerPageOptions: Array,
    tableOptions: Object,
    pageCount: Number,
  },
};
</script>

<style lang="scss" scoped>
</style>