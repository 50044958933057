<template>
  <v-row>
    <v-col cols="12" lg="3">
      <h3 class="mb-4">Verfügbare Felder</h3>
      <v-list dense elevation="2">
        <v-list-item-group>
          <draggable :list="fields" group="fields" class="draggable-wrapper">
            <v-list-item v-for="(element, index) in fields" :key="element.id">
              <v-list-item-content>
                <v-list-item-title
                  v-text="element.field_name"
                ></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </draggable>
        </v-list-item-group>
      </v-list>
    </v-col>
    <v-col cols="4" lg="3" v-for="(i, index) in 3" :key="i">
      <h3 class="mb-4">Spalte {{ i }}</h3>
      <v-list dense elevation="2">
        <v-list-item-group>
          <draggable
            :list="cloneColumns[index].portal_fields"
            group="fields"
            class="draggable-wrapper"
          >
            <v-list-item
              v-for="element in cloneColumns[index].portal_fields"
              :key="element.id"
            >
              <v-list-item-content>
                <v-list-item-title
                  v-text="element.field_name"
                ></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </draggable>
        </v-list-item-group>
      </v-list>
    </v-col>
  </v-row>
</template>

<script>
import draggable from "vuedraggable";

export default {
  components: {
    draggable,
  },
  data: () => ({
    fields: [],
    cloneColumns: []
  }),
  created() {
    this.fields = _.sortBy([
      ...this.$store.getters["fieldStore/getAllFieldsByCategoryName"](
        this.module
      ),
    ], field => field.field_name.toLowerCase());

    this.cloneColumns = _.cloneDeep(this.columns)

    while (this.cloneColumns.length < 3) {
      this.cloneColumns.push({
        portal_fields: [],
      });
    }
  },
  props: {
    module: String,
    index: Number,
    columns: Array,
  },
  watch: {
    cloneColumns: {
      handler() {
        this.$emit("change", [this.cloneColumns, this.index]);
      },
      deep: true,
    },
    module: {
      handler() {
        this.fields = [
          ...this.$store.getters["fieldStore/getAllFieldsByCategoryName"](
            this.module
          ),
        ];
        this.cloneColumns = []

        while (this.cloneColumns.length < 3) {
          this.cloneColumns.push({
            portal_fields: [],
          });
        }
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.v-list {
  height: 400px;
  overflow: scroll;

  .v-list-item-group {
    height: 100%;
    .draggable-wrapper {
      height: 100%;
    }
  }
}
</style>