<template>
  <div>
    <v-data-iterator
      class="d-none"
      v-if="itemsLoaded"
      :search="search"
      :items="items"
      :items-per-page="-1"
      :sort-by="tableOptions.sortBy"
      :sort-desc="tableOptions.sortDesc"
      locale="de-DE"
      hide-default-footer
      :footer-props="{
        itemsPerPageText: 'Zeilen pro Seite: ',
        itemsPerPageOptions: [-1],
      }"
      @current-items="cloneItems = $event"
    >
    </v-data-iterator>
    <v-data-table
      v-if="itemsLoaded"
      v-model="selected"
      show-select
      item-key="uuid"
      :search="search"
      :headers="headers"
      :items="items"
      :item-class="setCursor"
      :items-per-page.sync="tableOptions.itemsPerPage"
      :sort-by.sync="tableOptions.sortBy"
      :sort-desc.sync="tableOptions.sortDesc"
      :page.sync="tableOptions.page"
      @page-count="pageCount = $event"
      dense
      locale="de-DE"
      hide-default-footer
      :loading="loading"
      :footer-props="{
        itemsPerPageText: 'Zeilen pro Seite: ',
        itemsPerPageOptions: [10, 25, 50, 100],
      }"
      @current-items="$emit('current-items', $event)"
      @click:row="openDetails"
    >
      <template
        v-for="header in headers"
        v-slot:[`item.${header.value}`]="{ value }"
      >
        <template v-if="header.data_type == 'date'">
          {{ value | formatDate }}
        </template>
        <template v-else-if="header.data_type == 'number'">
          {{ value | formatNumber }}
        </template>
        <template v-else-if="header.data_type == 'coordinate'">
          {{ value | formatCoordinate }}
        </template>
        <template v-else>
          {{ value }}
        </template>
      </template>
      <template
        v-slot:footer="{
          props: { pagination, itemsPerPageText, itemsPerPageOptions },
        }"
      >
        <table-footer
          :pagination="pagination"
          :itemsPerPageText="itemsPerPageText"
          :itemsPerPageOptions="itemsPerPageOptions"
          :tableOptions="tableOptions"
          :pageCount="pageCount"
        ></table-footer>
        <v-row>
          <code>
            {{ sql }}
          </code>
        </v-row>
      </template>
    </v-data-table>
    <div class="d-flex justify-center mt-16" v-else>
      <v-progress-circular
        indeterminate
        size="60"
        color="primary"
      ></v-progress-circular>
    </div>
    <v-dialog v-model="show" max-width="400">
      <v-card>
        <v-toolbar color="info" dark flat>
          <v-toolbar-title>Tabelle exportieren</v-toolbar-title>
        </v-toolbar>
        <v-card-text class="pt-4 px-4">
          <p v-if="$store.state.fieldStore.selectedFields.length > warningFields">
            Hinweis: auf Grund der Anzahl der zu exportierenden Daten kann der
            Export längere Zeit in Anspruch nehmen.
          </p>
          <v-form v-model="exportValid" ref="exportSettings">
            <v-text-field
              v-model="fileName"
              :rules="validationRules"
              filled
              dense
              hide-details="auto"
              label="Dateiname"
              class="mb-4"
            ></v-text-field>
            <v-select
              v-model="exportFormat"
              :rules="validationRules"
              filled
              dense
              hide-details="auto"
              :items="dropdownItems"
              label="Als Excel oder CSV exportieren?"
            ></v-select>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn color="error" @click.stop="$emit('close')" x-small
            >Abbrechen</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn color="primary" type="btn" small @click="exportData"
            >Exportieren</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="showMap"
      @click:outside.stop="$emit('close-map')"
      max-width="1080"
    >
      <v-card>
        <v-toolbar color="primary" dark flat>
          <v-toolbar-title>Karte</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon dark @click.stop="$emit('close-map')">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text class="pt-4 px-4">
          <table-map
            :items="selected.length > 0 ? selected : cloneItems"
            :module="module"
            :key="key"
          ></table-map>
        </v-card-text>
        <v-card-actions>
          <v-btn color="error" @click.stop="$emit('close-map')" x-small
            >Schließen</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import TableMap from "./TableMap.vue";
import TableFooter from "./TableFooter.vue";
import moment from "moment/dist/moment";

export default {
  components: {
    "table-map": TableMap,
    "table-footer": TableFooter,
  },
  data: () => ({
    pageCount: 0,
    key: 0,
    cloneItems: [],
    selected: [],
    itemsLoaded: false,
    tableOptions: {
      page: 1,
      itemsPerPage: 10,
      sortBy: [],
      sortDesc: [],
    },
    loading: false,
    loadingData: false,
    exportFormat: "",
    fileName: "",
    items: [],
    sql: "",
    exportValid: true,
    dropdownItems: [
      {
        text: "Excel (.xlsx)",
        value: "xlsx",
      },
      {
        text: "CSV (.csv)",
        value: "csv",
      },
    ],
    validationRules: [(v) => !!v || "Bitte Feld ausfüllen"],
  }),
  props: {
    search: String,
    show: Boolean,
    showMap: Boolean,
    module: String,
    moduleId: String,
  },

  created() {
    this.debouncedGetData = _.throttle(this.getData, 2000);

    this.loadLocalStorageTableOptions();
  },
  methods: {
    loadLocalStorageTableOptions() {
      if (localStorage.hasOwnProperty("tableOptions")) {
        const lsTableOptions = JSON.parse(localStorage.tableOptions);

        if (lsTableOptions.module === this.module)
          this.tableOptions = lsTableOptions;
      }
    },
    openDetails(row) {
      const index = _.findIndex(this.cloneItems, (item) => {
        return _.isEqual(item, row);
      });

      this.$store.dispatch("detailsPagination/setIndizes", index);
      window.location.href = this.$root.route(
        `${this.module.toLowerCase()}.details`,
        row.Id
      );
    },
    setCursor() {
      return "cursor-pointer table-item";
    },

    exportData() {
      this.$refs.exportSettings.validate();

      if (this.exportValid) {
        this.$emit("close");
        this.$exportSnackbar.showMessage({
          content: "Daten werden exportiert...",
          color: "info",
        });
        axios
          .post(
            this.$root.route("excel.export"),
            {
              items: this.selected.length > 0 ? this.selected : this.cloneItems,
              headings: this.headers,
              format: this.exportFormat,
            },
            {
              responseType: "blob",
            }
          )
          .then((res) => {
            const url = window.URL.createObjectURL(new Blob([res.data]));
            const link = document.createElement("a");
            link.href = url;
            const fileName = `${this.fileName}.${this.exportFormat}`;
            link.setAttribute("download", fileName);
            document.body.appendChild(link);
            link.click();
            link.remove();
            this.$exportSnackbar.closeMessage({
              content: "",
              color: "primary",
            });
          });
      }
    },

    getData() {
      this.loading = true;
      if (!this.loadingData && this.currentFilter) {
        this.loadingData = true;
        const { sortBy, sortDesc } = this.tableOptions;
        axios
          .post(
            this.$root.route(this.module.toLowerCase() + ".data"),
            {
              sortBy: sortBy[0],
              sortDesc: sortDesc[0],
              filter: this.currentFilter,
              id: this.moduleId ?? "",
            }
            // {
            //   onDownloadProgress: (evt) => {
            //     // console.log(evt.currentTarget.responseText);
            //   },
            // }
          )
          .then((res) => {
            this.loading = false;
            this.itemsLoaded = true;
            this.loadingData = false;
            this.items = res.data.data.map((items) => ({
              ...items,
              uuid: this.$uuid.v4(),
            }));
            // this.sql = res.data.sql;
            if (this.items.length < 1) {
              this.items = [];
            }

            return this.items;
          })
          .catch((error) => {
            this.loading = false;
            this.itemsLoaded = true;
            this.loadingData = false;
            this.$snackbar.showMessage({
              content: `<h2 class="mb-2">Ihre Abfrage wurde abgebrochen (Fehlercode ${error.response.status})</h2><p>Leider konnten anhand der Kombination Ihrer Filtereinstellungen keine Daten ermittelt werden oder die Ausgabe übersteigt die maximale Anzahl der Datensätze, die Abfrage der Filterdaten wurde abgebrochen.</p><p>Bitte passen Sie die Einstellungen des Filters an und versuchen es noch einmal. Sollte der Fehler weiterhin bestehen, wenden Sie sich bitte an unseren Support (support@energiemarktdatenbank.de) und teilen Sie uns den Fehlercode sowie den Namen Ihres Filters mit.</p><p>Vielen Dank.</p>`,
              color: "error",
            });
          });
      }
    },

    getHeaderSlug(categoryName) {
      if (categoryName === this.module) return "";

      switch (categoryName) {
        case "Ansprechpartner":
          return "ap.";
        case "Ansprechpartner Betreiber":
          return "betreiber.ap.";
        case "Ansprechpartner Eigentümer":
          return "eigentümer.ap.";
        case "Betreiber":
          return "betreiber.";
        case "Brennstoffanlagen":
          return "bsa.";
        case "Brennstoffe":
          return "brennstoff.";
        case "Eigentümer":
          return "eigentümer.";
        case "Organisationen":
          return "organisation.";
        case "Photovoltaikanlagen":
          return "pva.";
        case "Photovoltaikparks":
          return "pv_park.";
        case "Windenergieanlagen":
          return "wea.";
        case "Windparks":
          return "windpark.";
        default:
          break;
      }
    },
  },
  computed: {
    headers: {
      get() {
        return this.$store.state.fieldStore.selectedFields.map((field) => ({
          ...field,
          value:
            this.getHeaderSlug(field.portal_field_category.name) +
            field.table_value,
        }));
      },
    },
    currentFilter: {
      get() {
        return this.$root.currentFilter;
      },
    },
    warningFields: {
      get() {
        return process.env.MIX_WARNING_FIELDS
      }
    }
  },
  watch: {
    cloneItems: {
      handler() {
        this.key++;
        this.$store.dispatch(
          "detailsPagination/setItems",
          _.map(this.cloneItems, "Id")
        );
      },
    },
    selected: {
      handler() {
        this.$emit("current-items", this.selected);
        this.key++;
      },
    },
    tableOptions: {
      handler() {
        this.tableOptions.module = this.module;

        localStorage.tableOptions = JSON.stringify(this.tableOptions);
      },
      deep: true,
    },
    headers: {
      handler() {
        if (!this.$root.showFilterCard && !this.loadingData)
          this.headers.forEach((header) => {
            if (header.data_type === "date")
              header.sort = (a, b) => {
                if (
                  moment(a, "MMM DD YYYY HH:mm:ss:A").isBefore(
                    moment(b, "MMM DD YYYY HH:mm:ss:A")
                  )
                )
                  return -1;
                if (
                  moment(a, "MMM DD YYYY HH:mm:ss:A").isAfter(
                    moment(b, "MMM DD YYYY HH:mm:ss:A")
                  )
                )
                  return 1;
                if (
                  moment(a, "MMM DD YYYY HH:mm:ss:A").isSame(
                    moment(b, "MMM DD YYYY HH:mm:ss:A")
                  )
                )
                  return 0;
              };
          });
        this.debouncedGetData();
      },
      deep: true,
    },
    currentFilter: {
      handler() {
        localStorage.filter = this.currentFilter;
        this.selected = [];
        if (!this.$root.showFilterCard) this.debouncedGetData();
        if (this.$store.state.filter.default !== this.currentFilter) {
          this.tableOptions.page = 1;
          this.tableOptions.sortBy = [];
          this.tableOptions.sortDesc = [];
        }
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.v-data-table::v-deep {
  .v-data-table-header th {
    white-space: nowrap;
  }
  .cursor-pointer {
    cursor: pointer;
  }

  .table-item {
    white-space: nowrap !important;
  }
}
</style>