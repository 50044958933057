<template>
  <v-autocomplete
    dense
    filled
    clearable
    hide-details
    v-model="$root.currentFilter"
    item-value="id"
    :items="filters"
    placeholder="Filter anwenden"
  >
    <!-- <template v-slot:item="{ item }">
      <v-list-item-content>
        <v-list-item-title class="d-flex justify-space-between align-center">
          {{ item.text }}

          <v-tooltip bottom v-if="item.id !== $store.state.filter.default">
            <template v-slot:activator="{ on, attrs }">
              <v-icon color="secondary" dark v-bind="attrs" v-on="on" @click="setDefault(item.id)">
                mdi-star-outline
              </v-icon>
            </template>
            <span>Als Standard setzen</span>
          </v-tooltip>
          <v-tooltip bottom v-if="item.id === $store.state.filter.default">
            <template v-slot:activator="{ on, attrs }">
              <v-icon color="warning" dark v-bind="attrs" v-on="on">
                mdi-star
              </v-icon>
            </template>
            <span>Als Standard gesetzt</span>
          </v-tooltip>
        </v-list-item-title>
      </v-list-item-content>
    </template> -->
    <template v-slot:append-outer>
      <div class="v-input__icon v-input__icon--append-outer">
        <v-btn icon @click="$emit('toggle')">
          <v-icon>mdi-filter</v-icon>
        </v-btn>
      </div>
    </template>
  </v-autocomplete>
</template>          
          
<script>
export default {
  data: () => ({}),
  props: {
    module: String,
  },
  created() {
    if (
      this.$store.state.filter.defaults.length > 0 &&
      (!this.$root.currentFilter ||
        !this.filters.find((filter) => filter.id === this.$root.currentFilter))
    ) {
      this.$root.currentFilter = this.$store.getters[
        "filter/getDefaultFilterByModule"
      ](this.module).id;
    }
  },
  watch: {
    currentFilter(id) {
      this.$store.commit("filter/changeFilter", id);
    },

    "$store.state.filter.defaults": {
      handler() {
        if (
          this.$store.state.filter.defaults.length > 0 &&
          (!this.$root.currentFilter ||
            !this.filters.find(
              (filter) => filter.id === this.$root.currentFilter
            ))
        ) {
          this.$root.currentFilter = this.$store.getters[
            "filter/getDefaultFilterByModule"
          ](this.module).id;
        }
      },
    },
  },
  computed: {
    filters() {
      return this.$store.state.filter.availableFilters.filter(
        (filter) =>
          filter.hasOwnProperty("header") || filter.module === this.module
      );
    },
  },
};
</script>        

<style lang="scss" scoped>
</style>
