<template>
  <v-card v-if="bookmarks && bookmarks.length > 0" flat>
    <v-card-title class="primary--text"> Lesezeichen </v-card-title>
    <v-container class="py-0">
      <v-row dense v-for="bookmark in bookmarks" :key="bookmark.id">
        <v-col cols="12">
          <v-card>
            <v-card-title class="py-2">{{ bookmark.title }}</v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="primary"
                text
                link
                :href="route(bookmark.route, bookmark.bookmark_id)"
                >Zum Lesezeichen</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <v-card-actions> </v-card-actions>
  </v-card>
</template>

<script>
export default {
  data: () => ({
    bookmarks: null
  }),
  created() {
    axios
      .get("/bookmark")
      .then((res) => {
        this.bookmarks = res.data.bookmarks;
      })
      .catch(() => {
        this.$snackbar.showMessage({
          content: "Fehler beim Laden!",
          color: "error",
        });
      });
  },
};
</script>

<style lang="scss" scoped>
</style>