var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{attrs:{"hide-default-footer":"","headers":_vm.headers,"items":_vm.cloneItems,"item-key":"id","search":_vm.search,"options":{ itemsPerPage: _vm.itemsPerPage },"page":_vm.page,"dense":"","footer-props":{
      itemsPerPageText: 'Zeilen pro Seite: ',
      itemsPerPageOptions: [5, 10, 15, 30, -1],
    }},on:{"update:page":function($event){_vm.page=$event},"page-count":function($event){_vm.pageCount = $event}},scopedSlots:_vm._u([{key:"item.public",fn:function(ref){
    var item = ref.item;
return [(item.public == true)?_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-check-circle-outline")]):_c('v-icon',{attrs:{"color":"error"}},[_vm._v("mdi-close-circle-outline")])]}},{key:"item.actions",fn:function(ref){
    var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"error"},on:{"click":function($event){$event.stopPropagation();return _vm.openDialog(item.id)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-delete ")])],1)]}}],null,true)},[_vm._v(" "),_c('span',[_vm._v("Löschen")])])]}},{key:"footer",fn:function(ref){
      var ref_props = ref.props;
      var pagination = ref_props.pagination;
      var itemsPerPageText = ref_props.itemsPerPageText;
      var itemsPerPageOptions = ref_props.itemsPerPageOptions;
      var options = ref_props.options;
return [_c('table-footer',{attrs:{"pagination":pagination,"itemsPerPageText":itemsPerPageText,"itemsPerPageOptions":itemsPerPageOptions,"tableOptions":options,"pageCount":_vm.pageCount}})]}}])}),_vm._v(" "),_c('v-dialog',{attrs:{"max-width":"400"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-toolbar',{attrs:{"color":"primary","dark":"","flat":""}},[_c('v-toolbar-title',[_vm._v("Filter löschen?")])],1),_vm._v(" "),_c('v-card-actions',[_c('v-btn',{attrs:{"color":"secondary","x-small":""},on:{"click":function($event){$event.stopPropagation();_vm.dialog = false}}},[_vm._v("Abbrechen")]),_vm._v(" "),_c('v-spacer'),_vm._v(" "),_c('v-btn',{attrs:{"color":"error","type":"btn","small":""},on:{"click":function($event){return _vm.deleteFilter()}}},[_vm._v("Löschen")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }