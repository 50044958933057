<template>
  <v-row>
    <v-col>
      <h3 class="primary--text my-4">verfügbare Tabellenfelder</h3>
      <v-expansion-panels flat accordion v-model="active">
        <v-expansion-panel
          v-for="category in categories"
          active-class="active"
          :key="category.id"
          :disabled="category.name !== module && disabled"
        >
          <v-expansion-panel-header
            class="px-0 text-subtitle-2 font-weight-bold secondary--text"
            >{{ category.name }}</v-expansion-panel-header
          >
          <v-expansion-panel-content>
            <v-divider class="mb-4"></v-divider>
            <field-draggable
              :fields="getCategoryFields(category.id)"
            ></field-draggable>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-col>
    <v-col>
      <h3 class="primary--text my-4">angezeigte Tabellenfelder</h3>
      <v-chip-group column style="min-height: 150px; min-width: 50%">
        <draggable
          v-model="selectedPortalFields"
          v-bind="dragOptions"
          @start="drag = true"
          @end="drag = false"
        >
          <v-chip
            close
            small
            v-for="(element, index) in selectedPortalFields"
            :key="index"
            :color="element.color"
            @click:close="removeSelected(index, element)"
          >
            {{ element.field_name }}
          </v-chip>
        </draggable>
      </v-chip-group>
    </v-col>
  </v-row>
</template>
<script>
import draggable from "vuedraggable";
import FieldDraggable from "./FieldDraggable.vue";

export default {
  data: () => ({
    active: 0,
    drag: false,
  }),
  props: {
    module: String,
    disabled: Boolean,
  },
  components: {
    draggable,
    FieldDraggable,
  },
  created() {
    this.active = this.disabled ? -1 : 0;
  },
  watch: {
    disabled: {
      handler() {
        this.active = this.disabled ? -1 : 0;
      },
    },
  },
  computed: {
    categories() {
      let categories = _.flatten(
        _.partition(
          _.sortBy(
            _.uniqBy(
              _.map(
                this.$store.state.fieldStore.availableFields,
                "portal_field_category"
              ),
              "id"
            ),
            "id"
          ),
          (o) =>
            o.name === "Windparks" ||
            o.name === "Windenergieanlagen" ||
            o.name === "Organisationen" ||
            o.name === "Photovoltaikparks" ||
            o.name === "Photovoltaikanlagen" ||
            o.name === "Brennstoffe" ||
            o.name === "Brennstoffanlagen"
        )
      );

      if (
        this.module === "Organisationen" ||
        this.module === "Ansprechpartner"
      ) {
        categories = categories.filter(
          (elem) =>
            elem.name === "Organisationen" || elem.name === "Ansprechpartner"
        );
      } else {
        categories = categories.filter(
          (elem) =>
            elem.name !== "Organisationen" && elem.name !== "Ansprechpartner"
        );
      }

      if (
        this.module === "Photovoltaikparks" ||
        this.module === "Photovoltaikanlagen"
      ) {
        categories = categories.filter(
          (elem) =>
            elem.name !== "Windparks" &&
            elem.name !== "Windenergieanlagen" &&
            elem.name !== "Brennstoffe" &&
            elem.name !== "Brennstoffanlagen"
        );
      }

      if (
        this.module === "Brennstoffe" ||
        this.module === "Brennstoffanlagen"
      ) {
        categories = categories.filter(
          (elem) =>
            elem.name !== "Windparks" &&
            elem.name !== "Windenergieanlagen" &&
            elem.name !== "Photovoltaikparks" &&
            elem.name !== "Photovoltaikanlagen"
        );
      }

      if (this.module === "Windparks" || this.module === "Windenergieanlagen") {
        categories = categories.filter(
          (elem) =>
            elem.name !== "Brennstoffe" &&
            elem.name !== "Brennstoffanlagen" &&
            elem.name !== "Photovoltaikparks" &&
            elem.name !== "Photovoltaikanlagen"
        );
      }

      if (this.module === "Eigentümer") {
        categories = categories.filter(
          (elem) => !elem.name.includes("Betreiber")
        );
      }

      if (this.module === "Betreiber") {
        categories = categories.filter(
          (elem) => !elem.name.includes("Eigentümer")
        );
      }

      return categories;
    },
    selectedPortalFields: {
      get() {
        return this.$store.state.fieldStore.selectedFields;
      },
      set(value) {
        if (value.length > process.env.MIX_WARNING_FIELDS) {
          this.$snackbar.showMessage({
            content:
              "Hinweis: Beachten Sie bitte, dass eine größere Auswahl von Feldern zu längeren Ladezeiten führen kann.",
            color: "warning",
          });
        }
        if (value.length > process.env.MIX_MAX_FIELDS && process.env.MIX_MAX_FIELDS != -1) {
          this.$snackbar.showMessage({
            content:
              "maximale Anzahl Felder erreicht",
            color: "error",
          });

          return
        }

        this.$store.commit("fieldStore/updateSelectedFields", value);
      },
    },

    dragOptions() {
      return {
        animation: 200,
        group: {
          name: "selectedFields",
          pull: ["selectedFields"],
          put: ["availableFields", "selectedFields"],
        },
        disabled: false,
        ghostClass: "ghost",
      };
    },
  },
  methods: {
    removeSelected(index, element) {
      this.$store.dispatch("fieldStore/removeSelected", index);
      this.$store.dispatch("fieldStore/addAvailable", element);
    },

    getCategoryFields(index) {
      return this.$store.getters["fieldStore/getAvailableFieldsByCategoryId"](
        index
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.v-expansion-panel-content::v-deep .v-expansion-panel-content__wrap {
  padding-left: 0;
  padding-right: 0;
}
</style>

